<template>
  <v-content id="tamanhoDiv">
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-bookmark-check
      </v-icon>
      <v-card-text class="text">
        Relatórios
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
      :style="{ height: `${tamanhoPagina}px`}"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-card class="elevation-12 mx-auto">
            <!-- Formulario  -->
            <v-card-text>
              <v-form
                ref="form"
                lazy-validation
                class="formulario"
              >
                <!-- Tipo do Relatório-->
                <v-row justify="space-around">
                  <v-col
                    cols="12"
                    sm="9"
                  >
                    <v-select
                      v-model="relatorio"
                      label="Tipo do Relatório"
                      name="tipoRelatorio"
                      type="text"
                      :items="tituloRelatorio"
                      :disabled="!administrador"
                      required
                      @change="validaFiltroRelatorio"
                      @value="insereRelatorioFiltro"
                    />
                  </v-col>
                </v-row>

                <!-- Mês -->
                <v-row
                  v-if="!isUltimosSocios && !ocultaCalendario"
                  justify="space-around"
                >
                  <v-col
                    cols="12"
                    sm="9"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      class="calendario"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          :value="formataDataCalendario"
                          label="Mês/Ano Referência"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="date"
                        type="month"
                        min="2020-05-01T03:00:00.000Z"
                        :max="mesAtual"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="salvaMesAnoReferencia"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <!-- Ano -->
                <v-row
                  v-if="administrador && anoReferencia"
                  justify="space-around"
                >
                  <v-col
                    cols="12"
                    sm="9"
                  >
                    <v-select
                      v-if="relatorio === relatorioEntradaSociosAno"
                      v-model="year"
                      label="Ano de Referência"
                      name="anoReferencia"
                      type="text"
                      :items="yearsEntries"
                      required
                      @value="selecionaAno"
                      @change="validaAnoSelecionado"
                    />
                    <v-select
                      v-if="relatorio === relatorioSaidaSociosAno"
                      v-model="year"
                      label="Ano de Referência"
                      name="anoReferencia"
                      type="text"
                      :items="yearsExit"
                      required
                      @value="selecionaAno"
                      @change="validaAnoSelecionado"
                    />
                  </v-col>
                </v-row>

                <!-- Empresa -->
                <v-row
                  v-if="!isUltimosSocios && !ocultaEmpresa && filiais.length > 0"
                  justify="space-around"
                >
                  <v-col
                    cols="12"
                    sm="9"
                  >
                    <v-select
                      v-model="empresaSelecionada"
                      label="Empresa"
                      name="empresa"
                      type="text"
                      :items="nomeEmpresas"
                      required
                      @change="validaEmpresaSelecionada"
                    />
                  </v-col>
                </v-row>

                <!-- Campo matricula Ultimos Sócios -->
                <v-row
                  v-if="administrador && isUltimosSocios"
                  justify="space-around"
                >
                  <v-col
                    cols="12"
                    sm="9"
                  >
                    <v-text-field
                      v-model="nextMatricula"
                      v-mask="nextMatriculaMask"
                      label="A partir da matricula"
                      name="depoisMatricula"
                      type="text"
                      :rules="nextMatriculaRules"
                      required
                      @value="contaCaracter"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <!-- Botão Visualizar relatório  -->
            <v-card-actions
              v-if="!isUltimosSocios"
              class="botoes"
            >
              <v-btn
                class="my-2"
                rounded
                :disabled="visualizarRelatorio"
                color="indigo"
                :loading="carregando"
                @click="gerarRelatorio"
              >
                Visualizar
              </v-btn>
            </v-card-actions>

            <!-- Faz aparecer o botão inserindo 4 caracter matricula  -->
            <v-card-actions
              v-if="nextMatricula.length > 3"
              class="botoes"
            >
              <v-btn
                class="my-2"
                rounded
                color="indigo"
                :loading="carregando"
                @click="gerarRelatorio"
              >
                Visualizar
              </v-btn>
            </v-card-actions>

            <v-radio-group
              v-if="!ocultaTipoRelatorio"
              v-model="tipoRelatorioSelecionado"
              class="checkBoxTipoRelatorio"
              :mandatory="true"
            >
              <v-row>
                <v-col cols="5">
                  <v-radio
                    label="PDF"
                    :value="true"
                  />
                </v-col>
                <v-col cols="2">
                  <v-radio
                    label="Excel"
                    :value="false"
                  />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/pt-br'

export default {
  data: () => ({
    // Variáveis Página
    menu: false,
    tamanhoPagina: '',
    // Variaveis Globais
    administrador: false,
    // Variáveis de Controle
    carregando: false,
    visualizarRelatorio: true,
    // Variáveis Relatório
    empresas: [],
    filiais: [],
    nomeEmpresas: [],
    empresaEFiliais: [],
    relatorio: 'Relatório de Sócios',
    empresaSelecionada: '',

    ultimoAssociados: 'Relatório de Últimos Sócios',
    uidCurrentUser: '',
    instituicoesReport: [],
    selecao: [],
    nextMatricula: '',
    nextMatriculaMask: '####',
    nextMatriculaRules: [
      v => !!v || 'O Matricula é obrigatório'
    ],
    date: new Date().toISOString().substr(0, 7),
    mesAtual: new Date().toISOString().substr(0, 7),
    year: '',
    yearsEntries: [],
    yearsExit: [],
    socios: [],
    tituloRelatorio: [],

    // Titulos Relatorios
    controleEntregaBrindesGeral: '',
    controleEntregaBrindesMes: '',
    controleEntregaCarteirinhaGeral: '',
    controleEntregaCarteirinhaMes: '',
    emissaoCarteirinhasGrafica: '',
    listaContatoSocios: '',
    listaPresencaSocios: '',
    relatorioSocios: '',
    relatorioUltimosSocios: '',

    // Variaveis
    isUltimosSocios: false,
    contaCaracterMatricula: '',
    ultimaEmpresaSelecionada: '',
    countFiliais: 0,
    idReport: '',
    cnpj: '',
    idEmpresa: '',
    ocultaCalendario: false,
    ocultaEmpresa: false,
    gerencial: false,
    anoReferencia: false,
    dadosEmpresaLogada: [],
    temFiliais: false,
    tipoRelatorioSelecionado: true,
    ocultaTipoRelatorio: false
  }),
  computed: {
    formataDataCalendario () {
      var dateFormated = this.date ? moment(this.date).format('MMMM/YYYY') : ''
      dateFormated = dateFormated[0].toUpperCase() + dateFormated.substr(1)
      return dateFormated
    },
    contaCaracter () {
      return this.nextMatricula.length
    },
    insereRelatorioFiltro () {
      return this.relatorio
    },
    selecionaAno () {
      return this.year
    },
    empresaSelecionadaFiltro () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.visualizarRelatorio = true

      return this.empresaSelecionada
    }
  },
  async created () {
    await this.retornaDadosEmpresaLogada()
    await this.retornaTituloRelatorio()
    if (this.administrador) {
      await this.retornaEmpresas()
      await this.retornaAnosAssociacao()
    }

    this.validaAcesso()
  },
  mounted () {
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.tamanhoPagina = tamanhoPagina - 145
  },
  methods: {
    async retornaDadosEmpresaLogada () {
      this.uidCurrentUser = firebase.auth().currentUser.uid
      let dadosEmpresa = []
      await firebase.firestore().collection('empresas')
        .where('uid', '==', this.uidCurrentUser)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            dadosEmpresa.push(doc.data())
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })

      this.dadosEmpresaLogada = dadosEmpresa[0]

      if (this.dadosEmpresaLogada.acesso === 'Administrador') {
        this.administrador = true
      } else {
        this.administrador = false
      }
    },
    async retornaEmpresas () {
      let dadosEmpresas = []
      this.empresas = this.$store.state.empresas
      if (this.empresas === undefined) {
        this.empresas = []
        await firebase.firestore().collection('empresas')
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              dadosEmpresas.push(doc.data())
            })
          })
        this.empresas = dadosEmpresas
      }
    },
    async retornaTituloRelatorio () {
      // Chamada da função para popular os relatórios
      const enumTitleReports = firebase.functions().httpsCallable('enumTitleReports')

      try {
        const response = await enumTitleReports()
        let enumTitle = response.data
        this.tituloRelatorio = [
          enumTitle.emissaoCarteirinhasGrafica,
          enumTitle.controleEntregaBrindesGeral,
          // enumTitle.controleEntregaBrindesMes,
          // enumTitle.controleEntregaCarteirinhaGeral,
          enumTitle.controleEntregaCarteirinhaMes,
          enumTitle.listaContatoSocios,
          enumTitle.listaPresencaSocios,
          enumTitle.relatorioSocios,
          enumTitle.relatorioEntradaSociosMes,
          enumTitle.relatorioSaidaSociosMes,
          enumTitle.relatorioEntradaSociosAno,
          enumTitle.relatorioSaidaSociosAno,
          // enumTitle.relatorioUltimosSocios
        ]

        this.controleEntregaBrindesGeral = enumTitle.controleEntregaBrindesGeral
        this.controleEntregaBrindesMes = enumTitle.controleEntregaBrindesMes
        this.controleEntregaCarteirinhaGeral = enumTitle.controleEntregaCarteirinhaGeral
        this.controleEntregaCarteirinhaMes = enumTitle.controleEntregaCarteirinhaMes
        this.emissaoCarteirinhasGrafica = enumTitle.emissaoCarteirinhasGrafica
        this.listaContatoSocios = enumTitle.listaContatoSocios
        this.listaPresencaSocios = enumTitle.listaPresencaSocios
        this.relatorioSocios = enumTitle.relatorioSocios
        this.relatorioEntradaSociosAno = enumTitle.relatorioEntradaSociosAno
        this.relatorioEntradaSociosMes = enumTitle.relatorioEntradaSociosMes
        this.relatorioSaidaSociosAno = enumTitle.relatorioSaidaSociosAno
        this.relatorioSaidaSociosMes = enumTitle.relatorioSaidaSociosMes
        this.relatorioUltimosSocios = enumTitle.relatorioUltimosSocios
      } catch (error) {
        console.error('Erro ao chamar a função enumTitleReports:', error)
      }
    },
    validaAcesso () {
      if (this.administrador) {
        this.empresas.forEach(doc => {
          if (doc.acesso !== 'Administrador') {
            this.nomeEmpresas.push(doc.nome)
          }
        })
        this.nomeEmpresas.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        this.filiais.push(this.nomeEmpresas)
      } else {
        this.empresas.forEach(doc => {
          if (doc.cnpj_matriz === this.dadosEmpresaLogada.cnpj) {
            this.filiais.push(doc.nome)
          }
        })

        if (this.filiais.length > 0) {
          this.nomeEmpresas = this.filiais
          this.nomeEmpresas.push(this.dadosEmpresaLogada.nome)
          this.nomeEmpresas.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        } else {
          this.visualizarRelatorio = false
        }
      }
    },
    validaFiltroRelatorio () {
      // Valida últimos socios
      if (this.relatorio === this.relatorioUltimosSocios) {
        this.isUltimosSocios = true
      } else {
        this.nextMatricula = ''
        this.isUltimosSocios = false
      }

      // Validação para ocultar calendário
      if (this.relatorio === this.controleEntregaBrindesGeral ||
          this.relatorio === this.controleEntregaCarteirinhaGeral ||
          this.relatorio === this.listaPresencaSocios ||
          this.relatorio === this.listaContatoSocios ||
          this.relatorio === this.relatorioEntradaSociosAno ||
          this.relatorio === this.relatorioSaidaSociosAno) {
        this.ocultaCalendario = true
      } else {
        this.ocultaCalendario = false
      }

      if (this.relatorio === this.relatorioEntradaSociosAno ||
          this.relatorio === this.relatorioEntradaSociosMes ||
          this.relatorio === this.relatorioSaidaSociosAno ||
          this.relatorio === this.relatorioSaidaSociosMes ||
          this.relatorio === this.emissaoCarteirinhasGrafica) {
        this.ocultaEmpresa = true
        this.visualizarRelatorio = false
        this.gerencial = true
      } else {
        this.ocultaEmpresa = false
      }

      if (this.relatorio === this.relatorioEntradaSociosAno ||
          this.relatorio === this.relatorioSaidaSociosAno) {
        this.anoReferencia = true
        this.visualizarRelatorio = true
        this.ocultaTipoRelatorio = true
      } else {
        this.anoReferencia = false
        this.ocultaTipoRelatorio = false
      }

      if (this.selecionaAno) {
        this.visualizarRelatorio = false
      }
    },
    validaEmpresaSelecionada () {
      if (this.relatorio.length > 0) {
        this.visualizarRelatorio = false
      }
    },
    validaAnoSelecionado () {
      if (this.relatorio.length > 0) {
        this.visualizarRelatorio = false
      }
    },
    gerarRelatorio () {
      this.carregando = true
      let cnpj

      let relatorio
      let mimeTypeRelatorio
      let typeRelatorio

      // Valida se o relatório é Excel ou PDF
      if (this.tipoRelatorioSelecionado) {
        relatorio = 'generatePDF'
        mimeTypeRelatorio = 'blob'
        typeRelatorio = 'application/pdf'
      } else {
        relatorio = 'generateExcel'
        mimeTypeRelatorio = 'arraybuffer'
        typeRelatorio = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }

      if (this.administrador || this.filiais.length > 0) {
        // this.criaMovimentacaoMensalidade()
        this.empresas.forEach(doc => {
          if (doc.nome === this.empresaSelecionada) {
            cnpj = doc.cnpj
          }
        })
      } else {
        cnpj = this.dadosEmpresaLogada.cnpj
      }

      axios
        .get(`https://us-central1-sindi-associado.cloudfunctions.net/${relatorio}?`, {
        // .get(`http://localhost:5000/sindi-associado/us-central1/${relatorio}?`, {
          params: {
            cnpj: cnpj,
            nome: this.empresaSelecionada,
            tipo: this.relatorio,
            mes: this.date,
            nextMatricula: this.nextMatricula,
            ano: this.year
          },
          responseType: mimeTypeRelatorio
        })
        .then(response => {
          console.log(response)
          console.log('response')
          var blob = new Blob([response.data], {
            type: typeRelatorio
          })

          var url = window.URL.createObjectURL(blob)
          window.open(url)

          this.carregando = false

          const cnpjSemCaracter = cnpj.replace(/[^\d]/g, '')

          if (this.relatorio === this.relatorioSocios &&
              this.mesAtual === this.date) {
            let dataMov = moment(this.date).format('MMMM/YYYY')
            firebase.firestore().collection('empresas').doc(cnpjSemCaracter).update({
              ['mensalidade.' + moment(this.date).format('YYYY-MM')]: dataMov.charAt(0).toUpperCase() + dataMov.slice(1)
            })
          }
          setTimeout(() => {
            location.reload()
          }, 2500)
          this.carregando = false
        })
        .catch(err => {
          console.log('err - ' + err)
          this.carregando = false
        })
    },
    async retornaAnosAssociacao () {
      const datasAssociacao = [ ]
      const datasSaida = [ ]

      // Valida se tem no Store os dados dos socios
      this.socios = this.$store.state.socios

      if (this.socios === undefined) {
        this.socios = []
        await firebase.firestore().collection('socios').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.socios.push(doc.data())
            })
            // Guarda os dados dos socios no store
            this.$store.dispatch('setSocios', this.socios)
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.socios = this.$store.state.socios
      }

      this.socios.forEach(el => {
        if (el.data_entrada_socio !== '') {
          datasAssociacao.push(el.data_entrada_socio)
        }
      })

      this.socios.forEach(el => {
        if (el.data_saida_socio !== '') {
          datasSaida.push(el.data_saida_socio)
        }
      })

      const dateValitedEntry = this.criaFiltroAno(datasAssociacao)
      dateValitedEntry.forEach(doc => {
        this.yearsEntries.push(doc[0])
      })
      this.yearsEntries = this.yearsEntries.reverse()

      const dateValitedExit = this.criaFiltroAno(datasSaida)
      dateValitedExit.forEach(doc => {
        this.yearsExit.push(doc[0])
      })
      this.yearsExit = this.yearsExit.reverse()
    },
    criaFiltroAno (datas) {
      // Criar um objeto para armazenar os anos e as datas correspondentes
      const anosEdatas = []

      // Percorrer o array de datas
      for (const data of datas) {
        const parts = data.split('/') // Separa a data entre dia, mes e ano
        const year = parseInt(parts[2])// Pega o ano

        // Verificar se o ano já existe no objeto
        if (anosEdatas.hasOwnProperty(year)) {
          // Se existir, adicionar a data ao array correspondente
          anosEdatas[year].push(year)
        } else {
          // Se não existir, criar um novo array com a data
          anosEdatas[year] = [year]
        }
      }
      return anosEdatas
    },
    criaMovimentacaoMensalidade () {
      let cnpjEmpresas = []
      let movimentacoesGeradas

      // Obter a data atual
      const dataAtual = new Date()

      // Array com os nomes dos meses
      const nomesDosMeses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ]

      // Obter o nome do mês e o ano
      const nomeDoMes = nomesDosMeses[dataAtual.getMonth()]
      const ano = dataAtual.getFullYear()
      const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0') // Adiciona zero à esquerda, se necessário

      // Criar a string no formato desejado
      const anoMes = `${ano}-${mes}`

      // Criar a string no formato desejado
      const mesAno = `${nomeDoMes}/${ano}`

      firebase.firestore().collection('configuracao').doc('movimentacaoMensalidade').get()
        .then(doc => {
          movimentacoesGeradas = doc.data().mensalidade

          if (movimentacoesGeradas[this.date] !== true) {
            // Atualiza para todos os sócios ativos a movimentação da mensalidade
            this.socios.forEach(doc => {
              if (doc.socio === true) {
                firebase.firestore().collection('socios').doc(doc.matricula).update({
                  ['mensalidade.' + anoMes]: {
                    'ano_mes': anoMes,
                    'cnpj_empresa': doc.cnpj_empresa,
                    'funcao': doc.funcao,
                    'observacao': doc.observacao,
                    'valor_mensalidade': doc.valor_mensalidade
                  }
                })
              }
            })

            // Busca o cnpj para realizar o realizar o update da movimentação da mensalidade
            this.empresas.forEach(doc => {
              if (doc.mensalidade !== undefined && doc.acesso === 'Usuário') {
                cnpjEmpresas.push(doc.cnpj.replace(/[^\d]/g, ''))
              }
            })

            // Atualiza para todas as empresas a movimentação das mensalidades
            cnpjEmpresas.forEach(cnpj => {
              firebase.firestore().collection('empresas').doc(cnpj).update({
                ['mensalidade.' + anoMes]: mesAno
              })
            })

            firebase.firestore().collection('configuracao').doc('movimentacaoMensalidade').update({
              ['mensalidade.' + anoMes]: true
            })
          }
        })
        .catch((err) => {
          console.log('Error getting documents2', err)
        })
    },
    salvaMesAnoReferencia () {
      this.menu = false
      this.$refs.menu.save(this.date)
    }
  }
}
</script>

<style scoped>
.v-content {
  height: 100vh;
}

.formulario {
  margin-top: 30px
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
}

.v-toolbar__title {
  margin: auto;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 60px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 25px;
}

.v-card__text {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.v-icon {
  display: flex;
  margin-top: 20px;
  font-size: 80px;
}
.switch {
  margin: 0 40% !important;
}

.botoes {
  justify-content: center;
  padding-bottom: 30px;
}

.my-2 {
  color: white
}
.v-application .my-2 {
  margin-bottom: 22px !important;
}

.BarraAtencao {
  height: 25px !important;
  margin-top: 2px !important;
  font-size: 22px;
  font-weight: bold;
}

.BarraEditar {
  height: 45px !important;
}

.botoes{
  padding: 0px 5px;
  margin: -9px 30px 0px 30px;
  justify-content: center;
}

.textoModal{
  font-size: 18px;
  margin-top: 0px;
  line-height: 1.8;
  color:gray;
}

.botoesModal{
  padding: 0px 5px;
  margin-top: -10px;
  justify-content: center;
}

#handPoint {
  position: absolute;
  color: white;
  top: 10px;
  right: 40px;
  width: 10px;
  transform: scaleX(-1);
}

.alertas {
  z-index: 10;
  position: absolute;
  width: 430px;
  top: -13%;
  right: 10%;
}

.theme--light {
  background-color: transparent;
}
.cardEdit{
  background-color: white;
  text-align: center;
}

.v-list {
  background-color: white;
}

.checkBoxTipoRelatorio {
  position: absolute;
    bottom: 3%;
    right: 5%;
}

.v-menu__content {
  top: 26% !important
}

.theme--light.v-card {
      background-color: #FFFFFF !important;
}
</style>
