<template v-slot>
  <v-content id="tamanhoDiv">
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-account-group
      </v-icon>
      <v-card-text class="text">
        Associados
      </v-card-text>
    </v-card>

    <!-- Dados do Associado -->
    <v-container
      class="fill-height"
      fluid
      :style="{ height: `${tamanhoPag}px`}"
    >
      <!-- Modal Pergunta se quer excluir socio -->
      <v-dialog
        v-model="dialog"
        width="42%"
      >
        <v-card class="elevation-12">
          <!-- Barra de Título -->
          <v-toolbar
            class="BarraEditar"
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title class="BarraAtencao text-uppercase">
              atenção !!!
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <div class="textoModal">
              <p class="font-weight-bold">
                Nessa ação o socio <b>{{ dados.nome }}</b>
                será excluído definitivamente!!!
              </p>
              <p class="confirmaAcao">
                Você confirma essa <b>EXCLUSÃO?</b>
              </p>
            </div>

            <v-card-actions class="botoesModal">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="cancelar"
                >
                  Cancelar
                </v-btn>
              </v-flex>
              <v-btn
                :carregando="carregandoDialogo"
                class="my-2"
                rounded
                color="indigo"
                dark
                @click="confirmarExclusaoAssociado"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Modal Confirmação de Exclusão -->
      <v-dialog
        v-model="confirmacaoEnvio"
        width="41%"
      >
        <v-card class="elevation-12">
          <!-- Barra de Título -->
          <v-toolbar
            class="BarraEditar"
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title class="BarraAtencao text-uppercase">
              Sucesso !!!
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text style="text-align: center">
            <div class="textoModal">
              <p class="font-weight-bold">
                O socio
                <b>{{ dados.nome }}</b> foi excluido!!!
              </p>

              <v-card-actions class="botoesModal">
                <v-btn
                  class="my-2"
                  rounded
                  color="indigo"
                  dark
                  @click="ok"
                >
                  ok
                </v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Modal Detalhes Histórico Empresa -->
      <v-dialog
        v-model="modalDetalhesHistoricoEmpresa"
        width="70%"
      >
        <v-card class="elevation-12">
          <!-- Barra de Título -->
          <v-toolbar
            class="BarraEditar"
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title class="BarraAtencao text-uppercase">
              Histórico Empresa
            </v-toolbar-title>
            <button
              class="btnFechar"
              @click="fecharModalDetalhesHistoricoEmpresa"
            >
              <v-icon>mdi-close</v-icon>
            </button>
          </v-toolbar>
          <v-container class="modalHistoricoEmpresa">
            <div class="titleData">
              Dados Profissionais
            </div>

            <!-- Empresa e Data Admissão -->
            <v-row>
              <v-col
                cols="12"
                md="7"
              >
                <v-row>
                  <p>Empresa:</p>
                  <div>{{ historicoSelecionado.cnpj_empresa }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-row>
                  <p>Data Admissão:</p>
                  <div>{{ historicoSelecionado.data_admissao }}</div>
                </v-row>
              </v-col>
            </v-row>

            <!-- Função e Setor -->
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <p>Função:</p>
                  <div>{{ historicoSelecionado.funcao }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <p>Setor:</p>
                  <div>{{ historicoSelecionado.setor }}</div>
                </v-row>
              </v-col>
            </v-row>

            <!-- Valor e Observação -->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <p>Valor:</p>
                  <div>{{ historicoSelecionado.valor_mensalidade }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-row>
                  <p>Obs.:</p>
                  <div>{{ historicoSelecionado.observacao }}</div>
                </v-row>
              </v-col>
            </v-row>

            <v-divider />

            <div class="titleData">
              Dados do Sindicato
            </div>

            <!-- Data Entrada Sócio e Quem associou -->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <p>Data de Entrada Sócio:</p>
                  <div>{{ historicoSelecionado.data_entrada_socio }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-row>
                  <p>Quem Associou:</p>
                  <div>{{ historicoSelecionado.quem_associou }}</div>
                </v-row>
              </v-col>
            </v-row>

            <!-- Data Saída Sindicato e Motivo Saída -->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <p>Data de Saída Sindicato:</p>
                  <div>{{ historicoSelecionado.data_saida_socio }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-row>
                  <p>Motivo da Saída:</p>
                  <div>{{ historicoSelecionado.motivo_saida }}</div>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Botão de Voltar para Associados -->
      <v-container class="body">
        <button
          class="iconBack"
          @click="voltar"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </button>
        <!-- Nome do Associado -->
        <h1 class="text-center">
          {{ dados.nome }}
        </h1>
      </v-container>

      <!-- Todos os Dados -->
      <v-row>
        <v-col
          cols="12"
          md=""
        >
          <v-container class="data">
            <div class="titleData">
              Dados Pessoais
            </div>
            <!-- Matricula, Email, Data de Nascimento -->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <p>Matricula:</p>
                  <div>{{ dados.matricula }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md=""
              >
                <v-row>
                  <p>CPF:</p>
                  <div>{{ dados.cpf }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md=""
              >
                <v-row>
                  <p>Estado Civil:</p>
                  <div>{{ dados.estado_civil }}</div>
                </v-row>
              </v-col>
            </v-row>

            <!-- CPF, RG, Estado CIvil -->
            <v-row>
              <v-col
                cols="12"
                md=""
              >
                <v-row>
                  <p>Data de Nascimento:</p>
                  <div>{{ dados.data_nascimento }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md=""
              >
                <v-row>
                  <p>RG:</p>
                  <div>{{ dados.rg }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md=""
              >
                <v-row>
                  <p>Carteira de Trabalho:</p>
                  <div>{{ dados.carteira_profissional }}</div>
                </v-row>
              </v-col>
            </v-row>

            <!-- CEP, Endereço, Cidade -->
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-row>
                  <p>CEP:</p>
                  <div>{{ dados.cep }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <p>Endereço:</p>
                  <div v-if="dados.endereco !== ''">
                    {{ dados.endereco }}<span v-if="dados.numero !== ''">,  {{ dados.numero }} {{ dados.complemento }} </span>
                    <span v-if="dados.bairro !== ''">  - {{ dados.bairro }} </span>
                  </div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <p>Cidade:</p>
                  <div>{{ dados.cidade }} <span v-if="dados.uf !== '' && dados.cidade !== ''">- {{ dados.uf }}</span></div>
                </v-row>
              </v-col>
            </v-row>

            <!-- Carteira de Trabalho, Fones -->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row>
                  <p>E-mail:</p>
                  <div>{{ dados.email }}</div>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md=""
              >
                <v-row>
                  <p>Celular:</p>
                  <div>{{ dados.celular }}</div>
                </v-row>
              </v-col>
            </v-row>

            <v-divider />

            <div class="titleData">
              Dados Profissionais
            </div>

            <!-- Visualização Administrador -->
            <div v-if="isAdmin">
              <!-- Empresa Principal, Data Admissão, Função -->
              <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-row>
                    <p>Empresa:</p>
                    <div>{{ buscarNomesEmpresas(dados.cnpj_empresa) }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md=""
                >
                  <v-row>
                    <p>Data Admissão:</p>
                    <div>{{ dados.data_admissao }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md=""
                >
                  <v-row>
                    <p>Função:</p>
                    <div>{{ dados.funcao }}</div>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Setor, Valor e Observação -->
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-row>
                    <p>Setor:</p>
                    <div>{{ dados.setor }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <p>Valor:</p>
                    <div>{{ dados.valor_mensalidade }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-row>
                    <p>Obs.:</p>
                    <div>{{ dados.observacao }}</div>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider />

              <div class="titleData">
                Dados do Sindicato
              </div>

              <!-- Matricula, Email, Data de Nascimento -->
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-row>
                    <p>Sócio:</p>
                    <div>{{ socio }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-row>
                    <p>Data de Associação:</p>
                    <div>{{ dados.data_entrada_socio }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-row>
                    <p>Quem Associou:</p>
                    <div>{{ dados.quem_associou }}</div>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Matricula, Email, Data de Nascimento -->
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-row>
                    <p>Dirigente:</p>
                    <div>{{ dirigente }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-row>
                    <p>Data de Saída Sindicato:</p>
                    <div>{{ dados.data_saida_socio }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-row>
                    <p>Motivo da Saída:</p>
                    <div>{{ dados.motivo_saida }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>

            <div v-if="!isAdmin">
              <!-- Empresa Logadada Email, Data de Nascimento -->
              <v-row>
                <v-col
                  cols="12"
                  md=""
                >
                  <v-row>
                    <p>Empresa:</p>
                    <div>{{ buscarNomesEmpresas(cnpjUserLogado) }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md=""
                >
                  <v-row>
                    <p>Função:</p>
                    <div>{{ funcaoEmpresaLogada }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md=""
                >
                  <v-row>
                    <p>Setor:</p>
                    <div>{{ setorEmpresaLogada }}</div>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Data de Admissão, Valor e Observação -->
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <p>Data Admissão:</p>
                    <div>{{ dataAdmissaoEmpresaLogada }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <p>Valor:</p>
                    <div>{{ valorMensalidadeEmpresaLogada }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <p>Obs.:</p>
                    <div>{{ observacaoEmpresaLogada }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>

            <!-- Dependentes -->
            <div v-if="items !== undefined">
              <v-divider />
              <div class="titleData">
                Dependentes
              </div>
              <v-row
                v-for="item in items"
                :key="item.idDependente"
              >
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-row>
                    <p>Nome: </p>
                    <div>{{ item.nome_dependente }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-row>
                    <p>Data de Nascimento:</p>
                    <div>{{ item.data_nascimento }}</div>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <p>Parentesco:</p>
                    <div>{{ item.parentesco }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>

            <div v-if="dados.mensalidade !== '' && isAdmin">
              <v-divider />
              <div class="titleData">
                Histórico de Pagamentos
              </div>

              <v-data-table
                sort-by="ano_mes"
                class="elevation-1"
                :headers="headers"
                :fixed-header="true"
                :height="250"
                :items="dadosMovimentacao"
                :carregando="carregando"
                carregando-text="Carregando Dados... Por Favor Aguarde."
                :sort-desc="true"
              />

              <v-row>
                <v-col
                  cols="12"
                  md=""
                >
                  <v-row>
                    <p>VALOR TOTAL CONTRIBUÍDO: </p>
                    <div>{{ valorTotalContribuido }}</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>

            <div v-if="dados.historico_trabalho_associacao && isAdmin">
              <v-divider />
              <div class="titleData">
                Histórico Empresas
              </div>

              <v-data-table
                sort-by="ordenar_data"
                class="elevation-1"
                :headers="tabelaHistoricoEmpresa"
                :fixed-header="true"
                :height="200"
                :items="dadosHistoricoEmpresas"
                :carregando="carregandoHistoricoEmpresas"
                carregando-text="Carregando Dados... Por Favor Aguarde."
                :sort-desc="true"
              >
                <!-- Botão de Detalhes -->
                <template #item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="abrirModalDetalhesHistoricoEmpresa(item)"
                  >
                    mdi-text-box-search-outline
                  </v-icon>
                </template>
              </v-data-table>
            </div>

            <v-card-actions
              v-if="isAdmin"
              class="botoes"
            >
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="excluiAssociado"
                >
                  Excluir
                </v-btn>
              </v-flex>
              <v-btn
                class="my-2"
                rounded
                color="#1976d2"
                dark
                :carregando="estaCarregando"
                @click="editarSocio"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-col>

        <!-- Imagem do Cadastro Associados -->
        <v-col
          cols="12"
          md="3"
        >
          <h4>Ficha do socio</h4>
          <v-container
            v-if="dados.ficha === undefined || dados.ficha === ''"
          >
            <v-img
              :src="semFicha"
              contain="true"
              aspect-ratio="1"
            />
          </v-container>
          <v-container
            v-if="dados.ficha !== undefined && dados.ficha !== ''"
          >
            <v-img
              :src="urlImagem"
              aspect-ratio="1"
              contain="true"
            />
            <div class="descImagem">
              <h4 class="ficha">
                {{ dados.ficha }}
              </h4>
              <v-icon
                large
                class="icoDown"
                @click="baixarImagem"
              >
                mdi-cloud-download
              </v-icon>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'
import download from 'downloadjs'

export default {
  name: 'VisualizarSocio',
  components: { },
  props: { },
  data () {
    return {
      dados: { },
      empresas: [],
      tamanhoPag: '',
      semFicha: 'https://firebasestorage.googleapis.com/v0/b/sindi-associado.appspot.com/o/Fichas%20Associados%2Fsem_ficha.png?alt=media&token=90702687-4851-4ad7-bacb-0599dbd909af',
      urlImagem: '',
      dialog: false,
      carregandoDialogo: false,
      confirmacaoEnvio: false,
      estaCarregando: false,
      isAdmin: false,
      // Insituição Logada
      valorMensalidadeEmpresaLogada: '',
      observacaoEmpresaLogada: '',
      dataAdmissaoEmpresaLogada: '',
      funcaoEmpresaLogada: '',
      setorEmpresaLogada: '',
      cnpjUserLogado: '',
      items: undefined,
      socios: undefined,
      socio: 'Sim',
      dirigente: 'Não',
      dadosMovimentacao: [],
      dadosHistoricoEmpresas: [],
      valorTotalContribuido: 0,
      headers: [
        { text: 'Ano/Mês', align: 'left', value: 'mensalidade', sortable: true },
        { text: 'Empresa', align: 'left', value: 'cnpj_empresa', sortable: false },
        { text: 'Função', value: 'funcao', sortable: false },
        { text: 'Valor ', value: 'valor_mensalidade', sortable: false },
        { text: 'Observação', value: 'observacao', sortable: false }
      ],
      tabelaHistoricoEmpresa: [
        { text: 'Empresa', align: 'left', value: 'cnpj_empresa', sortable: true },
        { text: 'Data Admissão', align: 'left', value: 'data_admissao', sortable: true },
        { text: 'Função', value: 'funcao', sortable: false },
        { text: 'Data Entrada Sócio', value: 'data_entrada_socio', sortable: true },
        { text: 'Detalhes', value: 'action', sortable: false }
      ],
      carregando: false,
      carregandoHistoricoEmpresas: false,
      historicoSelecionado: '',
      modalDetalhesHistoricoEmpresa: false
    }
  },
  created () {
    this.recebendoDados()
    if (this.dados.ficha !== undefined) {
      this.visualizarImagem()
    }

    if (this.dados.socio === true) {
      this.socio = 'Ativo'
    } else {
      this.socio = 'Inativo'
    }

    if (this.dados.dirigente === true) {
      this.dirigente = 'Sim'
    } else {
      this.dirigente = 'Não'
    }
  },
  mounted () {
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.tamanhoPag = tamanhoPagina - 145
  },
  methods: {
    buscandoCredenciais () {
      this.userLogado = firebase.auth().currentUser.uid
      let acesso
      this.empresas.forEach((el) => {
        if (el.uid === this.userLogado) {
          acesso = el.acesso
          this.cnpjUserLogado = el.cnpj
        }
      })
      // Verifica se usuário tem permissão admin
      this.isAdmin = acesso === 'Administrador' || acesso === undefined
    },
    recebendoDados () {
      const dadosRecebidos = this.$route.query.W5kaXNhdWR
      const empresaRecebida = this.$route.query.KSJMLwWasO

      const convertdadosRecebidos = JSON.stringify(dadosRecebidos)
      const decriptaEmpresaRecebida = JSON.stringify(empresaRecebida)

      this.dados = JSON.parse(convertdadosRecebidos)
      this.empresas = JSON.parse(decriptaEmpresaRecebida)

      this.buscandoCredenciais()
      this.buscarDependentes()
      this.buscarDadosUsuarioLogado()
      this.buscarMensalidades()
      this.buscarHistoricoEmpresas()
    },
    voltar () {
      this.$router.push('socios')
    },
    buscarNomesEmpresas (cnpj) {
      let nomeInstituicao
      // Varre todas as instituições com o cnpj e retorna o nome da Empresa
      this.empresas.forEach((el) => {
        if (el.cnpj === cnpj) {
          nomeInstituicao = el.nome
        }
      })
      return nomeInstituicao
    },
    visualizarImagem () {
      if (this.dados.ficha !== undefined && this.dados.ficha !== '') {
        firebase.storage()
          .ref(`Fichas Associados/${this.dados.ficha}`)
          .getDownloadURL().then((url) => {
            this.urlImagem = url
          })
      }
    },
    baixarImagem () {
      var filename = this.dados.matricula
      firebase.storage().ref(`Fichas Associados/${this.dados.ficha}`)
        .getDownloadURL().then(function (url) {
          var x = new XMLHttpRequest()
          x.open('GET', url, true)
          x.responseType = 'blob'
          x.onload = function (e) { download(x.response, `${filename}`) }
          x.send()
        })
    },
    editarSocio () {
      this.$router.push({
        name: 'editarSocio',
        query: { W5kaXNhdWR: this.$route.query.W5kaXNhdWR,
          KSJMLwWasO: this.$route.query.KSJMLwWasO,
          page: 'detalhesSocio' }
      })
    },
    excluiAssociado () {
      this.dialog = true
    },
    cancelar () {
      this.dialog = false
    },
    confirmarExclusaoAssociado () {
      this.carregandoDialogo = true
      let dependentesExcluidos = []

      firebase.firestore().collection('dependentes')
        .where('matricula_associado', '==', this.dados.matricula)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            dependentesExcluidos.push(doc.data())
          })
          dependentesExcluidos.forEach((el) => {
            firebase.firestore().collection('dependentes').doc(el.id).delete()
              .then(() => { })
              .catch((err) => {
                console.log('Error update document', err)
              })
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })

      firebase.firestore().collection('socios').doc(this.dados.matricula).delete()
        .then(() => {
          this.carregandoDialogo = false
          this.confirmacaoEnvio = true
          this.estaCarregando = false
        })
        .catch((err) => {
          this.carregandoDialogo = false
          this.dialog = false
          console.log('Error update document', err)
        })

      // Exclui o socio do store
      this.socios = this.$store.state.socios

      if (this.socios !== undefined) {
        this.socios.forEach((el) => {
          if (el.matricula === this.dados.matricula) {
            this.socios.splice(this.socios.indexOf(el.matricula), 1)
          }
        })
      }
    },
    ok () {
      this.confirmacaoEnvio = false
      this.$router.push('/socios')
    },
    buscarMensalidades () {
      let mensalidade
      for (var key in this.dados.mensalidade) {
        if (this.dados.mensalidade[key].valor_mensalidade !== '') {
          mensalidade = this.dados.mensalidade[key].valor_mensalidade
        } else {
          mensalidade = '0,00'
        }
        this.dadosMovimentacao.push({
          'ano_mes': this.dados.mensalidade[key].ano_mes,
          'mensalidade': this.transformarDataEscrita(this.dados.mensalidade[key].ano_mes),
          'cnpj_empresa': this.buscarNomesEmpresas(this.dados.mensalidade[key].cnpj_empresa),
          'funcao': this.dados.mensalidade[key].funcao,
          'valor_mensalidade': mensalidade,
          'observacao': this.dados.mensalidade[key].observacao
        })
      }
      this.dadosMovimentacao.sort((a, b) => a.ano_mes.localeCompare(b.ano_mes))

      let total = 0

      for (let i = 0; i < this.dadosMovimentacao.length; i++) {
        let valorStr = this.dadosMovimentacao[i].valor_mensalidade.replace(',', '.')
        let valorNum = parseFloat(valorStr)

        total += valorNum
      }

      this.valorTotalContribuido = total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

      this.carregando = false
    },
    buscarHistoricoEmpresas () {
      for (var key in this.dados.historico_trabalho_associacao) {
        this.dadosHistoricoEmpresas.push({
          'cnpj_empresa': this.buscarNomesEmpresas(this.dados.historico_trabalho_associacao[key].cnpj_empresa),
          'data_admissao': this.dados.historico_trabalho_associacao[key].data_admissao,
          'funcao': this.dados.historico_trabalho_associacao[key].funcao,
          'setor': this.dados.historico_trabalho_associacao[key].setor,
          'observacao': this.dados.historico_trabalho_associacao[key].observacao,
          'valor_mensalidade': this.dados.historico_trabalho_associacao[key].valor_mensalidade,
          'data_entrada_socio': this.dados.historico_trabalho_associacao[key].data_entrada_socio,
          'ordenar_data': this.formatarData(this.dados.historico_trabalho_associacao[key].data_entrada_socio),
          'quem_associou': this.dados.historico_trabalho_associacao[key].quem_associou,
          'data_saida_socio': this.dados.historico_trabalho_associacao[key].data_saida_socio,
          'motivo_saida': this.dados.historico_trabalho_associacao[key].motivo_saida
        })

        this.carregandoHistoricoEmpresas = false
      }
    },
    formatarData (datadeAdmissao) {
      let partes
      let ano
      let mes
      let dia

      partes = datadeAdmissao.split('/') // Separa a data entre dia, mes e ano
      ano = parseInt(partes[2])// Pega o ano
      mes = parseInt(partes[1]) // Pega o mes
      dia = parseInt(partes[0]) // Pega o dia

      // Formata a data completa para validar com o primeiro e o ultimo dia
      return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2)
    },
    transformarDataEscrita (dateString) {
      const months = [
        'Janeiro', 'Fevereiro', 'Março',
        'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro',
        'Outubro', 'Novembro', 'Dezembro'
      ]

      const [year, month] = dateString.split('-')
      const monthName = months[parseInt(month) - 1]

      return `${monthName}/${year}`
    },
    buscarDependentes () {
      let dependenteTemp = []
      firebase.firestore().collection('dependentes')
        .where('matricula_associado', '==', this.dados.matricula)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            dependenteTemp.push(doc.data())
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })
      setTimeout(() => {
        if (dependenteTemp.length > 0) {
          this.items = []
          this.items = dependenteTemp
        }
      }, 2000)
    },
    buscarDadosUsuarioLogado () {
      if (this.cnpjUserLogado === this.dados.cnpj_empresa) {
        this.valorMensalidadeEmpresaLogada = this.dados.valor_mensalidade
        this.observacaoEmpresaLogada = this.dados.observacao
        this.dataAdmissaoEmpresaLogada = this.dados.data_admissao
        this.funcaoEmpresaLogada = this.dados.funcao
        this.setorEmpresaLogada = this.dados.setor
      }
    },
    abrirModalDetalhesHistoricoEmpresa (item) {
      this.historicoSelecionado = Object.assign({}, item)
      this.modalDetalhesHistoricoEmpresa = true
    },
    fecharModalDetalhesHistoricoEmpresa () {
      this.modalDetalhesHistoricoEmpresa = false
    }
  }
}
</script>
<style  scoped>
.v-content {
  height: 100vh;
  background-color: white;
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 80px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 30px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.body {
  margin-top: -15px;
}

p{
  color:grey;
  margin-right: 10px;
}

div {
  font-weight: 600;
}

.data {
  margin-left: 30px;
  margin-top: -20px
}

.row {
  margin-bottom: -2px;
}

.iconBack {
  position: absolute;
  left: 3%;
  margin-top: 10px;
}

.fill-height{
  align-items: baseline;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 3px;
}

.dependentesTitle {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  color: #041c32
}

.botoes{
  margin: 0px 0px -25px -25px;
}

.descImagem {
  margin-top: 25px;
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  gap: 18px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: space-around;
}

.ficha {
  margin: 0; /* Remove a margem padrão do h4 */
}

.icoDown {
  position: relative;
  margin-top: -50px;
  margin-left: 80px;
  margin-bottom: -20px;
}

.BarraEditar {
  height: 45px !important;
}

.BarraAtencao {
  position: relative;
  left: 38%;
  top: -7px;
  font-size: 22px;
  font-weight: bold;
}

.textoModal{
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.textoModal b{
  color: #041c32;
}

.confirmaAcao{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px;
}

.botoesModal{
  padding: 0px 5px;
  justify-content: center;
}

h4 {
  text-align: center;
  color: gray
}

.titleData {
  margin-left: 40%;
  margin-bottom: 40px;
  margin-top: 20px;
  color: #041c32
}

.elevation-1 {
  margin-top: -20px;
  margin-bottom: 20px;
   font-weight: 500;
}

.modalHistoricoEmpresa {
  padding-left: 40px;
}

.btnFechar {
  position: absolute;
  right: 10px;
  top: 10px
}
</style>
