<template>
  <v-content id="tamanhoDiv">
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-calendar-sync-outline
      </v-icon>
      <v-card-text class="text">
        Cópia ou Exclusão de Movimentação
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                Gerar Movimentação de Pagamentos Retroativos por instituição
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-radio-group
                      v-model="tipoMovimentacao"
                      :rules="tipoMovimentacaoRules"
                      required
                      row
                      prepend-icon="mdi-file-move"
                    >
                      <v-radio
                        label="Copiar"
                        value="Copiar"
                      />
                      <v-radio
                        label="Excluir"
                        value="Excluir"
                      />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-select
                      v-model="empresa"
                      label="Empresas"
                      name="empresa"
                      prepend-icon="mdi-hospital-building"
                      type="text"
                      :value="computedEmpresas"
                      :items="empresaOpcoes"
                      :rules="instituicaoRules"
                      required
                      @change="carregaCNPJ"
                    />
                  </v-col>
                </v-row>
                Excluir
                <v-row v-if="tipoMovimentacao === 'Excluir'">
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-menu
                      ref="menuExclusao"
                      v-model="menuExclusao"
                      v-model:return-value="dateMesExclusao"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          :value="computedDateFormattedExclusao"
                          label="Mês/Ano para Excluir Movimentação"
                          prepend-icon="mdi-calendar-sync-outline"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dateMesExclusao"
                        prepend-icon="mdi-calendar-sync-outline"
                        type="month"
                        min="2020-05-01T03:00:00.000Z"
                        :max="mesAtual"
                        no-title
                        scrollable
                        :rules="mesExclusaoRules"
                        required
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menuExclusao = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuExclusao.save(dateMesExclusao)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- Copiar -->
                <v-row v-if="tipoMovimentacao === 'Copiar'">
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-menu
                      ref="menuReferencia"
                      v-model="menuReferencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          :value="computedDateFormattedReference"
                          label="Mês/Ano Referência"
                          prepend-icon="mdi-calendar-sync-outline"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dateMesReferencia"
                        prepend-icon="mdi-calendar-sync-outline"
                        type="month"
                        min="2020-05-01T03:00:00.000Z"
                        :max="mesAtual"
                        no-title
                        scrollable
                        :rules="mesReferenciaRules"
                        required
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menuReferencia = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="salvarDataMesReferencia"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-menu
                      ref="menuDestino"
                      v-model="menuDestino"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          prepend-icon="mdi-calendar-sync-outline"
                          :value="computedDateFormattedDestine"
                          label="Mês/Ano Destino"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dateMesDestino"
                        type="month"
                        min="2020-05-01T03:00:00.000Z"
                        :max="mesAtual"
                        no-title
                        scrollable
                        :rules="mesDestinoRules"
                        required
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menuDestino = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="salvarDataMesDestino"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-model="hasError"
                type="error"
                dismissible
              >
                Confira as informações e tente novamente
              </v-alert>

              <v-alert
                v-if="tipoMovimentacao === 'Copiar'"
                v-model="hasSuccess"
                type="success"
                dismissible
              >
                Movimentação Copiada com sucesso
              </v-alert>
              <v-alert
                v-if="tipoMovimentacao === 'Excluir'"
                v-model="hasSuccess"
                type="success"
                dismissible
              >
                Movimentação Excluida com sucesso
              </v-alert>
            </div>

            <v-card-actions
              v-if="tipoMovimentacao === 'Copiar'"
              class="botoes"
            >
              <v-btn
                class="my-2"
                :loading="hasLoading"
                rounded
                color="success"
                @click="openDialog"
              >
                Copiar Movimentação
              </v-btn>
            </v-card-actions>

            <v-card-actions
              v-if="tipoMovimentacao === 'Excluir'"
              class="botoes"
            >
              <v-btn
                class="my-2"
                :loading="hasLoading"
                rounded
                color="error"
                @click="openDialog"
              >
                Excluir Movimentação
              </v-btn>
            </v-card-actions>

            <v-row class="movimenta">
              <v-col
                v-if="empresa"
                cols="12"
                sm=""
              >
                <div class="tituloMovimenta">
                  Movimentações da Empresas
                </div>
                <v-card
                  class="mx-auto"
                  max-width="650"
                  :height="height"
                >
                  <v-list flat>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>

            <!-- Modal Pergunta se quer copiar ou excluir a movimentação -->
            <v-dialog
              v-model="dialog"
              width="42%"
            >
              <v-card class="elevation-12">
                <!-- Barra de Título -->
                <v-toolbar
                  class="BarraEditar"
                  color="indigo"
                  dark
                  flat
                >
                  <v-toolbar-title class="BarraAtencao text-uppercase">
                    atenção !!!
                  </v-toolbar-title>
                  <button
                    class="btnFechar"
                    @click="cancel"
                  >
                    <v-icon>mdi-close</v-icon>
                  </button>
                </v-toolbar>

                <v-card-text>
                  <div
                    v-if="tipoMovimentacao === 'Copiar'"
                    class="textoModal"
                  >
                    <p class="font-weight-bold">
                      Você está prestes a copiar os dados da movimentação de
                      <b>{{ dataFormatter(dateMesReferencia) }} </b> para
                      <b>{{ dataFormatter(dateMesDestino) }} </b>
                      da instituição <b>{{ empresa }}</b>!!!
                    </p>
                    <p class="confirmaAcao">
                      Você confirma essa <b>MOVIMENTAÇÃO?</b>
                    </p>
                  </div>
                  <div
                    v-if="tipoMovimentacao === 'Excluir'"
                    class="textoModal"
                  >
                    <p class="font-weight-bold">
                      Você está prestes a excluir todos os
                      dados da movimentação de <b>{{ dataFormatter(dateMesExclusao) }} </b>
                      de todos os socios da instituição <b>{{ empresa }}</b>!!!
                    </p>
                    <p class="confirmaAcao">
                      Você confirma essa <b>EXCLUSÃO?</b>
                    </p>
                  </div>

                  <!-- Alertas de Sucesso e Erro -->
                  <div class="alertas">
                    <v-alert
                      v-if="returnStatus"
                      v-model="hasReturn"
                      :type="returnStatus"
                      dismissible
                    >
                      {{ returnTextAlert }}
                    </v-alert>
                  </div>

                  <v-card-actions class="botoesModal">
                    <v-flex>
                      <v-btn
                        class="my-2"
                        rounded
                        color="error"
                        dark
                        @click="cancel"
                      >
                        Cancelar
                      </v-btn>
                    </v-flex>
                    <v-btn
                      v-if="tipoMovimentacao === 'Copiar'"
                      :loading="hasLoading"
                      class="my-2"
                      rounded
                      color="indigo"
                      dark
                      @click="insereMovimentacao"
                    >
                      Confirmar
                    </v-btn>
                    <v-btn
                      v-if="tipoMovimentacao === 'Excluir'"
                      :loading="hasLoading"
                      class="my-2"
                      rounded
                      color="indigo"
                      dark
                      @click="excluirMovimentacao"
                    >
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'
import moment from 'moment'

export default {
  name: 'ScriptInsertion',
  props: { },
  data () {
    return {
      valid: false,
      menuDestino: false,
      menuReferencia: false,
      menuExclusao: false,
      empresas: undefined,
      empresa: '',
      instituicaoRules: [
        v => !!v || 'A Empresas é obrigatória'
      ],
      empresaOpcoes: [],
      dateMesReferencia: new Date().toISOString().substr(0, 7),
      dateMesReferenciaCopia: new Date().toISOString().substr(0, 7),
      dateMesReferenciaFormatada: '',
      mesReferenciaRules: [
        v => !!v || 'A data de Referência é obrigatória'
      ],
      dateMesDestino: new Date().toISOString().substr(0, 7),
      dateMesDestinoCopia: new Date().toISOString().substr(0, 7),
      mesDestinoRules: [
        v => !!v || 'A data de Destino é obrigatória'
      ],
      mesAtual: new Date().toISOString().substr(0, 7),
      dateMesExclusao: new Date().toISOString().substr(0, 7),
      dateMesExclusaoCopia: new Date().toISOString().substr(0, 7),
      mesExclusaoRules: [
        v => !!v || 'A data de Exclusão é obrigatória'
      ],
      hasError: false,
      hasLoading: false,
      hasSuccess: false,
      cnpj: '',
      uidEmpresa: '',
      items: [],
      itemsJson: {},
      itemsArray: [],
      height: '',
      dialog: false,
      hasReturn: false,
      returnStatus: '',
      countMovimentacoes: 0,
      tipoMovimentacao: 'Copiar',
      tipoMovimentacaoRules: [
        v => !!v || 'O tipo de movimentação é obrigatória'
      ]
    }
  },
  computed: {
    computedDateFormattedReference () {
      var dateFormated = this.dateMesReferencia ? moment(this.dateMesReferencia).format('MMMM/YYYY') : ''
      dateFormated = dateFormated[0].toUpperCase() + dateFormated.substr(1)
      return dateFormated
    },
    computedDateFormattedDestine () {
      var dateFormated = this.dateMesDestino ? moment(this.dateMesDestino).format('MMMM/YYYY') : ''
      dateFormated = dateFormated[0].toUpperCase() + dateFormated.substr(1)
      return dateFormated
    },
    computedDateFormattedExclusao () {
      var dateFormated = this.dateMesExclusao ? moment(this.dateMesExclusao).format('MMMM/YYYY') : ''
      dateFormated = dateFormated[0].toUpperCase() + dateFormated.substr(1)
      return dateFormated
    },
    computedEmpresas () {
      this.getMovimentations()
      return this.empresa
    },
    computedTipoMovimentacao () {
      return this.tipoMovimentacao
    }
  },
  created () {
    this.getEmpresas()
  },
  mounted () {
    // Função para montar o tamanho certo da tela que mostrará o scroll correto
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.height = tamanhoPagina - 530
  },
  methods: {
    insereMovimentacao () {
      this.dialog = false
      this.hasLoading = true
      this.empresas.forEach((el) => {
        if (el.nome === this.empresa) {
          this.cnpj = el.cnpj
          this.uidEmpresa = el.id
        }
      })
      this.dateMesReferenciaCopia = moment(this.dateMesReferencia).format('YYYY-MM')
      this.dateMesDestinoCopia = moment(this.dateMesDestino).format('YYYY-MM')

      firebase.firestore().collection('socios')
        .where('cnpj_empresa', '==', this.cnpj)
        .where('socio', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc.data().mensalidade[this.dateMesReferenciaCopia]) {
              firebase.firestore().collection('socios').doc(doc.data().matricula).update({
                ['mensalidade.' + this.dateMesDestinoCopia]: {
                  'ano_mes': doc.data().mensalidade[this.dateMesReferenciaCopia].ano_mes,
                  'cnpj_empresa': doc.data().mensalidade[this.dateMesReferenciaCopia].cnpj_empresa,
                  'funcao': doc.data().mensalidade[this.dateMesReferenciaCopia].funcao,
                  'observacao': doc.data().mensalidade[this.dateMesReferenciaCopia].observacao,
                  'valor_mensalidade': doc.data().mensalidade[this.dateMesReferenciaCopia].valor_mensalidade
                }
              })
              this.countMovimentacoes += 1
            }
            console.log('this.countMovimentacoes  :: ' + this.countMovimentacoes)
            if (this.countMovimentacoes === 1) {
              const mesMensalidade = moment(this.dateMesDestino).format('MMMM')
              const mesAnoMovimentacao = mesMensalidade.charAt(0).toUpperCase() +
                                         mesMensalidade.slice(1) +
                                         '/' +
                                         this.dateMesDestinoCopia.substr(0, 4)
              console.log('mesAnoMovimentacao  :: ' + mesAnoMovimentacao)
              const cnpj = this.cnpj.replace(/[^\d]/g, '')

              firebase.firestore().collection('empresas').doc(cnpj).update({
                ['mensalidade.' + this.dateMesDestinoCopia]: mesAnoMovimentacao
              })
            }
            setTimeout(() => {
              if (this.countMovimentacoes > 0) {
                console.log('hasSuccess  :: ' + this.hasSuccess)
                this.hasLoading = false
                this.hasSuccess = true
              } else {
                this.hasError = true
                console.log('this.hasError  :: ' + this.hasError)
                setTimeout(() => {
                  this.hasError = false
                }, 5000)
              }
            }, 7000)
          })
        })

      // Desaparece a mensagem após 2 segundos
      setTimeout(() => {
        this.hasSuccess = false
        location.reload()
      }, 10000)
    },
    excluirMovimentacao () {
      this.dialog = false
      this.hasLoading = true
      this.empresas.forEach((el) => {
        if (el.nome === this.empresa) {
          this.cnpj = el.cnpj
          this.uidEmpresa = el.id
        }
      })
      this.dateMesExclusaoCopia = moment(this.dateMesExclusao).format('YYYY-MM')

      firebase.firestore().collection('socios')
        .where('cnpj_empresa', '==', this.cnpj)
        .where('socio', '==', true)
        .where('mensalidade', '!=', '')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc.data().mensalidade[this.dateMesExclusaoCopia]) {
              firebase.firestore().collection('socios')
                .doc(doc.data().matricula)
                .update({
                  ['mensalidade.' + this.dateMesExclusaoCopia]: firebase.firestore.FieldValue.delete()
                })
              this.countMovimentacoes += 1
            }

            if (this.countMovimentacoes === 1) {
              firebase.firestore().collection('empresas').doc(this.cnpj).update({
                ['mensalidade.' + this.dateMesExclusaoCopia.substr(3) + '-' + this.dateMesExclusaoCopia.substr(0, 2)]: firebase.firestore.FieldValue.delete()
              })
            }
            setTimeout(() => {
              if (this.countMovimentacoes > 0) {
                this.hasLoading = false
                this.hasSuccess = true
              } else {
                this.hasError = true
                setTimeout(() => {
                  this.hasError = false
                }, 5000)
              }
            }, 7000)
          })
        })

      // Desaparece a mensagem após 2 segundos
      setTimeout(() => {
        this.hasSuccess = false
        location.reload()
      }, 10000)
    },
    carregaCNPJ () {
      this.empresas.forEach((el) => {
        if (el.nome === this.instituicaoPrincipal) {
          this.cnpjInstituicaoPrincipal = el.cnpj
        } else if (el.nome === this.instituicaoSecundaria) {
          this.cnpjInstituicaoSecundaria = el.cnpj
        }
      })
    },
    getEmpresas () {
      // Valida se tem no Store os dados das instituições
      this.empresas = this.$store.state.empresas

      // Valida se tem instituições no Store senão faz consulta no BD
      if (this.empresas === undefined) {
        this.empresas = []
        firebase.firestore().collection('empresas').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc.data().acesso !== 'Administrador') {
              // Busca Empresas para o Filro
                this.empresas.push(doc.data())
                this.$store.dispatch('setEmpresas', this.empresas)
              }
            })
            this.empresas.forEach((el) => {
              this.empresaOpcoes.push(el.nome)
            })
            this.empresaOpcoes.sort()
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.empresas = this.$store.state.empresas
        this.empresas.forEach((el) => {
          this.empresaOpcoes.push(el.nome)
        })
        this.empresaOpcoes.sort()
      }
    },
    getMovimentations () {
      this.items = []
      this.itemsArray = []
      this.empresas.forEach((el) => {
        if (el.nome === this.empresa) {
          this.items.push(el.mensalidade)
        }
      })

      this.items.forEach((el) => {
        this.itemsJson = JSON.parse(JSON.stringify(el))
      })

      for (var item in this.itemsJson) {
        this.itemsArray.push([item, this.itemsJson[item]])
      }
      this.itemsArray.sort(function (a, b) {
        return a[1] - b[1]
      })

      this.itemsArray = this.itemsArray.sort().reverse()

      this.items = []
      this.itemsArray.forEach((el) => {
        this.items.push(el[1])
      })
    },
    cancel () {
      this.dialog = false
    },
    openDialog () {
      if (!this.$refs.form.validate()) {
        this.hasError = true
        this.hasLoading = false

        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.hasError = false
        }, 2000)
      }

      if (this.empresa) {
        this.dialog = true
      }
    },
    dataFormatter (date) {
      let dataFormatada = moment(date).format('MMMM/YYYY')

      return dataFormatada.charAt(0).toUpperCase() + dataFormatada.slice(1)
    },
    salvarDataMesDestino () {
      this.menuDestino = false
      this.$refs.menuDestino.save(this.date)
    },
    salvarDataMesReferencia () {
      this.menuReferencia = false
      this.$refs.menuReferencia.save(this.date)
    }
  }
}

</script>
<style  scoped>
.container {
  padding-top: 0;
  margin-top: -10px;
}

.v-content {
  height: 100vh;
}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.botoes{
  padding: 10px 36px;
  justify-content: center;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-application .indigo {
    height: 45px !important;
}

.v-card__text, .v-card__title {
  padding: 18px 26px 3px 26px;
}

.v-toolbar__title {
  margin: auto
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 280px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 25px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.dependentesTitle {
  font-size: 18px;
  margin-top: 0px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #041c32
}

.alertas {
  margin: 0px auto -13px;
  width: 90%;
}

.mx-auto {
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.movimenta {
  margin-top: -13px;
}

.tituloMovimenta {
    font-weight: 600;
    margin: -7px auto 1% 5%;
}

.fill-height {
  margin-top: -15px;
}

.textoModal{
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
  line-height: 1.7;
}

.textoModal b{
  color: #041c32;
}

.confirmaAcao{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px;
}

.BarraAtencao {
  position: relative;
  top: -7px;
  font-size: 22px;
  font-weight: bold;
}

.btnFechar {
  position: absolute;
  right: 10px;
  top: 10px

}

.BarraEditar {
  height: 45px !important;
}

.botoesModal{
  padding: 20px 5px 0px 0px ;
  margin-top: -20px;
  margin-bottom: -10px;
  justify-content: center;
}
</style>
