<template>
  <v-content>
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-hospital-building
      </v-icon>
      <v-card-text class="text">
        Instituições
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                cadastrar
              </v-toolbar-title>
            </v-toolbar>

            <!-- Formulario  -->
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <!-- Nome completo e abreviado -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <v-text-field
                      v-model="nome"
                      label="Nome Completo"
                      name="nome"
                      prepend-icon="mdi-hospital-building"
                      type="text"
                      :rules="nomeRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="cnpj"
                      v-mask="cnpjMascara"
                      label="CNPJ"
                      name="cnpj"
                      prepend-icon="mdi-newspaper-variant"
                      type="text"
                      :rules="cnpjRegras"
                      required
                    />
                  </v-col>
                </v-row>

                <!-- CEP, Endereço, Numero e Complemento  -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="cep"
                      v-mask="cepMask"
                      label="CEP"
                      name="cep"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :rules="cepRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                  >
                    <v-text-field
                      v-model="endereco"
                      label="Endereço"
                      name="endereco"
                      prepend-icon="mdi-map-marker"
                      type="text"
                      :rules="enderecoRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="numero"
                      label="Nº"
                      name="numero"
                      prepend-icon="mdi-home-group"
                      type="text"
                      :rules="numeroRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="complemento"
                      label="Compl"
                      name="complemento"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <!-- Bairro, Cidade, UF e Fone -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="bairro"
                      label="Bairro"
                      name="bairro"
                      prepend-icon="mdi-home-city"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="cidade"
                      label="Cidade"
                      name="cidade"
                      prepend-icon="mdi-map-marker"
                      type="text"
                      :rules="cidadeRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-select
                      v-model="uf"
                      label="UF"
                      name="uf"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :items="ufOpcoes"
                      :rules="ufRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="fone"
                      v-mask="foneMask"
                      label="Fone"
                      name="fone"
                      prepend-icon="mdi-phone"
                      type="text"
                      :rules="foneRegras"
                      required
                    />
                  </v-col>
                </v-row>

                <!-- Matriz e Filial -->
                <v-row>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-select
                      v-model="tipo"
                      :items="tipoOpcoes"
                      prepend-icon="mdi-clipboard-list-outline"
                      label="Tipo da Empresa"
                      :rules="tipoRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    v-if="tipo === 'Filial'"
                    cols="12"
                    sm=""
                  >
                    <v-select
                      v-model="nomeEmpresaMatriz"
                      :items="pesquisaNomeEmpresa"
                      :rules="pesquisaNomeEmpresaRegras"
                      label="Empresa Matriz"
                      clearable
                      @change="converteCNPJ"
                    />
                  </v-col>
                </v-row>

                <!-- Email e Senha -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="email"
                      label="E-mail"
                      name="email"
                      prepend-icon="mdi-email"
                      type="text"
                      :rules="emailRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-text-field
                      v-model="password"
                      label="Senha Inicial"
                      name="password"
                      prepend-icon="mdi-lock-question"
                      disabled
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-model="erro"
                type="error"
                dismissible
              >
                Confira as informações e tente novamente
              </v-alert>

              <v-alert
                v-model="sucesso"
                type="success"
                dismissible
              >
                Empresa Cadastrada com sucesso
              </v-alert>
            </div>

            <v-card-actions class="botoes">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="cancelar"
                >
                  Cancelar
                </v-btn>
              </v-flex>
              <v-btn
                class="my-2"
                rounded
                color="indigo"
                dark
                :loading="carregando"
                @click="cadastrar"
              >
                Cadastrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'CadastrarEmpresa',
  props: { },
  data () {
    return {
      valid: false,
      nome: '',
      info: '',
      nomeRegras: [
        v => !!v || 'O nome é obrigatório'
      ],
      phone: '',
      cnpj: '',
      cnpjRegras: [
        v => !!v || 'O CNPJ é obrigatório',
        v => v.length === 18 || 'Insira um CNPJ válido'

      ],
      cnpjMascara: '##.###.###/####-##',
      cep: '',
      cepRegras: [
        v => !!v || 'O CEP é obrigatório',
        v => v.length === 9 || 'Insira um CEP válido'
      ],
      cepMask: '#####-###',
      endereco: '',
      enderecoRegras: [
        v => !!v || 'O endereço é obrigatório'
      ],
      numero: '',
      numeroRules: [
        v => !!v || 'O Numero é obrigatório'
      ],
      fone: '',
      foneRegras: [
        v => !!v || 'O fone é obrigatório',
        v => v.length === 14 || 'Insira um Telefone válido'
      ],
      foneMask: '(##) ####-####',
      complemento: '',
      bairro: '',
      cidade: '',
      cidadeRegras: [
        v => !!v || 'A cidade é obrigatória'
      ],
      uf: '',
      ufOpcoes: [
        'RS',
        'SC',
        'PR'
      ],
      ufRegras: [
        v => !!v || 'A UF é obrigatória'
      ],
      email: '',
      emailRegras: [
        v => !!v || 'O email é obrigatório',
        v => /.+@.+\..+/.test(v) || 'Digite um e-mail válido'
      ],
      showPassword: false,
      password: '123456',
      acesso: 'Usuário',
      tipoOpcoes: [
        'Matriz',
        'Filial'
      ],
      empresas: undefined,
      pesquisaNomeEmpresa: [],
      pesquisaNomeEmpresaRegras: [
        v => !!v || 'A Empresa Matriz é obrigatória'
      ],
      tipo: '',
      cnpjEmpresaMatriz: '',
      nomeEmpresaMatriz: '',
      tipoRegras: [
        v => !!v || 'O tipo da Empresa é obrigatória'
      ],
      erro: false,
      carregando: false,
      sucesso: false,
      senhaAcesso: '',
      emailAcesso: ''
    }
  },
  computed: { },
  created () {
    this.buscarCredenciais()
    this.retornaEmpresas()
  },
  methods: {
    cadastrar () {
      this.carregando = true
      this.erro = false
      if (!this.$refs.form.validate()) {
        this.erro = true
        this.carregando = false

        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.erro = false
        }, 2000)
        return
      }

      const cnpj = this.cnpj.replace(/[^\d]/g, '')
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          user => {
            firebase.firestore().collection('empresas').doc(cnpj).set({
              nome: this.capitalize(this.nome),
              cnpj: this.cnpj,
              cep: this.cep,
              endereco: this.capitalize(this.endereco),
              numero: this.numero,
              fone: this.fone,
              complemento: this.complemento,
              bairro: this.capitalize(this.bairro),
              cidade: this.capitalize(this.cidade),
              uf: this.uf,
              email: this.email.toLowerCase(),
              acesso: this.acesso,
              uid: user.user.uid,
              cnpj_matriz: this.cnpjEmpresaMatriz,
              senha: this.password,
              tipo: this.tipo
            })
            this.carregando = false
            this.sucesso = true

            this.relogin()
          },
          // eslint-disable-next-line
          err => {
            this.erro = true
            this.carregando = false
            alert(`Aconteceu algo inesperado. Erro: ${err}`)
          }
        )
    },
    cancelar () { // Método para voltar a página anterior
      this.$refs.form.reset()
      this.$store.dispatch('setEmpresas', undefined)
      this.$router.push('empresas')
    },
    capitalize (value) { // Método para padronizar dados no Banco de Dados
      // Recebe o valor inteiro
      let loweredText = value.toLowerCase()
      // Separa por virgula todas as palavras
      let words = loweredText.split(' ')

      // Faz um for em todas as palavras e coloca a primeira letra maior
      for (let a = 0; a < words.length; a++) {
        let w = words[a]
        let firstLetter = w[0]

        if (w === 'dos' || w === 'das') {
          w = firstLetter + w.slice(1)
        } else if (w.length > 2 || w.replace('.', '').length === 1) {
          w = firstLetter.toUpperCase() + w.slice(1)
        } else {
          w = firstLetter + w.slice(1)
        }
        // retorna cada palavra com a primeira maior
        words[a] = w
      }

      // retorna a frase inteira
      return words.join(' ')
    },
    relogin () {
      // Recebe a senha do Banco e decodifica acesso
      const decodificaPrimeiroNivel = atob(this.senhaAcesso)

      // Decodifica o segundo nível
      const senhaRetornadaHash = decodificaPrimeiroNivel.slice(9, -17)

      // Retorna senha decodificada
      const senhaDecodificada = atob(senhaRetornadaHash)

      firebase
        .auth()
        .signInWithEmailAndPassword(this.emailAcesso, senhaDecodificada)
        .then(doc => {
          setTimeout(() => {
            this.cancelar()
          }, 2000)
        })
        .catch((err) => {
          console.log('Error getting documents', err)
        })
    },
    async buscarCredenciais () {
      const userLogado = firebase.auth().currentUser.uid
      let senha = ''
      // Pega e Email da sessão
      this.emailAcesso = firebase.auth().currentUser.email

      await firebase.firestore().collection('empresas')
        .where('uid', '==', userLogado)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            senha = doc.data().senha
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })
      this.senhaAcesso = senha
    },
    converteCNPJ () {
      this.empresas.forEach((el) => {
        if (el.nome === this.nomeEmpresaMatriz) {
          this.cnpjEmpresaMatriz = el.cnpj
        }
      })
    },
    retornaEmpresas () {
      // Valida se tem no Store os dados das empresas
      this.empresas = this.$store.state.empresas

      // Valida se tem empresas no Store senão faz consulta no BD
      if (this.empresas === undefined) {
        this.empresas = []
        firebase.firestore().collection('empresas').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.empresas.push(doc.data())
              this.$store.dispatch('setEmpresas', this.empresas)
            })
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.empresas = this.$store.state.empresas
      }

      // Busca o nome das empresas para o filtro e ordena por
      setTimeout(() => {
        this.empresas.forEach((el) => {
          this.pesquisaNomeEmpresa.push(el.nome)
        })
        this.pesquisaNomeEmpresa.sort()
      }, 2000)

      // Loading do carregamento da tabela
      this.loading = false

      // Desaparece o alerta de sucesso e Modal de Edição após 2 segundos
      setTimeout(() => {
        this.hasReturn = false
        this.returnStatus = ''
        this.dialog = false
      }, 2000)
    }
  }
}

</script>
<style  scoped>
.v-content {
  height: 100vh;

}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.botoes{
  padding: 20px 36px 10px;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-application .indigo {
    height: 45px !important;
}

.v-card__text, .v-card__title {
  padding: 18px 26px 3px 26px;
}

.v-toolbar__title {
  margin: auto
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.v-text-field {
    margin-top: -8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 90px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 25px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.fill-height{
  padding-top: 48px;
  align-items: baseline;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  bottom: 39px;
}

.fill-height::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 36px;
}

.fill-height::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
  -webkit-border-radius: 3px;
}

.fill-height::-webkit-scrollbar-thumb {
  height: 160px;
  background-color: #666;
  -webkit-border-radius: 3px;
}

.alertas {
  margin: 0px auto -13px;
  width: 90%;
}
</style>
