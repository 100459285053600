<template>
  <v-content>
    <v-card class="header">
      <v-icon class="icon">
        mdi-cog
      </v-icon>
      <v-card-text class="text">
        Configuração
      </v-card-text>
    </v-card>

    <v-row>
      <!-- Perfil -->
      <!-- <v-col cols="12" sm="3" >
            <v-hover v-slot:default="{ hover }" >
              <v-card :elevation="hover ? 12 : 2" class="mx-auto" to="/perfil">
                <v-icon >mdi-account-circle</v-icon>
                <v-card-text >Perfil</v-card-text>
              </v-card>
            </v-hover>
          </v-col> -->

      <!-- Listar Instituições Cadastradas -->
      <v-col
        v-show="isAdmin"
        cols="12"
        sm="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            to="/instituicoes"
          >
            <v-icon>mdi-hospital-building</v-icon>
            <v-card-text>Instituições Cadastradas</v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- Listar Associados -->
      <v-col
        cols="12"
        sm="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            to="/associados"
          >
            <v-icon>mdi-account-group</v-icon>
            <v-card-text>Listar Associados</v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- Relatório dos Associados por Instituição -->
      <v-col
        cols="12"
        sm="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            to="/relatorios"
          >
            <v-icon>mdi-file-chart</v-icon>
            <v-card-text>Relatórios</v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- Controle de Acesso de Hospitais -->
      <!-- <v-col cols="12" sm="4" v-show="isAdmin">
            <v-hover v-slot:default="{ hover }">
              <v-card :elevation="hover ? 12 : 2" class="mx-auto">
                <v-icon >mdi-account-lock</v-icon>
                <v-card-text >Bloquear Edição</v-card-text>
                <v-switch class="switchAssociado" inset
                    v-model="selectSwitch"
                    @change="blockAssociated"
                />
              </v-card>
            </v-hover>
          </v-col> -->
    </v-row>
  </v-content>
</template>

<script>

import firebase from 'firebase'

export default {
  data () {
    return {
      isAdmin: false,
      selectSwitch: '',
      db: firebase.firestore(),
      teste: 'teste2',
      acessoUserLogado: '',
      associados: undefined
    }
  },
  created () {
  },
  methods: {
    /** Função que busca se o permite Editar está ativo ou não */
    getLetsEdit () {
      this.db.collection('controle-acesso').doc('0001').get()
        .then(doc => {
          this.selectSwitch = doc.data().permiteEditar
          this.$store.dispatch('setPermiteEditar', this.selectSwitch)
        })
        .catch((err) => {
          console.log('Error getting documents', err)
        })
    }
  }
}
</script>

<style  scoped>
.row {
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 50px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 20px;
}

.mx-auto {
  height: 190px;
  width: 230px;
  padding-top: 13px;
  margin-top: 25px;
}
.v-card__text {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.v-icon{
  display: flex;
  margin-top: 20px;
  font-size: 80px;
}

.switchAssociado {
  position: relative;
  left: 40%;
  margin-top: -15px;
}

</style>
