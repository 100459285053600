<template>
  <v-content>
    <v-card class="header">
      <v-icon class="icon">
        mdi-home
      </v-icon>
      <v-card-text class="text">
        Home
      </v-card-text>
    </v-card>

    <v-row>
      <!-- Listar Instituições Cadastradas -->
      <v-col
        v-if="isAdmin"
        cols="12"
        sm="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            to="/empresas"
          >
            <v-icon>mdi-hospital-building</v-icon>
            <v-card-text>Empresas</v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- Listar Sócios -->
      <v-col
        cols="12"
        sm="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            to="/socios"
          >
            <v-icon>mdi-account-group</v-icon>
            <v-card-text>Sócios</v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- Relatório dos Sócios -->
      <v-col
        cols="12"
        sm="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto"
            to="/relatorios"
          >
            <v-icon>mdi-bookmark-check</v-icon>
            <v-card-text>Relatórios</v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>

import firebase from 'firebase'

export default {
  data () {
    return {
      isAdmin: false,
      selectSwitch: '',
      db: firebase.firestore(),
      acessoUserLogado: ''
    }
  },
  async created () {
    await this.setIsAdmin()
  },
  methods: {
    /**
     * Função para verificar se o acesso é Administrador e setar no Store
     * o isAdmin como true
     */
    async setIsAdmin () {
      this.uidCurrentUser = firebase.auth().currentUser.uid
      let validaAcesso
      await firebase.firestore().collection('empresas')
        .where('uid', '==', this.uidCurrentUser)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            validaAcesso = doc.data().acesso === 'Administrador'
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })

      this.isAdmin = validaAcesso
      this.$store.dispatch('setAdm', this.isAdmin)
    }
  }
}
</script>

<style  scoped>
.v-content {

}

.row {
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 50px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 20px;
}

.mx-auto {
  height: 190px;
  width: 230px;
  padding-top: 13px;
  margin-top: 25px;
}
.v-card__text {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.v-icon{
  display: flex;
  margin-top: 20px;
  font-size: 80px;
}

.switchAssociado {
  position: relative;
  left: 40%;
  margin-top: -15px;
}

</style>
