import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContent,[_c(VCard,{staticClass:"header"},[_c(VIcon,{staticClass:"icon"},[_vm._v(" mdi-calendar-sync-outline ")]),_c(VCardText,{staticClass:"text"},[_vm._v(" Script Ajuste ")])],1),_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"color":"indigo","dark":"","flat":""}},[_c(VToolbarTitle,{staticClass:"tituloBarra text-uppercase"},[_vm._v(" Scripts de Ajuste ")])],1),_c(VCardActions,{staticClass:"botoes"},[_c(VBtn,{staticClass:"my-2",attrs:{"rounded":"","color":"success"},on:{"click":_vm.getEmpresas}},[_vm._v(" getAssociados ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }