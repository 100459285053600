import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'
import axios from 'axios'
import { VueMaskDirective } from 'v-mask'
import VueCurrencyInput from 'vue-currency-input'

Vue.use(axios)
Vue.directive('mask', VueMaskDirective)
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: null
  }
})

Vue.config.productionTip = false

// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyBJBVx35BLnd96MH_bFMBVZXa7wd87lA4o',
  authDomain: 'sindi-associado.firebaseapp.com',
  databaseURL: 'https://sindi-associado.firebaseio.com',
  projectId: 'sindi-associado',
  storageBucket: 'sindi-associado.appspot.com',
  messagingSenderId: '195948254530',
  appId: '1:195948254530:web:dd058f5044647422baa4db',
  measurementId: 'G-H4F4GB2KZH'
})
firebase.analytics()

let app = ''
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})

const db = firebase.firestore()
const auth = firebase.auth()

db.enablePersistence()

export { db, auth }
