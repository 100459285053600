<template>
  <v-content id="tamanhoDiv">
    <v-card class="header">
      <v-icon class="icon">
        mdi-account-group
      </v-icon>
      <v-card-text class="text">
        Sócios
      </v-card-text>
    </v-card>

    <!-- Modal Pergunta se quer excluir associado -->
    <v-dialog
      v-model="dialog"
      width="42%"
    >
      <v-card class="elevation-12">
        <!-- Barra de Título -->
        <v-toolbar
          class="BarraEditar"
          color="indigo"
          dark
          flat
        >
          <v-toolbar-title class="BarraAtencao text-uppercase">
            atenção !!!
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <div class="textoModal">
            <p class="font-weight-bold">
              Nessa ação o associado <b>{{ itemSelected.nome }}</b>
              será excluído definitivamente!!!
            </p>
            <p class="confirmaAcao">
              Você confirma essa <b>EXCLUSÃO?</b>
            </p>
          </div>

          <v-card-actions class="botoesModal">
            <v-flex>
              <v-btn
                class="my-2"
                rounded
                color="error"
                dark
                @click="cancelar"
              >
                Cancelar
              </v-btn>
            </v-flex>
            <v-btn
              :loading="dialogLoading"
              class="my-2"
              rounded
              color="indigo"
              dark
              @click="excluirDependentesSocio"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal Confirmação de Exclusão -->
    <v-dialog
      v-model="confirmacaoEnvio"
      width="41%"
    >
      <v-card class="elevation-12">
        <!-- Barra de Título -->
        <v-toolbar
          class="BarraEditar"
          color="indigo"
          dark
          flat
        >
          <v-toolbar-title class="BarraAtencao text-uppercase">
            Sucesso !!!
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text style="text-align: center">
          <div class="textoModal">
            <p class="font-weight-bold">
              O associado
              <b>{{ itemSelected.nome }}</b> foi excluido!!!
            </p>

            <v-card-actions class="botoesModal">
              <v-btn
                class="my-2"
                rounded
                color="indigo"
                dark
                @click="pararLoadingBotaoEnvio"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal Editar Valor e Observação Associado -->
    <v-dialog
      v-model="modalEditarValorObs"
      width="70%"
    >
      <v-card class="elevation-12">
        <!-- Barra de Título -->
        <v-toolbar
          class="BarraEditar"
          color="indigo"
          dark
          flat
        >
          <v-toolbar-title class="tituloBarra text-uppercase">
            editar
          </v-toolbar-title>
        </v-toolbar>

        <!-- Formulario  -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <!-- Nome completo e Matricula -->
            <v-row>
              <v-col
                cols="12"
                sm="2"
              >
                <v-text-field
                  v-model="itemSelected.matricula"
                  label="Matricula"
                  name="matricula"
                  prepend-icon="mdi-newspaper-variant"
                  type="text"
                  disabled
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="itemSelected.nome"
                  label="Nome Completo"
                  name="nome"
                  prepend-icon="mdi-account-circle"
                  type="text"
                  disabled
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="itemSelected.cpf"
                  v-mask="'###.###.###-##'"
                  label="CPF"
                  name="cpf"
                  prepend-icon="mdi-text-box-check"
                  :rules="itemSelected.cpfRegra"
                  required
                />
              </v-col>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="itemSelected.data_admissao"
                    label="Data de Admissão"
                    name="dataAdmissao"
                    prepend-icon="mdi-calendar-range"
                    type="text"
                    required
                  />
                  <!-- :rules="dataAdmissaoRegra" -->
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-select
                    v-model="itemSelected.funcao"
                    label="Função"
                    name="funcao"
                    prepend-icon="mdi-account-hard-hat"
                    type="text"
                    :items="funcoesEmpresa"
                    :rules="funcaoRegra"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="itemSelected.setor"
                    label="Setor"
                    name="setor"
                    prepend-icon="mdi-briefcase-account-outline"
                    type="text"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="itemSelected.valor_mensalidade"
                    label="Valor R$"
                    name="valor"
                    prepend-icon="mdi-cash-usd-outline"
                    type="text"
                    :rules="[currencyFormat]"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-text-field
                    v-model="itemSelected.observacao"
                    label="Observação"
                    name="observacao"
                    prepend-icon="mdi-tooltip-text"
                    type="text"
                    required
                  />
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Alertas de Sucesso e Erro -->
        <div class="alertas">
          <v-alert
            v-model="erro"
            type="error"
            dismissible
          >
            Confira as informações e tente novamente
          </v-alert>

          <v-alert
            v-model="temErroCPF"
            type="error"
            dismissible
          >
            CPF Inválido, verifique e preenche corretamente
          </v-alert>

          <v-alert
            v-model="sucesso"
            type="success"
            dismissible
          >
            Colaborador alterado com sucesso
          </v-alert>
        </div>

        <v-card-actions class="botoes">
          <v-flex>
            <v-btn
              class="my-2"
              rounded
              color="error"
              dark
              @click="cancelar"
            >
              Cancelar
            </v-btn>
          </v-flex>
          <v-btn
            class="my-2"
            rounded
            color="indigo"
            dark
            :loading="carregando"
            @click="atualizaDadosSocios"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="filtroSelecaoEmpresa">
      <h2 v-if="!temFiliais">
        {{ nomeAcessoUserLogado }}
      </h2>
      <v-select
        v-if="temFiliais"
        v-model="empresaSelecionada"
        :items="filtroNomeEmpresa"
        label="Empresas"
        clearable
        @change="filtroEmpresa"
      />
    </div>

    <!-- CheckBox para filtrar sócios ativos e Inativos -->
    <div
      v-if="isAdmin"
      class="status"
    >
      <p class="subtitle-2 text-center font-weight-bold">
        Sócio
      </p>
      <v-row class="dark--text">
        <div @click="filtroStatusSocio()">
          <v-checkbox
            v-model="socioAtivo"
            label="Ativo"
            color="indigo"
          />
        </div>
        <div @click="filtroStatusSocio()">
          <v-checkbox
            v-model="socioInativo"
            label="Inativo"
            color="red"
            class="statusCheckbox"
          />
        </div>
      </v-row>
    </div>

    <!-- Tabela com empresas -->
    <v-data-table
      sort-by="nome"
      class="elevation-1"
      :headers="isAdmin ? cabecalhoAdministrador : (temFiliais ? cabecalhoEmpresaMatriz :cabecalhoEmpresas)"
      :fixed-header="fixedHeader"
      :height="height"
      :items="socios"
      :search="search"
      :loading="loading"
      loading-text="Carregando Dados... Por Favor Aguarde."
    >
      <template #top>
        <v-toolbar
          flat
          color="white"
        >
          <v-spacer />
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="search"
              class="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>
          <v-btn
            v-if="isAdmin"
            class="mx-2"
            fab
            dark
            color="#1976d2"
            @click="cadastrar"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <!-- Nome da Empresa Principal do Associado -->
      <template #item.empresas="{ item }">
        {{ retornaNomeEmpresa(item) }}
      </template>

      <!-- Valor da Mensalidade com Cifrão separado por principal e secundaria -->
      <template #item.mensalidade="{ item }">
        {{ retornaMensalidade(item) }}
      </template>

      <!-- Status do Sócio -->
      <template #item.statusSocio="{ item }">
        {{ ajusteStatusSocio(item) }}
      </template>

      <!-- Botão de Ações lateral tabela -->
      <template #item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="detalhesSocio(item)"
        >
          mdi-text-box-search-outline
        </v-icon>
        <v-icon
          v-if="isAdmin"
          small
          class="mr-2"
          @click="editarSocio(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="!selectSwitch && !isAdmin"
          small
          class="mr-2"
          @click="editarSocio(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isAdmin"
          small
          @click="excluirSocio(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-content>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Socios',
  components: { },
  props: { },
  data () {
    return {
      valid: false,
      erro: false,
      temErroCPF: false,
      sucesso: false,
      search: '',
      db: firebase.firestore(),
      socioAtivo: true,
      socioInativo: false,
      cabecalhoAdministrador: [
        { text: 'Matrícula', align: 'left', value: 'matricula' },
        { text: 'Nome', value: 'nome' },
        { text: 'CPF', value: 'cpf', sortable: false  },
        { text: 'Empresa', value: 'empresas' },
        { text: 'Cargo', value: 'funcao'},
        { text: 'Mensalidade', value: 'mensalidade' },
        { text: 'Sócio', value: 'statusSocio', sortable: false },
        { text: 'Ações', value: 'action', sortable: false }
      ],
      cabecalhoEmpresas: [
        { text: 'Matrícula', align: 'left', value: 'matricula' },
        { text: 'Nome', value: 'nome' },
        { text: 'CPF', value: 'cpf', sortable: false },
        { text: 'Cargo', value: 'funcao'},
        { text: 'Mensalidade', value: 'mensalidade' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Ações', value: 'action', sortable: false }
      ],
      cabecalhoEmpresaMatriz: [
        { text: 'Matrícula', align: 'left', value: 'matricula' },
        { text: 'Nome', value: 'nome' },
        { text: 'CPF', value: 'cpf', sortable: false  },
        { text: 'Empresa', value: 'empresas'},
        { text: 'Cargo', value: 'funcao' },
        { text: 'Mensalidade', value: 'mensalidade' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Ações', value: 'action', sortable: false }
      ],
      fixedHeader: true,
      height: 365,
      pagination: true,
      loading: true,
      socios: undefined,
      filtroAssociados: [],
      empresas: undefined,
      empresaSelecionada: undefined,
      filtroNomeEmpresa: [],
      dialog: false,
      dialogLoading: false,
      confirmacaoEnvio: false,
      matriculaExclusao: '',
      carregando: false,
      // Credenciais Usuário Logado
      userLogado: '',
      dadosUsuarioLogado: undefined,
      nomeAcessoUserLogado: '',
      isAdmin: undefined,
      selectSwitch: false,
      modalEditarValorObs: false,
      currencyFormat: (value) => {
        const regex = /^\d{1,3}(.\d{3})*,\d{2}$/
        return regex.test(value) || 'Insira um valor válido (por ex.: 34,56 ou 0,00)'
      },
      // Edição Modal
      dataAdmissaoRegra: [
        v => !!v || 'A data de admissão é obrigatória'
      ],
      cpfRegra: [
        v => !!v || 'O CPF é obrigatório'
      ],
      funcaoRegra: [
        v => !!v || 'A Função é obrigatória'
      ],
      itemSelected: {
        matricula: '',
        nome: '',
        cpf: '',
        dataAdmissao: '',
        funcao: '',
        funcoesEmpresa: [],
        setor: '',
        cargo: '',
        mensalidade: '',
        observacao: '',
        cnpjEmpresaPesquisada: undefined,
        // Modal Edição Empresa Logada
        valorMensalidadeEmpresaLogada: '',
        observacaoEmpresaLogada: ''
      },
      cnpjMatrizFiliais: [],
      temFiliais: false,
      funcoesEmpresa: []
    }
  },
  async created () {
    await this.retornaEmpresas()
    await this.buscaCredenciais()
    await this.retornaAssociados()
    this.configuracoesSistema()
    this.retornaFuncaoTrabalho()
  },
  mounted () {
    // Função para montar o tamanho certo da tela que mostrará o scroll correto
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.height = tamanhoPagina - 269
  },
  methods: {
    // Função para pegar as credenciais
    async buscaCredenciais () {
      this.userLogado = firebase.auth().currentUser.uid

      // Guarda os dados da empresa logada
      this.empresas.forEach(doc => {
        if (doc.uid === firebase.auth().currentUser.uid) {
          this.dadosUsuarioLogado = doc
        }
      })
      let contarFiliais = 0

      if (this.dadosUsuarioLogado === undefined) {
        location.reload()
      }

      if (this.dadosUsuarioLogado.acesso === 'Administrador') {
        this.isAdmin = true
        this.temFiliais = true
      } else {
        this.isAdmin = false

        // Verifica se a empresa logada tem filiais
        this.empresas.forEach(doc => {
          if (doc.cnpj_matriz === this.dadosUsuarioLogado.cnpj) {
            contarFiliais += 1
          }
        })

        if (contarFiliais > 0) {
          this.temFiliais = true
          this.retornaFiliais()
        } else {
          this.temFiliais = false
        }

        // Guarda o Nome do acesso do Usuário Logado
        this.nomeAcessoUserLogado = this.dadosUsuarioLogado.nome
      }
    },
    // Função para pegar todos os socios no banco
    async retornaAssociados () {
      // Se a empresa Logada é Administrador
      if (this.isAdmin) {
        // Valida se tem no Store os dados dos socios
        this.socios = this.$store.state.socios

        if (this.socios === undefined) {
          this.socios = []

          await firebase.firestore().collection('socios').get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                this.socios.push(doc.data())
              })
              this.loading = false
              // Guarda os dados dos socios no store
              this.$store.dispatch('setSocios', this.socios)
            })
            .catch((err) => {
              console.log('Error getting documents', err)
            })
        } else {
          this.loading = false
          this.socios = this.$store.state.socios
        }

        // Se o usuario logado for as empresas
      } else {
        /** Se for Matriz deve pesquisar pelo CNPJ da Matriz */
        this.socios = this.$store.state.socios

        if (this.temFiliais) {
          if (this.socios === undefined) {
            this.socios = []
            let associadoTemp = []
            await firebase.firestore().collection('socios')
              .where('cnpj_empresa', 'in', this.cnpjMatrizFiliais)
              .where('socio', '==', true)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                  associadoTemp.push(doc.data())
                })
              })
              .catch(function (error) {
                console.log('Error getting documents: ', error)
              })
            setTimeout(() => {
              this.loading = false
            }, 2000)
            this.socios = associadoTemp
            // Guarda os dados dos socios no store
            this.$store.dispatch('setSocios', this.socios)
          } else {
            this.loading = false
            this.socios = this.$store.state.socios
          }
          this.empresaSelecionada = undefined
        } else { // Logado sem Filial
          // Pesquisa apenas pelo CNPJ da Filial
          if (this.socios === undefined) {
            this.socios = []
            let associadoTemp = []
            await firebase.firestore().collection('socios')
              .where('cnpj_empresa', '==', this.dadosUsuarioLogado.cnpj)
              .where('socio', '==', true)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                  associadoTemp.push(doc.data())
                })
              })
              .catch(function (error) {
                console.log('Error getting documents: ', error)
              })
            this.loading = false
            this.socios = associadoTemp
            // Guarda os dados dos socios no store
            this.$store.dispatch('setSocios', this.socios)
          } else {
            this.loading = false
            this.socios = this.$store.state.socios
          }
          this.empresaSelecionada = undefined
        }
      }
      this.filtroAssociados = this.socios

      if (this.isAdmin) {
        this.socios = [ ...this.filtroAssociados ].filter(socios => socios.socio === true)
      }
    },
    retornaFiliais () {
      // Inclui a matriz para exibir no select
      this.cnpjMatrizFiliais.push(this.dadosUsuarioLogado.cnpj)

      this.empresas.forEach((el) => {
        if (el.cnpj_matriz === this.dadosUsuarioLogado.cnpj) {
          this.cnpjMatrizFiliais.push(el.cnpj)
        }
      })

      // Busca o nome das empresas para o filtro e ordena por
      if (this.dadosUsuarioLogado.acesso === 'Administrador') {
        this.filtroNomeEmpresa = []
        this.empresas.forEach((el) => {
          this.filtroNomeEmpresa.push(el.nome)
        })
      } else {
        this.filtroNomeEmpresa = []
        this.empresas.forEach((el) => {
          this.cnpjMatrizFiliais.forEach((el1) => {
            if (el1 === el.cnpj) {
              this.filtroNomeEmpresa.push(el.nome)
            }
          })
        })
      }
      this.filtroNomeEmpresa.sort()
    },
    // Função que busca todas as instuições das collections
    async retornaEmpresas () {
      let empresasSemAdm = []

      // Valida se tem no Store os dados das empresas
      this.empresas = this.$store.state.empresas

      // Valida se tem empresas no Store senão faz consulta no BD
      if (this.empresas === undefined) {
        this.empresas = []

        await this.db.collection('empresas')
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.empresas.push(doc.data())
            })
            this.empresas.forEach(el => {
              if(el.acesso === 'Usuário'){
                empresasSemAdm.push(el)
              }
            })
            this.$store.dispatch('setEmpresas', empresasSemAdm)

          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        empresasSemAdm = this.$store.state.empresas
      }
      // Busca o nome das empresas para o filtro e ordena por
      empresasSemAdm.forEach((el) => {
        this.filtroNomeEmpresa.push(el.nome)
      })

      this.filtroNomeEmpresa.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    },
    // Função que pega as funções dos socios para o select
    async retornaFuncaoTrabalho () {
      this.funcoesEmpresa = this.$store.state.funcaoEmpresa

      if (this.funcoesEmpresa === undefined) {
        this.funcoesEmpresa = []
        await firebase.firestore().collection('configuracao')
          .where('configuracao', '==', 'funcao')
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.funcoesEmpresa.push(doc.data().descricao)
            })
            this.$store.dispatch('setFuncaoEmpresa', this.funcoesEmpresa)
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.funcoesEmpresa = this.$store.state.funcaoEmpresa
      }

      let dataFunctions = []

      this.funcoesEmpresa.forEach(doc => {
        dataFunctions.push(doc)
      })

      this.funcoesEmpresa = []

      dataFunctions[0].forEach(el => {
        this.funcoesEmpresa.push(el)
      })

      this.funcoesEmpresa = this.funcoesEmpresa.sort()
    },
    /* Função que pega os cnpj retornado da collections socios e cruza com
       o cnpj das empresas para mostrar na tabela da tela o nome da
       Empresa */
    retornaNomeEmpresa (item) {
      let nomeEmpresa
      // Varre todas as empresas com o cnpj e retorna o nome da Empresa
      this.empresas.forEach((el) => {
        if (this.empresaSelecionada === undefined || this.empresaSelecionada === null) {
          if (el.cnpj === item.cnpj_empresa) {
            nomeEmpresa = el.nome
          }
        } else {
          if (el.cnpj === item.cnpj_empresa) {
            nomeEmpresa = this.empresaSelecionada
          }
        }
      })
      return nomeEmpresa
    },
    // Função para filtrar as empresas na tabela
    filtroEmpresa () {
      // Varre todas as empresas para search o nome a partir do cnpj
      this.empresas.forEach((el) => {
        if (el.nome === this.empresaSelecionada) {
          this.cnpjEmpresaPesquisada = el.cnpj
        }
      })

      /* Verifica se está vazio então retorna todos os dados utilizando
         o this.filtroAssociados, senão faz o filtro a partir da empresa
         selecionada no filtro */
      const filterAssociados = [ ...this.filtroAssociados ]

      // Caso selecione uma empresa
      if (this.empresaSelecionada !== undefined &&
          this.empresaSelecionada !== null) {
        // Valida para retornar todos independente de socioAtivo ou socioInativo da empresa selecionada
        if ((this.socioAtivo === true && this.socioInativo === true) ||
            (this.socioAtivo === false && this.socioInativo === false)) {
          this.socios = filterAssociados
            .filter(
              socios =>
                socios.cnpj_empresa === this.cnpjEmpresaPesquisada)

        // Retorna todos os ativos da empresa selecionada
        } else if (this.socioAtivo === true && this.socioInativo === false) {
          this.socios = filterAssociados
            .filter(
              socios =>
                socios.cnpj_empresa === this.cnpjEmpresaPesquisada &&
                socios.socio === true)

        // Retorna apenas os inativos da empresa selecionada
        } else {
          this.socios = filterAssociados
            .filter(
              socios =>
                socios.cnpj_empresa === this.cnpjEmpresaPesquisada &&
                socios.socio === false)
        }

      // Caso não selecione nenhuma empresa
      } else {
        // Retorna todos os ativos do sistema
        if (this.socioAtivo === true && this.socioInativo === false) {
          this.socios = filterAssociados.filter(socios => socios.socio === true)

        // Retorna todos os inativos do sistema
        } else if (this.socioAtivo === false && this.socioInativo === true) {
          this.socios = filterAssociados.filter(socios => socios.socio === false)

        // Retorna todos os socios no sistema sem filtro
        } else {
          this.socios = filterAssociados.filter(socios => socios.cnpj_empresa !== '')
        }
      }
    },
    // Função de Redirecionamento de página para cadastrar associado
    cadastrar () {
      this.$router.push('/cadastrar-socio')
    },
    // Função para filtrar por socioAtivo e socioInativo
    filtroStatusSocio () {
      const filterAssociados = [ ...this.filtroAssociados ]
      let filtroEmpresa
      let cnpjEmpresaPesquisada

      if (this.empresaSelecionada === undefined ||
            this.empresaSelecionada === null) {
        filtroEmpresa = ''
      } else {
        filtroEmpresa = this.empresaSelecionada
      }

      // Valida o Filtro com a empresa selecionada
      if (this.empresaSelecionada !== '') {
        // Varre todas as empresas para search o nome a partir do cnpj
        this.empresas.forEach((el) => {
          if (el.nome === this.empresaSelecionada) {
            cnpjEmpresaPesquisada = el.cnpj
          }
        })
      }

      // Socio ativo
      if (this.socioAtivo === true && this.socioInativo === false) {
        // Com empresa selecionada
        if (filtroEmpresa !== '') {
          this.socios = filterAssociados.filter(socios => socios.socio === true &&
        socios.cnpj_empresa === cnpjEmpresaPesquisada)

        // Sem empresa selecionada
        } else {
          this.socios = filterAssociados.filter(socios => socios.socio === true)
        }

        // Socio inativo e empresa selecionada
      } else if (this.socioInativo === true && this.socioAtivo === false) {
        // Com empresa selecionada
        if (filtroEmpresa !== '') {
          this.socios = filterAssociados.filter(socios => socios.socio === false &&
        socios.cnpj_empresa === cnpjEmpresaPesquisada)

        // Sem empresa selecionada
        } else {
          this.socios = filterAssociados.filter(socios => socios.socio === false)
        }

        // Retorna todos socios
      } else {
        // Somente empresa selecionada
        if (filtroEmpresa !== '') {
          this.socios = filterAssociados.filter(socios => socios.cnpj_empresa === cnpjEmpresaPesquisada)

        // Sem filtro selecionado
        } else {
          this.socios = filterAssociados.filter(socios => socios.cnpj_empresa !== '')
        }
      }
    },
    // Função para Adicionar cifrão na frente do valor da mensalidade
    retornaMensalidade (item) {
      if ((item.valor_mensalidade === '') ||
          (item.valor_mensalidade === undefined)) {
        return 'R$ 0,00'
      } else {
        return `R$ ${item.valor_mensalidade}`
      }
    },
    // Função retorno se sócio está socioAtivo ou socioInativo
    ajusteStatusSocio (item) {
      return item.socio === true ? 'Ativo' : 'Inativo'
    },
    // Função para abrir o modal de edição do cadastro de associado
    abrirModalEdicaoSocio (item) {
      this.itemSelected = Object.assign({}, item)
      this.dialog = true
    },
    detalhesSocio (item) {
      this.$router.push({
        name: 'detalhesSocio',
        query: { W5kaXNhdWR: item, KSJMLwWasO: this.empresas }
      })
    },
    editarSocio (item) {
      if (!this.isAdmin) {
        this.itemSelected = Object.assign({}, item)
        this.valorMensalidadeEmpresaLogada = this.itemSelected.valor_mensalidade
        this.observacaoEmpresaLogada = this.itemSelected.observacao
        this.modalEditarValorObs = true
      } else {
        this.$router.push({
          name: 'editarSocio',
          query: { W5kaXNhdWR: item, KSJMLwWasO: this.empresas, page: 'socios' }
        })
      }
    },
    excluirSocio (item) {
      this.itemSelected = Object.assign({}, item)
      this.matriculaExclusao = this.itemSelected.matricula
      this.dialog = true
    },
    cancelar () {
      this.dialog = false
      this.modalEditarValorObs = false
    },
    excluirDependentesSocio () {
      this.dialogLoading = true
      let dependentesExcluidos = []

      firebase.firestore().collection('dependentes')
        .where('matricula_associado', '==', this.matriculaExclusao)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            dependentesExcluidos.push(doc.data())
          })
          dependentesExcluidos.forEach((el) => {
            firebase.firestore().collection('dependentes').doc(el.id).delete()
              .then(() => { })
              .catch((err) => {
                console.log('Error update document', err)
              })
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })

      this.db.collection('socios').doc(this.matriculaExclusao).delete()
        .then(() => {
          this.confirmacaoEnvio = true
          this.dialog = false
        })
        .catch((err) => {
          this.dialogLoading = false
          this.dialog = false
          console.log('Error update document', err)
        })

      // Exclui o associado do store
      this.socios = this.$store.state.socios

      if (this.socios !== undefined) {
        this.socios.forEach((el) => {
          if (el.matricula === this.matriculaExclusao) {
            this.socios.splice(this.socios.indexOf(el.matricula), 1)
          }
        })
      }
    },
    pararLoadingBotaoEnvio () {
      this.confirmacaoEnvio = false
    },
    atualizaDadosSocios () {
      // Loading do botão
      this.carregando = true
      this.erro = false

      if (this.itemSelected.cpf.length === 0) {
        // Valida se esta preenchido
      } else if (this.itemSelected.cpf.length !== 14) {
        this.temErroCPF = true
        this.carregando = false

        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.temErroCPF = false
        }, 2000)
        return
      }

      if (!this.$refs.form.validate()) {
        this.erro = true
        this.carregando = false

        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.erro = false
        }, 2000)
        return
      }

      this.db.collection('socios').doc(this.itemSelected.matricula).update({
        cpf: this.itemSelected.cpf,
        data_admissao: this.itemSelected.data_admissao,
        funcao: this.itemSelected.funcao,
        setor: this.itemSelected.setor,
        valor_mensalidade: this.itemSelected.valor_mensalidade,
        observacao: this.itemSelected.observacao
      })
        .then(() => {
          this.carregando = false
          this.sucesso = true
          this.$store.dispatch('setSocios', undefined)
          this.retornaAssociados()
          setTimeout(() => {
            this.modalEditarValorObs = false
            this.sucesso = false
          }, 2000)
        })
        .catch((err) => {
          this.carregando = false
          this.erro = true
          this.modalEditarValorObs = false
          console.log('Error update document', err)
        })
    },
    /** Função para search dados sobre a configuração */
    configuracoesSistema () {
      firebase.firestore().collection('configuracao').doc('socios').get()
        .then(doc => {
          this.$store.state.bloqueiaEdicaoMatricula = doc.data().bloqueiaEdicaoMatricula
          this.$store.state.cpfObrigatorio = doc.data().cpfObrigatorio
        })
        .catch((err) => {
          console.log('Error getting documents', err)
        })
    }
  }
}
</script>

<style  scoped>
.v-application .mr-2 {
  margin-right: 2px !important
}
.theme--light.v-data-table {
  margin-top: 5px;
}
.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 100px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 0px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.BarraEditar {
  height: 45px !important;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-text-field {
    margin-top: -8px;
}
.v-dialog > .v-card > .v-card__text {
    margin-top: 20px;
}

.tituloBarra {
  height: 45px !important;
  margin: auto
}

.botoes{
  padding: 0px 5px;
  margin: -21px 30px 0px 30px;
  justify-content: center;
}

.alertas {
  margin: -3% auto 4%;
  width: 90%;
}

.v-content {
  height: 100vh;

}

.filtroSelecaoEmpresa{
  position: absolute;
  top: 60px;
  width: 403px;
  margin-left: 17px;
  z-index: 4;
}

.search {
  padding-top: 14px;
}

.status {
  position: absolute;
  top: 50px;
  z-index: 4;
  right: 46%;
  color: grey
}

.statusCheckbox {
  margin-left: 20px;
}

.dark--text {
  margin-top: -30px
}

.BarraEditar {
  height: 45px !important;
}

.BarraAtencao {
  position: relative;
  left: 38%;
  top: -7px;
  font-size: 22px;
  font-weight: bold;
}

.textoModal{
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.textoModal b{
  color: #041c32;
}

.confirmaAcao{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px;
}

.botoesModal{
  padding: 0px 5px;
  justify-content: center;
}
h2 {
  color: grey;
  width: 500px;
}
</style>
