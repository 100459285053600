<template>
  <v-app>
    <Cabecalho v-if="$route.path !== '/login'" />
    <router-view />
    <Rodape v-if="$route.path !== '/login'" />
  </v-app>
</template>

<script>
import Cabecalho from '@/layouts/Cabecalho.vue'
import Rodape from '@/layouts/Rodape.vue'

export default {
  name: 'App',
  components: {
    Rodape,
    Cabecalho
  },
  created () {
    // Logica para Limpar o cache do navegador
    let keyFirebase = localStorage.getItem('reloadFirebase')

    if (!keyFirebase) {
      location.reload()
    }
    localStorage.setItem('reloadFirebase', true)
  }
}
</script>

<style scoped>
.v-content {
  position: absolute;
  top: 0;
  min-width: 100%;
}
</style>
