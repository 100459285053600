<template>
  <v-content id="tamanhoDiv">
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-account
      </v-icon>
      <v-card-text class="text">
        Perfil
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
      :style="{ height: `${tamanhoPag}px`}"
    >
      <!-- Modal Troca de Senha -->
      <v-dialog
        v-model="dialog"
        width="42%"
      >
        <v-card class="elevation-12">
          <!-- Barra de Título -->
          <v-toolbar
            class="BarraEditar"
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title class="BarraAtencao text-uppercase">
              atenção !!!
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <div class="textoModal">
              <p class="font-weight-bold">
                Será enviado um email para
                <b>{{ email }}</b>
                com o acesso para alteração da senha.
              </p>
              <p class="confirmaAcao">
                Você confirma essa ação ?
              </p>
            </div>

            <v-card-actions class="botoesModal">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="cancelar"
                >
                  Cancelar
                </v-btn>
              </v-flex>
              <v-btn
                :loading="dialogLoading"
                class="my-2"
                rounded
                color="indigo"
                dark
                @click="resetarSenha"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Modal Confirmação de Envio do E-mail -->
      <v-dialog
        v-model="confirmacaoEnvio"
        width="41%"
      >
        <v-card class="elevation-12">
          <!-- Barra de Título -->
          <v-toolbar
            v-if="retornoSucesso"
            class="BarraEditar"
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title class="BarraAtencao text-uppercase">
              Sucesso !!!
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            v-else-if="!retornoSucesso"
            class="BarraEditar"
            color="red darken-2"
            dark
            flat
          >
            <v-toolbar-title class="BarraAtencao text-uppercase">
              Atenção !!!
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text style="text-align: center">
            <div
              v-if="retornoSucesso"
              class="textoModal"
            >
              <p class="font-weight-bold">
                E-mail enviado com sucesso para
                <b>{{ email }}</b>
              </p>

              <v-card-actions class="botoesModal">
                <v-btn
                  class="my-2"
                  rounded
                  color="indigo"
                  dark
                  @click="ok"
                >
                  ok
                </v-btn>
              </v-card-actions>
            </div>

            <div
              v-else-if="!retornoSucesso"
              class="textoModal"
            >
              <p class="font-weight-bold">
                Ops! Algo deu errado...
                <br> Verifique se o e-mail <b>{{ email }} </b>
                é válido!
              </p>

              <v-card-actions class="botoesModal">
                <v-btn
                  class="my-2"
                  rounded
                  color="red darken-2"
                  dark
                  @click="ok"
                >
                  ok
                </v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Formulário de Editar Dados do Acesso do Usuário -->
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                editar
              </v-toolbar-title>
            </v-toolbar>

            <!-- Formulario  -->
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <!-- Nome completo e CNPJ -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <v-text-field
                      v-model="nome"
                      label="Nome Completo"
                      name="nome"
                      prepend-icon="mdi-hospital-building"
                      type="text"
                      :rules="nomeRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="cnpj"
                      label="CNPJ"
                      name="cnpj"
                      prepend-icon="mdi-newspaper-variant"
                      type="text"
                      disabled=""
                    />
                  </v-col>
                </v-row>

                <!-- CEP, Endereço, Numero e Complemento -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="cep"
                      label="CEP"
                      name="cep"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :rules="cepRules"
                      :mask="cepMask"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="endereco"
                      label="Endereço"
                      name="endereco"
                      prepend-icon="mdi-map-marker"
                      type="text"
                      :rules="enderecoRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="numero"
                      label="Nº"
                      name="numero"
                      prepend-icon="mdi-home-group"
                      type="text"
                      :rules="numeroRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="complemento"
                      label="Compl"
                      name="complemento"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <!-- Complemento, Bairro, Cidade e UF -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="bairro"
                      label="Bairro"
                      name="bairro"
                      prepend-icon="mdi-home-city"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="cidade"
                      label="Cidade"
                      name="cidade"
                      prepend-icon="mdi-map-marker"
                      type="text"
                      :rules="cidadeRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-select
                      v-model="uf"
                      label="UF"
                      name="uf"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :items="ufOpcoes"
                      :rules="ufRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="fone"
                      label="Fone"
                      name="fone"
                      prepend-icon="mdi-phone"
                      type="text"
                      :rules="foneRules"
                      :mask="foneMask"
                      required
                    />
                  </v-col>
                </v-row>

                <!-- Email e Senha -->
                <v-row>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-text-field
                      v-model="email"
                      label="E-mail"
                      name="email"
                      prepend-icon="mdi-email"
                      type="text"
                      disabled=""
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-if="returnStatus"
                v-model="hasReturn"
                :type="returnStatus"
                dismissible
              >
                {{ returnTextAlert }}
              </v-alert>
            </div>

            <v-card-actions class="botoes">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="success"
                  dark
                  @click="trocarSenha"
                >
                  Trocar Senha
                </v-btn>
              </v-flex>
              <v-btn
                class="my-2"
                rounded
                color="indigo"
                dark
                :loading="hasLoading"
                @click="atualizaDados"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      // Dados do Perfil
      uidCurrentUser: '',
      nome: '',
      nomeRules: [
        v => !!v || 'O nome é obrigatório'
      ],
      cnpj: '',
      cep: '',
      cepRules: [
        v => !!v || 'O CEP é obrigatório'
      ],
      cepMask: '#####-###',
      endereco: '',
      enderecoRules: [
        v => !!v || 'O endereço é obrigatório'
      ],
      numero: '',
      numeroRules: [
        v => !!v || 'O Numero é obrigatório'
      ],
      fone: '',
      foneRules: [
        v => !!v || 'O fone é obrigatório'
      ],
      foneMask: '(##) ####-####',
      complemento: '',
      bairro: '',
      cidade: '',
      cidadeRules: [
        v => !!v || 'A cidade é obrigatória'
      ],
      uf: '',
      ufOpcoes: [
        'RS',
        'SC',
        'PR'
      ],
      ufRules: [
        v => !!v || 'A UF é obrigatória'
      ],
      email: '',
      // Variaveis Globais
      valid: false,
      tamanhoPag: '',
      hasReturn: false,
      returnStatus: '',
      returnTextAlert: '',
      hasLoading: false,
      dialogLoading: false,
      dialog: false,
      retornoSucesso: false,
      confirmacaoEnvio: false
    }
  },
  async created () {
    await this.buscarCredenciais()
  },
  mounted () {
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.tamanhoPag = tamanhoPagina - 145
  },
  methods: {
    async buscarCredenciais () {
      let dados = {}
      const userLogado = firebase.auth().currentUser.uid

      await firebase.firestore().collection('empresas')
        .where('uid', '==', userLogado)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            dados = doc.data()
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })

      this.nome = dados.nome
      this.cnpj = dados.cnpj
      this.cep = dados.cep
      this.endereco = this.capitalize(dados.endereco)
      this.numero = dados.numero
      this.fone = dados.fone
      this.complemento = dados.complemento
      this.bairro = this.capitalize(dados.bairro)
      this.cidade = this.capitalize(dados.cidade)
      this.uf = dados.uf
      this.email = dados.email
    },
    atualizaDados () {
      this.hasLoading = true

      if (!this.$refs.form.validate()) {
        this.retornoAlerta('error') // Retorna Error escondendo o loading
        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.hasReturn = false
        }, 2000)
        return
      }

      const cnpj = this.cnpj.replace(/[^\d]/g, '')

      firebase.firestore().collection('empresas').doc(cnpj).update({
        nome: this.nome,
        cnpj: this.cnpj,
        cep: this.cep,
        endereco: this.capitalize(this.endereco),
        numero: this.numero,
        fone: this.fone,
        complemento: this.complemento,
        bairro: this.capitalize(this.bairro),
        cidade: this.capitalize(this.cidade),
        uf: this.uf
      })
        .then(() => {
          this.retornoAlerta('success') // Retorna sucesso escondendo o loading
          this.buscarCredenciais()
        })
        .catch((err) => {
          this.retornoAlerta('error') // Retorna sucesso escondendo o loading
          console.log('Error update document', err)
        })
    },
    capitalize (value) { // Método para padronizar dados no Banco de Dados
      // Recebe o valor inteiro
      let loweredText = value.toLowerCase()
      // Separa por virgula todas as palavras
      let words = loweredText.split(' ')

      // Faz um for em todas as palavras e coloca a primeira letra maior
      for (let a = 0; a < words.length; a++) {
        let w = words[a]
        let firstLetter = w[0]

        if (w.length > 2) {
          w = firstLetter.toUpperCase() + w.slice(1)
        } else {
          w = firstLetter + w.slice(1)
        }
        // retorna cada palavra com a primeira maior
        words[a] = w
      }

      // retorna a frase inteira
      return words.join(' ')
    },
    resetarSenha () {
      this.dialogLoading = true

      firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.dialog = false
          this.dialogLoading = false

          this.retornoSucesso = true
          this.confirmacaoEnvio = true
          // email enviado

          setTimeout(() => {
            this.confirmacaoEnvio = false
          }, 5000)
        })
        .catch(() => {
          this.retornoSucesso = false
          this.dialogLoading = false
          // erro
        })
    },
    cancelar () {
      this.dialog = false
    },
    ok () {
      this.confirmacaoEnvio = false
    },
    trocarSenha () {
      this.dialog = true
    },
    retornoAlerta (value) {
      if (value === 'success') {
        this.returnStatus = value
        this.returnTextAlert = 'Usuário alterado com sucesso'
      } else {
        this.returnStatus = value
        this.returnTextAlert = 'Confira as informações e tente novamente'
      }
      this.hasReturn = true
      this.hasLoading = false
    }
  }
}
</script>
<style  scoped>
.v-content {
  height: 100vh;
}

.container {
  padding-top: 4px;
  padding-bottom: 4px;

}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.BarraAtencao {
  height: 25px !important;
  margin-top: 2px !important;
  font-size: 22px;
  font-weight: bold;
}

.BarraEditar {
  height: 45px !important;
}

.botoes{
  padding: 0px 5px;
  margin: -9px 30px 0px 30px;
  justify-content: center;
}

.textoModal{
  font-size: 18px;
  margin-top: 20px;
}
.textoModal b{
  color: #041c32;
}

.confirmaAcao{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px;
}

.botoesModal{
  padding: 0px 5px;
  margin-bottom: -20px;
  justify-content: center;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-application .indigo {
    height: 45px !important;
}

.v-card__text, .v-card__title {
  padding: 18px 26px 5px 26px;
}

.v-toolbar__title {
  margin: auto
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.v-text-field {
    margin-top: -8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 51px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 15px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.alertas {
  margin: -6px auto;
  width: 90%;
}

.fill-height{
  align-items: baseline;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 3px;
}
</style>
