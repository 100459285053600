import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAdmin: false,
    nomeAcesso: '',
    empresas: undefined,
    socios: undefined,
    dependentes: undefined,
    verificaLogin: undefined,
    bloqueiaEdicaoMatricula: false,
    cpfObrigatorio: false,
    ativaScript: false,
    ativaMovimentacao: false,
    exibeAdm: false,
    ativaBackup: false,
    funcaoEmpresa: undefined
  },
  mutations: {
    'SET_ADM' (state, data) {
      state.isAdmin = data
    },
    'NOME_ACESSO' (state, data) {
      state.nomeAcesso = data
    },
    'EMPRESAS' (state, data) {
      state.empresas = data
    },
    'SOCIOS' (state, data) {
      state.socios = data
    },
    'DEPENDENTES' (state, data) {
      state.dependentes = data
    },
    'VERIFICA_LOGIN' (state, data) {
      state.verificaLogin = data
    },
    'BLOQUEIA_EDICAO_MATRICULA' (state, data) {
      state.bloqueiaEdicaoMatricula = data
    },
    'ATIVA_MOVIMENTACAO' (state, data) {
      state.ativaMovimentacao = data
    },
    'EXIBE_ADM' (state, data) {
      state.exibeAdm = data
    },
    'ATIVA_BACKUP' (state, data) {
      state.ativaBackup = data
    },
    'CPF_OBRIGATORIO' (state, data) {
      state.cpfObrigatorio = data
    },
    'FUNCAO_EMPRESA' (state, data) {
      state.funcaoEmpresa = data
    }
  },
  actions: {
    setAdm ({ commit }, data) {
      commit('SET_ADM', data)
    },
    setNomeAcesso ({ commit }, data) {
      commit('NOME_ACESSO', data)
    },
    setEmpresas ({ commit }, data) {
      commit('EMPRESAS', data)
    },
    setSocios ({ commit }, data) {
      commit('SOCIOS', data)
    },
    setDependentes ({ commit }, data) {
      commit('DEPENDENTES', data)
    },
    setverificaLogin ({ commit }, data) {
      commit('VERIFICA_LOGIN', data)
    },
    setBloqueiaEdicaoMatricula ({ commit }, data) {
      commit('BLOQUEIA_EDICAO_MATRICULA', data)
    },
    setCpfObrigatorio ({ commit }, data) {
      commit('CPF_OBRIGATORIO', data)
    },
    setAtivaMovimentacao ({ commit }, data) {
      commit('ATIVA_MOVIMENTACAO', data)
    },
    setExibeAdmo ({ commit }, data) {
      commit('EXIBE_ADM', data)
    },
    setAtivaBackup ({ commit }, data) {
      commit('ATIVA_BACKUP', data)
    },
    setFuncaoEmpresa ({ commit }, data) {
      commit('FUNCAO_EMPRESA', data)
    }
  },
  modules: {
  }
})
