<template>
  <v-content id="tamanhoDiv">
    <v-card class="header">
      <v-icon class="icon">
        mdi-hospital-building
      </v-icon>
      <v-card-text class="text">
        Empresas
      </v-card-text>
    </v-card>

    <!-- Modal Pergunta se quer excluir associado -->
    <v-dialog
      v-model="dialogDelete"
      width="42%"
    >
      <v-card class="elevation-12">
        <!-- Barra de Título -->
        <v-toolbar
          class="BarraEditar"
          color="indigo"
          dark
          flat
        >
          <v-toolbar-title class="BarraAtencao text-uppercase">
            atenção !!!
          </v-toolbar-title>
          <button
            class="btnFechar"
            @click="cancelar"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </v-toolbar>

        <v-card-text>
          <div class="textoModal">
            <p class="font-weight-bold">
              Nessa ação a Empresa <b>{{ itemSelected.nome }}</b>
              será excluída definitivamente!!!
            </p>
            <p class="confirmaAcao">
              Você confirma essa <b>EXCLUSÃO?</b>
            </p>
          </div>

          <!-- Alertas de Sucesso e Erro -->
          <div class="alertas">
            <v-alert
              v-if="retornoStatus"
              v-model="hasReturn"
              :type="retornoStatus"
              dismissible
            >
              {{ retornaTextoAlerta }}
            </v-alert>
          </div>

          <v-card-actions class="botoesModal">
            <v-flex>
              <v-btn
                class="my-2"
                rounded
                color="error"
                dark
                @click="cancelar"
              >
                Cancelar
              </v-btn>
            </v-flex>
            <v-btn
              :loading="dialogLoading"
              class="my-2"
              rounded
              color="indigo"
              dark
              @click="excluirEmpresa"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :fixed-header="fixedHeader"
      :height="height"
      :items="empresas"
      :search="search"
      sort-by="nome"
      class="elevation-1"
      :loading="loading"
      loading-text="Carregando Dados... Por Favor Aguarde."
    >
      <!-- Tabela com instituições -->
      <template #top>
        <v-toolbar
          flat
          color="white"
        >
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
          <v-btn
            class="mx-2"
            fab
            dark
            color="#1976d2"
            @click="cadastrar"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Modal de Editar da Empresa -->
        <v-dialog
          v-model="dialog"
          width="70%"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              class="BarraEditar"
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                editar
              </v-toolbar-title>
            </v-toolbar>

            <!-- Formulario  -->
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <!-- Nome completo e CNPJ -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <v-text-field
                      v-model="itemSelected.nome"
                      label="Nome Completo"
                      name="nome"
                      prepend-icon="mdi-hospital-building"
                      type="text"
                      :rules="nomeRules"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="itemSelected.cnpj"
                      v-mask="cnpjMascara"
                      label="CNPJ"
                      name="cnpj"
                      prepend-icon="mdi-newspaper-variant"
                      type="text"
                      disabled
                    />
                  </v-col>
                </v-row>

                <!-- CEP, Endereço, Numero e Complemento -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="itemSelected.cep"
                      v-mask="cepMascara"
                      label="CEP"
                      name="cep"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :rules="cepRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="itemSelected.endereco"
                      label="Endereço"
                      name="endereco"
                      prepend-icon="mdi-map-marker"
                      type="text"
                      :rules="enderecoRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="itemSelected.numero"
                      label="Nº"
                      name="numero"
                      prepend-icon="mdi-home-group"
                      type="text"
                      :rules="numeroRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="itemSelected.complemento"
                      label="Compl"
                      name="complemento"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <!-- Complemento, Bairro, Cidade e UF -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="itemSelected.bairro"
                      label="Bairro"
                      name="bairro"
                      prepend-icon="mdi-home-city"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="itemSelected.cidade"
                      label="Cidade"
                      name="cidade"
                      prepend-icon="mdi-map-marker"
                      type="text"
                      :rules="cidadeRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-select
                      v-model="itemSelected.uf"
                      label="UF"
                      name="uf"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :items="ufOpcoes"
                      :rules="upRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="itemSelected.fone"
                      v-mask="foneMascara"
                      label="Fone"
                      name="fone"
                      prepend-icon="mdi-phone"
                      type="text"
                      :rules="foneRegras"
                      required
                    />
                  </v-col>
                </v-row>

                <!-- Matriz e Filial -->
                <v-row>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-select
                      v-model="itemSelected.tipo"
                      :items="tipoOpcoes"
                      prepend-icon="mdi-clipboard-list-outline"
                      label="Tipo da Empresa"
                      :rules="tipoRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    v-if="itemSelected.tipo === 'Filial'"
                    cols="12"
                    sm=""
                  >
                    <v-select
                      v-model="itemSelected.nome_matriz"
                      :items="pesquisaNomeEmpresas"
                      :rules="pesquisaNomeEmpresasRules"
                      label="Empresa Matriz"
                      clearable
                      @change="converteCNPJ"
                    />
                  </v-col>
                </v-row>

                <!-- Email, Permissão e Senha -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="5"
                  >
                    <v-text-field
                      v-model="itemSelected.email"
                      label="E-mail"
                      name="email"
                      prepend-icon="mdi-email"
                      type="text"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="itemSelected.acesso"
                      :items="acessoOpcoes"
                      prepend-icon="mdi-account-card-details"
                      label="Permissão"
                      :rules="acessoRegras"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-text-field
                      label="Senha"
                      name="senha"
                      prepend-icon="mdi-password"
                      type="text"
                      :value="decodificaSenha"
                      disabled
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-if="retornoStatus"
                v-model="hasReturn"
                :type="retornoStatus"
                dismissible
              >
                {{ retornaTextoAlerta }}
              </v-alert>
            </div>

            <v-card-actions class="botoes">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="cancelar"
                >
                  Cancelar
                </v-btn>
              </v-flex>
              <v-btn
                class="my-2"
                rounded
                color="indigo"
                dark
                :loading="estaCarregando"
                @click="editarEmpresa"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!-- Botão de Ações lateral tabela -->
      <template #item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="openDialog(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-content>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Empresas',
  components: { },
  props: { },
  data () {
    return {
      valid: false,
      fixedHeader: true,
      height: 365,
      pagination: true,
      search: '',
      empresas: undefined,
      isLoading: false,
      selectSwitch: undefined,
      db: firebase.firestore(),
      pesquisaNomeEmpresas: [],
      pesquisaNomeEmpresasRules: [
        v => !!v || 'A Empresa Matriz é obrigatória'
      ],
      headers: [
        {
          text: 'Nome',
          align: 'left',
          value: 'nome'
        },
        { text: 'Endereço', value: 'endereco' },
        { text: 'Cidade', value: 'cidade' },
        { text: 'Fone', value: 'fone' },
        { text: 'Email', value: 'email' },
        { text: 'Ações', value: 'action', sortable: false }
      ],
      itemSelected: {
        nome: '',
        cnpj: '',
        cep: '',
        endereco: '',
        numero: '',
        fone: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
        acesso: '',
        tipo: '',
        cnpj_matriz: '',
        nome_matriz: ''
      },
      dialog: false,
      dialogDelete: false,

      // Campos para Modal
      nome: '',
      nomeRules: [
        v => !!v || 'O nome é obrigatório'
      ],
      cnpj: '',
      cnpjMascara: '##.###.###/####-##',
      cep: '',
      cepRegras: [
        v => !!v || 'O CEP é obrigatório',
        v => v.length === 9 || 'Insira um CEP válido'
      ],
      cepMascara: '#####-###',
      endereco: '',
      enderecoRegras: [
        v => !!v || 'O endereço é obrigatório'
      ],
      numero: '',
      numeroRegras: [
        v => !!v || 'O Numero é obrigatório'
      ],
      fone: '',
      foneRegras: [
        v => !!v || 'O fone é obrigatório',
        v => v.length === 14 || 'Insira um Telefone válido'
      ],
      foneMascara: '(##) ####-####',
      complemento: '',
      bairro: '',
      cidade: '',
      cidadeRegras: [
        v => !!v || 'A cidade é obrigatória'
      ],
      uf: '',
      ufOpcoes: [
        'RS',
        'SC',
        'PR'
      ],
      upRegras: [
        v => !!v || 'A UF é obrigatória'
      ],
      acesso: null,
      acessoOpcoes: [
        'Usuário',
        'Administrador'
      ],
      acessoRegras: [
        v => !!v || 'A permissão é obrigatória'
      ],
      tipo: null,
      tipoOpcoes: [
        'Matriz',
        'Filial'
      ],
      tipoRegras: [
        v => !!v || 'O tipo da Empresa é obrigatória'
      ],
      estaCarregando: false,
      hasReturn: false,
      retornoStatus: '',
      excluidoComSucesso: false,
      retornaTextoAlerta: '',
      loading: true,
      dialogLoading: false,
      exibeAdm: false
    }
  },
  computed: {
    decodificaSenha () {
      if (this.itemSelected.senha === undefined) {
        return this.itemSelected.senha
      } else {
        // Recebe a senha do Banco e decodifica acesso
        const decodificaPrimeiroNivel = atob(this.itemSelected.senha)

        // Decodifica o segundo nível
        const senhaRetornadaHash = decodificaPrimeiroNivel.slice(9, -17)

        // Retorna senha decodificada
        return atob(senhaRetornadaHash)
      }
    }
  },
  async created () {
    await this.validaConfiguracao ()
    this.retornaEmpresas()
  },
  mounted () {
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.height = tamanhoPagina - 269
  },
  methods: {
    async validaConfiguracao () {
      await firebase.firestore().collection('configuracao').doc('geral').get()
        .then(doc => {
          this.exibeAdm = doc.data().exibeAdm
          this.$store.dispatch('setExibeAdm', this.exibeAdm)
        })
        .catch((err) => {
          console.log('Error getting documents2', err)
        })
    },
    retornaEmpresas () {
      // Valida se tem no Store os dados das instituições
      this.empresas = this.$store.state.empresas

      // Valida se tem instituições no Store senão faz consulta no BD
      if (this.empresas === undefined) {
        this.empresas = []
        this.db.collection('empresas').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if(this.exibeAdm) {
                this.empresas.push(doc.data())
              } else {
                if(doc.data().acesso === 'Usuário'){
                  this.empresas.push(doc.data())
                }
              }
              this.$store.dispatch('setEmpresas', this.empresas)
            })
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.empresas = this.$store.state.empresas
      }

      // Busca o nome das instituições para o filtro e ordena por
      setTimeout(() => {
        this.empresas.forEach((el) => {
          this.pesquisaNomeEmpresas.push(el.nome)
        })
        this.pesquisaNomeEmpresas.sort()
      }, 2000)

      // Loading do carregamento da tabela
      this.loading = false

      // Desaparece o alerta de sucesso e Modal de Edição após 2 segundos
      setTimeout(() => {
        this.hasReturn = false
        this.retornoStatus = ''
        this.dialog = false
      }, 2000)
    },
    converteCNPJ () {
      this.empresas.forEach((el) => {
        if (el.nome === this.itemSelected.nome_matriz) {
          this.itemSelected.cnpj_matriz = el.cnpj
        }
      })
    },
    /** Função para redirecionar para Cadastrar Empresas */
    cadastrar () {
      this.$router.push('/cadastrar-empresa')
    },
    /** Função para Atualizar dados da Empresa */
    editarEmpresa () {
      // Loading do botão
      this.estaCarregando = true

      if (!this.$refs.form.validate()) {
        this.returnAlertEdit('error') // Retorna Error escondendo o loading
        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.hasReturn = false
          this.estaCarregando = false
        }, 2000)
        return
      }

      if (this.itemSelected.cnpj_matriz === undefined) {
        this.itemSelected.cnpj_matriz = ''
      }

      if (this.itemSelected.tipo === 'Matriz') {
        this.itemSelected.cnpj_matriz = ''
      }

      const cnpj = this.itemSelected.cnpj.replace(/[^\d]/g, '')

      this.db.collection('empresas').doc(cnpj).update({
        nome: this.itemSelected.nome,
        cnpj: this.itemSelected.cnpj,
        cep: this.itemSelected.cep,
        endereco: this.capitalize(this.itemSelected.endereco),
        numero: this.itemSelected.numero,
        fone: this.itemSelected.fone,
        complemento: this.itemSelected.complemento,
        bairro: this.capitalize(this.itemSelected.bairro),
        cidade: this.capitalize(this.itemSelected.cidade),
        uf: this.itemSelected.uf,
        acesso: this.itemSelected.acesso,
        tipo: this.itemSelected.tipo,
        cnpj_matriz: this.itemSelected.cnpj_matriz
      })
        .then(() => {
          this.returnAlertEdit('success') // Retorna sucesso escondendo o loading
          this.$store.dispatch('setEmpresas', undefined)
          this.retornaEmpresas()
        })
        .catch((err) => {
          this.returnAlertEdit('error') // Retorna sucesso escondendo o loading
          console.log('Error update document', err)
        })
    },
    /** Função de retorno do Alerta Editar */
    returnAlertEdit (value) {
      if (value === 'success') {
        this.retornoStatus = value
        this.retornaTextoAlerta = 'Empresa alterada com sucesso'
      } else {
        this.retornoStatus = value
        this.retornaTextoAlerta = 'Confira as informações e tente novamente'
      }
      this.hasReturn = true
      this.estaCarregando = false
    },
    /** Função de retorno do Alerta */
    returnAlertDelete (value) {
      if (value === 'success') {
        this.retornoStatus = value
        this.retornaTextoAlerta = 'Empresa deletada com sucesso'
      } else {
        this.retornoStatus = value
        this.retornaTextoAlerta = 'Erro no excluir a empresa e tente novamente'
      }
      this.hasReturn = true
      this.estaCarregando = false
    },
    /** Função para padronizar dados no Banco de Dados */
    capitalize (value) {
      // Recebe o valor inteiro
      let loweredText = value.toLowerCase()
      // Separa por virgula todas as palavras
      let words = loweredText.split(' ')

      // Faz um for em todas as palavras e coloca a primeira letra maior
      for (let a = 0; a < words.length; a++) {
        let w = words[a]
        let firstLetter = w[0]

        if (w === 'dos' || w === 'das') {
          w = firstLetter + w.slice(1)
        } else if (w.length > 2 || w.replace('.', '').length === 1) {
          w = firstLetter.toUpperCase() + w.slice(1)
        } else {
          w = firstLetter + w.slice(1)
        }
        // retorna cada palavra com a primeira maior
        words[a] = w
      }

      // retorna a frase inteira
      return words.join(' ')
    },
    /** Função para Excluir empresa do banco de dados */
    deleteItem (item) {
      this.itemSelected = Object.assign({}, item)
      this.dialogDelete = true
    },
    /** Função para abrir Modal passando o Objeto */
    openDialog (item) {
      this.itemSelected = Object.assign({}, item)

      this.empresas.forEach((el) => {
        if (el.cnpj === this.itemSelected.cnpj_matriz) {
          this.itemSelected.nome_matriz = el.nome
        }
      })

      this.dialog = true
    },
    cancelar () {
      this.dialog = false
      this.dialogDelete = false
    },
    /** Função que Exclui a empresa do banco */
    excluirEmpresa () {
      this.dialogLoading = true
      const cnpj = this.itemSelected.cnpj.replace(/[^\d]/g, '')

      this.db.collection('empresas').doc(cnpj).delete()
        .then(() => {
          this.returnAlertDelete('success') // Retorna sucesso escondendo o loading
          this.dialogLoading = false
          setTimeout(() => {
            this.dialogDelete = false
          }, 2000)
          this.$store.dispatch('setEmpresas', undefined)
          this.retornaEmpresas()
        })
        .catch((err) => {
          this.returnAlertDelete('error') // Retorna sucesso escondendo o loading
          this.dialogLoading = false
          this.dialogDelete = false
          console.log('Error update document', err)
        })
    }
  }
}
</script>

<style  scoped>
.v-application .mr-2 {
  margin-right: 2px !important
}
.theme--light.v-data-table {
  margin-top: 5px;
}
.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 75px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 25px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.BarraEditar {
  height: 45px !important;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-text-field {
    margin-top: -8px;
}
.v-dialog > .v-card > .v-card__text {
    margin-top: 20px;
}

.tituloBarra {
  height: 45px !important;
  margin: auto
}

.botoes{
  padding: 0px 5px 10px 0px;
  margin: -21px 30px 0px 30px;
  justify-content: center;
}

.alertas {
  margin: -3% auto 4%;
  width: 90%;
}

.v-content {
  height: 100vh;

}

.switchAssociado {
  margin-left: 15px;
  margin-top: -1px;
}

.BarraAtencao {
  position: relative;
  left: 38%;
  top: -7px;
  font-size: 22px;
  font-weight: bold;
}

.textoModal{
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.textoModal b{
  color: #041c32;
}

.confirmaAcao{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px;
}

.botoesModal{
  padding: 20px 5px 0px 0px ;
  margin-top: -20px;
  margin-bottom: -10px;
  justify-content: center;
}

.btnFechar {
  position: absolute;
  right: 10px;
  top: 10px

}

</style>
