<template>
  <v-app id="inspire">
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <!-- Modal Troca de Senha -->
            <v-dialog
              v-model="changePassword"
              width="42%"
            >
              <v-card class="elevation-12">
                <!-- Barra de Título -->
                <v-toolbar
                  class="BarraEditar"
                  color="indigo"
                  dark
                  flat
                >
                  <v-toolbar-title class="BarraAtencao text-uppercase">
                    atenção !!!
                  </v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <div class="textoModal">
                    <p class="font-weight-bold">
                      Foi enviado um email para
                      <b>{{ email }}</b>
                      para alteração da senha inicial.
                    </p>
                    <p class="font-weight-bold">
                      Após a Alteração,
                      faça o Login Novamente!!!
                    </p>
                  </div>

                  <v-card-actions class="botoesModal">
                    <v-btn
                      class="my-2"
                      rounded
                      color="indigo"
                      dark
                      @click="ok"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
            <img
              class="logo"
              alt="Vue logo"
              src="../assets/LogoInicio.svg"
            >

            <v-card class="elevation-12">
              <v-toolbar
                color="indigo"
                dark
                flat
              >
                <v-toolbar-title>Sistema de Controle de Sócios</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    label="Email"
                    name="login"
                    prepend-icon="mdi-account"
                    type="email"
                    :rules="emailRules"
                    required
                  />

                  <v-text-field
                    v-model="password"
                    label="Senha"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    :rules="passwordRules"
                    required
                    @keypress.enter="login"
                  />
                </v-form>
                <span @click="dialog = true">Esqueceu sua senha?</span>
              </v-card-text>

              <v-alert
                v-show="hasError"
                dense
                type="error"
              >
                Email ou senha inválidos
              </v-alert>

              <v-card-actions>
                <v-btn
                  outlined
                  block
                  :loading="hasLoading"
                  color="indigo"
                  @click="login"
                >
                  Entrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Esqueceu sua senha?
        </v-card-title>

        <v-card-text>
          Digite seu email abaixo para receber um email com as instruções para criar uma nova senha para a sua conta
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-text-field
            v-model="email"
            label="Email"
            name="login"
            prepend-icon="mdi-account"
            type="email"
            :rules="emailRules"
            required
            @keypress="resetFormDialog"
          />
          <v-btn
            :loading="dialogLoading"
            class="mx-2"
            fab
            dark
            small
            :color="dialogColor"
            @click="resetPassword"
          >
            <v-icon dark>
              mdi-{{ dialogIcon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import firebase from 'firebase'
export default {
  data () {
    return {
      drawer: null,
      email: '',
      emailRules: [
        v => !!v || 'O email é obrigatório',
        v => /.+@.+\..+/.test(v) || 'Digite um e-mail válido.'
      ],
      password: '',
      passwordRules: [
        v => !!v || 'A senha é obrigatória',
        v => (v && v.length >= 6) || 'A senha deve conter no mínimo 6 caracteres'
      ],
      hasLoading: false,
      hasError: false,
      dialog: false,
      dialogIcon: 'send',
      dialogLoading: false,
      dialogColor: 'indigo',
      hashPassword: '',
      userId: '',
      db: firebase.firestore(),
      cnpj: '',
      hashPassBd: '',
      changePassword: false,
      isLogin: undefined,
      auxiliar: 0
    }
  },
  created () {
    this.persistenceSession()

    // Logica para Limpar o cache do navegador
    let keyFirebase = localStorage.getItem('reloadFirebase')

    if (!keyFirebase) {
      location.reload()
    }
    localStorage.setItem('reloadFirebase', true)
  },
  methods: {
    login () {
      this.hasLoading = true
      this.hasError = false

      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          (user) => {
            this.verificaLogin()
            setTimeout(() => {
              if (this.password === '123456' && this.isLogin === true) {
                this.changePassword = true
                firebase.auth().sendPasswordResetEmail(this.email)
                  .then(() => {
                    this.hasLoading = false

                    setTimeout(() => {
                      this.changePassword = false
                    }, 10000)
                  })
                  .catch(() => {
                    this.hasLoading = false
                    this.changePassword = true
                  // erro
                  })
              } else {
                firebase.firestore().collection('empresas').doc(user.user.uid).get()
                  .then(doc => {
                    const isAdmin = doc.data().acesso === 'Administrador'
                    this.$store.dispatch('setAdm', isAdmin)
                    this.$store.dispatch('setNomeAcesso', doc.data().nome)
                    this.userId = user.user.uid
                    this.cnpj = doc.data().cnpj
                    this.salveCredentials()
                  })
                  .catch((err) => {
                    console.log('Error getting documents', err)
                  })
                this.hasLoading = false
                localStorage.removeItem('reloadFirebase')
                this.$router.replace('/home')
              }
            }, 1000)
          },
          // eslint-disable-next-line
          (err) => {
            this.hasError = true
            this.hasLoading = false
          }
        )
      // }
    },
    resetPassword () {
      this.dialogLoading = true
      firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.dialogLoading = false
          this.dialogColor = 'green'
          this.dialogIcon = 'check'
          // email enviado
        })
        .catch(() => {
          this.dialogColor = 'red'
          this.dialogLoading = false
          this.dialogIcon = 'close'
          // erro
        })
    },
    resetFormDialog () {
      this.dialogColor = 'primary'
      this.dialogLoading = false
      this.dialogIcon = 'send'
    },
    salveCredentials () {
      // Recebe a senha normal e criptografa
      this.hashPassword = btoa(this.password)

      // criptografa o cnpj do usuário
      this.hashcnpj = btoa(this.cnpj)

      // Divide a criptografia do cnpj em  3 partes
      let part1 = this.hashcnpj.substr(0, 8)
      let part2 = this.hashcnpj.substr(8, 8)
      let part3 = this.hashcnpj.substr(-8)

      // Unifica os hashs da senha e cnpj fatiado
      this.hashPassBd = btoa('w' + part3 + this.hashPassword + part2 + 'R' + part1)

      // Guarda no banco senha fatiada e criptografada
      this.db.collection('empresas').doc(this.userId).update({
        senha: this.hashPassBd
      })
    },
    ok () {
      this.changePassword = false
    },
    persistenceSession () {
      this.$store.dispatch('setSocios', undefined)
      this.$store.dispatch('setNomeAcesso', undefined)
      this.$store.dispatch('setEmpresas', undefined)
      this.$store.dispatch('setAdm', undefined)
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        })
        .catch((err) => {
          console.log('Error getting documents', err)
        })
    },
    verificaLogin () {
      firebase.firestore().collection('configuracao').doc('geral').get()
        .then(doc => {
          this.isLogin = doc.data().verificaLogin
        })
        .catch((err) => {
          console.log('Error getting documents', err)
        })
    }
  }
}
</script>

<style scoped>
.v-card__actions{
  padding: 8px 15%;
}

.v-alert{
  width: 90%;
  margin: auto;
}

.v-toolbar__title {
  margin: auto
}

.v-card__actions .v-btn.v-btn {
  margin-top: 30px;
  margin-bottom: 19px;
}

.BarraEditar {
  height: 45px !important;
}

.BarraAtencao {
  height: 25px !important;
  margin-top: 2px !important;
  font-size: 22px;
  font-weight: bold;
}

.textoModal{
  font-size: 18px;
  margin-top: 20px;
  line-height: 30pt;
  text-align: center;
}

.textoModal b{
  color: #041c32;
}

.confirmaAcao{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 0px;
}

.botoesModal{
  padding: 0px 5px;
  justify-content: center;
}

.logo {
  width: 400px;
  display: block; /* Isso é necessário se .logo é uma img */
  margin: -16% auto 5% auto;
}

</style>
