<template>
  <v-content>
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-calendar-sync-outline
      </v-icon>
      <v-card-text class="text">
        Script Ajuste
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                Scripts de Ajuste
              </v-toolbar-title>
            </v-toolbar>

            <v-card-actions class="botoes">
              <v-btn
                class="my-2"
                rounded
                color="success"
                @click="getEmpresas"
              >
                getAssociados
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
// import firebase from 'firebase'
// import { mdiConsoleLine } from '@mdi/js'
import firebase from 'firebase'
import moment from 'moment'
// const associados = require('../data/ajuste.json')
const socios = require('../backup/2023-01-associados.json')

export default {
  name: 'ScriptInsertion',
  props: {},
  data: () => ({
    associados: [],
    empresas: [],
    monthEntries: [],
    funcoesEmpresa: []
  }),
  computed: { },
  created () {
    // this.getAssociados()
  },
  methods: {
    getDependentes () {
      // let associado = this.$store.state.associados
      let countAssociados = 0
      const associados = require('../backup/2023-02-associadosbkp.json')
      // associado.forEach((el) => {
      associados[0].data.forEach((doc) => {
        if (doc.matricula > 7084) {
          countAssociados += 1
          firebase.firestore().collection('associados').doc(doc.matricula).update({
            bairro: doc.bairro ?? '',
            carteira_profissional: doc.carteira_profissional ?? '',
            cep: doc.cep ?? '',
            cidade: doc.cidade ?? '',
            matricula: doc.matricula ?? '',
            cnpj_instituicao_principal: doc.cnpj_instituicao_principal ?? '',
            cnpj_instituicao_secundaria: doc.cnpj_instituicao_secundaria ?? '',
            complemento: doc.complemento ?? '',
            cpf: doc.cpf ?? '',
            data_admissao_instituicao_principal: doc.data_admissao_instituicao_principal ?? '',
            data_admissao_instituicao_secundaria: doc.data_admissao_instituicao_secundaria ?? '',
            data_de_associacao: doc.data_de_associacao ?? '',
            data_nascimento: doc.data_nascimento ?? '',
            data_saida_sindicato: doc.data_saida_sindicato ?? '',
            dirigente: doc.dirigente ?? '',
            email: doc.email ?? '',
            endereco: doc.endereco ?? '',
            estado_civil: doc.estado_civil ?? '',
            fone_celular: doc.fone_celular ?? '',
            fone_residencial: doc.fone_residencial ?? '',
            funcao_instituicao_principal: doc.funcao_instituicao_principal ?? '',
            funcao_instituicao_secundaria: doc.funcao_instituicao_secundaria ?? '',
            nome: doc.nome ?? '',
            numero: doc.numero ?? '',
            observacao_instituicao_principal: doc.observacao_instituicao_principal ?? '',
            observacao_instituicao_secundaria: doc.observacao_instituicao_secundaria ?? '',
            rg: doc.rg ?? '',
            setor_instituicao_principal: doc.setor_instituicao_principal ?? '',
            setor_instituicao_secundaria: doc.setor_instituicao_secundaria ?? '',
            socio: doc.socio ?? '',
            uf: doc.uf ?? '',
            valor_mensalidade_instituicao_principal: doc.valor_mensalidade_instituicao_principal ?? 0,
            valor_mensalidade_instituicao_secundaria: doc.valor_mensalidade_instituicao_secundaria ?? 0,
            ficha: doc.ficha ?? '',
            movimentacao: doc.movimentacao ?? '',
            data_demissao_instituicao_principal: doc.data_demissao_instituicao_principal ?? '',
            data_demissao_instituicao_secundaria: doc.data_demissao_instituicao_secundaria ?? ''
          })
        }
      })
      // })

      // let countCpf = 0
      // if (this.fichaStorage === undefined) {
      // associados[0].data.forEach((doc) => {
      //   if (doc.nome === undefined) {
      //     console.log(doc.nome)
      //   }
      // })
      console.log(countAssociados)
      //   console.log(countCpf)
      // } else {
      //   console.log(this.fichaStorage)
      // }
      // firebase.firestore().collection('associados')
      //   // .where('data_admissao_institicao_principal', '!=', '')
      //   .where('socio', '==', true)
      //   .get()
      //   .then(function (querySnapshot) {
      //     querySnapshot.forEach(function (doc) {
      //       if (doc.data().data_admissao_institicao_principal !== '') {
      //         console.log(doc.data())
      //       }
      //     })
      //   })
      //   if (el.cpf === '623.342.550-68') {
      //     firebase.storage()
      //       .ref(`Fichas Associados/${el.matricula}.jpg`)
      //       .getDownloadURL().then((url) => {

      //       })
      //     // if (el.cpf === doc.cpf && el.matricula !== doc.matricula) {
      //     // console.log(`${el.matricula}, ${el.cpf}, ${el.nome}, ${this.onCNPJtoName(el.cnpj_instituicao_principal)}`)
      //   }
      // }

      // if (el.endereco !== '') {
      // firebase.firestore().collection('associados').doc(el.matricula).update({
      //   endereco: el.endereco,
      //   cidade: el.cidade
      // }, { merge: true })
      //   console.log(el.matricula + ' - ' + el.endereco)
      //   console.log(el.matricula + ' - ' + el.cidade)
      // }
      // let associadosTemp = []
      // firebase.firestore().collection('associados')
      //   .where('funcao_instituicao_principal', '==', '')
      //   // .where('socio', '==', true)
      //   // .where('movimentacao.' +'08-21'.valor_mens_mov_instituicao_principal', '>=', '0')
      //   .get()
      //   .then((snapshot) => {
      //     snapshot.forEach(function (el) {
      //       associadosTemp.push(el.data())
      //       console.log('el.id :: ' + (el.data().matricula))
      //     })
      //     this.associados = associadosTemp
      //   })
      //   .catch((err) => {
      //     console.log('Error getting docs', err)
      //   })
    },
    async getAssociados () {
      // Valida se tem no Store os dados dos associados
      this.associados = this.$store.state.socios
      this.empresas = this.$store.state.empresas
      if (this.associados === undefined) {
        this.associados = []
        await firebase.firestore().collection('socios').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.associados.push(doc.data())
              this.$store.dispatch('setSocios', this.associados)
            })
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
        console.log('db')
      } else {
        this.associados = this.$store.state.socios
        console.log('store')
      }
      let count = 0
      // let sociosA = ['6141','6793','7112','6353','7269','6920','7362','6354','6712','6914','6785','7267','6921','6923','6321','6912','6792']

      // this.empresas.forEach(doc => {
      // const cnpj = doc.cnpj.replace(/[^\d]/g, '')
      // firebase.firestore().collection('empresas').doc(cnpj).update({
      //   ['mensalidade.2024-11']: firebase.firestore.FieldValue.delete()
      // })
      // })


      this.associados.forEach(doc => {
        // if (doc.cnpj_empresa === '91.945.204/0001-50' && doc.socio && doc.funcao === 'Aux. de Enfermagem') {
        // sociosA.forEach(el => {
        if(doc.socio
          && doc.valor_mensalidade === '0,00'
          // && doc.cnpj_empresa ==='97.503.676/0001-30'
          && doc.observacao === ''
          // && (doc.funcao === 'Téc. de Enfermagem'
          // || doc.funcao === 'Aux. de Farmácia')
        ) {

          count += 1

          // console.log(doc.mensalidade['2024-11'])
          // console.log(doc.cnpj_empresa)
          console.log(doc.matricula)
          console.log(doc.funcao)
          // console.log(doc.valor_mensalidade)
          // console.log(doc.observacao)

          // this.excluirCampo(doc.matricula)

          // firebase.firestore().collection('socios').doc(doc.matricula).update({
          //   'valor_mensalidade': '36,30'
          // })

          this.$store.state.socios = undefined
        }
        // })



        // }
      })

      console.log(count)


      console.log('finalizou')
    },
    async getEmpresas () {
      // Valida se tem no Store os dados dos associados
      this.empresas = this.$store.state.empresas
      if (this.empresas === undefined) {
        this.empresas = []
        await firebase.firestore().collection('empresas').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.empresas.push(doc.data())
              this.$store.dispatch('setEmpresas', this.empresas)
            })
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
        console.log('db')
      } else {
        this.empresas = this.$store.state.empresas
        console.log('store')
      }
      let count = 0

      this.empresas.forEach(doc => {

        if(
          // doc.cnpj === '90.619.818/0001-80'
          doc.cnpj !== '92.021.062/0009-55'
          && doc.cnpj !== '92.021.062/0001-06'
          && doc.acesso === 'Usuário'
        ) {
          console.log(doc.cnpj)

          count += 1
          const cnpj = doc.cnpj.replace(/[^\d]/g, '')
          // firebase.firestore().collection('empresas').doc(cnpj).update({
          //   ['mensalidade.2024-11']: firebase.firestore.FieldValue.delete(),
          // })

        }
      })


      this.$store.state.empresas = undefined


      console.log(count)

      console.log('finalizou')
    },
    convertDate (dateString) {
      const [day, month, year] = dateString.split('/')
      return `${year}/${month}/${day}`
    },
    // Função para excluir um campo específico em um documento
    excluirCampo(matricula) {
      // Referência ao documento
      firebase.firestore().collection('socios').doc(matricula).update({
        ['mensalidade.2024-11']: firebase.firestore.FieldValue.delete()
      })
        .then(() => {
          console.log('Campo excluído com sucesso!')
        })
        .catch((error) => {
          console.error('Erro ao excluir o campo: ', error)
        })
    },
    compareDates (a, b) {
      const dateA = this.convertDate(a[4])
      const dateB = this.convertDate(b[4])

      if (dateA < dateB) {
        return -1
      }
      if (dateA > dateB) {
        return 1
      }
      return 0
    },
    adjustmentDate () {
      let count = 0
      let AnoMes = []

      for (let year = 2020; year <= 2023; year++) {
        for (let month = 1; month <= 12; month++) {
          if (year === 2023 && month > 8) {
            break // Para no mês de julho de 2023
          }
          let monthString = month.toString().padStart(2, '0')
          AnoMes.push(`${year}-${monthString}`)
        }
      }

      this.associados.forEach(el => {
        // if (el.matricula === '6853') {
        AnoMes.forEach(date => {
          const dateMov = moment(date).format('MM-YYYY')
          if (el.movimentacao !== undefined && el.mensalidade !== undefined) {
            if (el.movimentacao[dateMov]) {
              // firebase.firestore().collection('associados').doc(el.matricula).update({
              //   [`mensalidade.${date}`]: {
              //     'ano_mes': date,
              //     'cnpj_empresa': el.cnpj_empresa,
              //     'funcao': el.funcao,
              //     'observacao': el.movimentacao[dateMov]['observacao_mov_instituicao_principal'],
              //     'valor_mensalidade': el.movimentacao[dateMov]['valor_mens_mov_instituicao_principal']
              //   }
              // })
              count += 1
              console.log(`${el.matricula} ${el.movimentacao[dateMov]}`)
            // console.log(`${el.movimentacao[dateMov]['observacao_mov_instituicao_principal']}`)
            // console.log(`${el.movimentacao[dateMov]['valor_mens_mov_instituicao_principal']}`)
            }
          }
        })
        // }
      })

      // this.associados.forEach(el => {
      //   if (el.data_saida_sindicato !== '' && el.socio === true) {
      //     this.empresas.forEach(doc => {
      //       if (el.cnpj_instituicao_principal === doc.cnpj) {
      //       // datas.push(el.data_de_associacao)
      //         console.log(`${el.matricula}, ${el.nome}, ${doc.nome}`)
      //         // console.log(this.formatarData(el.data_de_associacao) + ' data_de_associacao')
      //         count += 1
      //       }
      //     })
      //   }
      // })

      // const dateValitedEntry = this.dateValidSelect(datas)
      // console.log(this.contarStringsPorMes(dateValitedEntry))

      // dateValitedEntry.forEach(doc => {
      //   console.log(doc)
      // })

      // console.log(this.monthEntries)

      console.log(count + '------ terminou ------------')
    },
    dirigente () {
      let count = 0
      socios[0].data.forEach(el => {
        this.associados.forEach(doc => {
          // if (el.data_entrada_socio === '' && el.socio === false) {
          // if (el.observacao !== '') {
          // Fazendo o insert dos dados do associado
          // firebase.firestore().collection('socios').doc(el.matricula).update({
          //   motivo_saida: 'A pedido'
          // })
          if (el.matricula === doc.matricula) {
            if (el.movimentacao !== undefined && doc.socio === true) {
              if (el.movimentacao['08-2023']) {
                if (el.movimentacao['08-2023']['valor_mens_mov_instituicao_principal'] !== doc.valor_mensalidade) {
                  count += 1
                  console.log(`${el.matricula}`)
                  console.log(`${el.movimentacao['08-2023']['valor_mens_mov_instituicao_principal']}`)
                  console.log(`${el.movimentacao['08-2023']['observacao_mov_instituicao_principal']}`)
                  console.log(' --------- ')
                  console.log(`${doc.valor_mensalidade}`)
                  console.log(`${doc.observacao}`)
                  // firebase.firestore().collection('socios').doc(doc.matricula).update({
                  //   valor_mensalidade: el.movimentacao['08-2023']['valor_mens_mov_instituicao_principal'],
                  //   observacao: el.movimentacao['08-2023']['observacao_mov_instituicao_principal']
                  // })
                }
              }
            }
          }
          // }
          // }
        })
      })
      console.log(count + '------ terminou ------------')
    },
    contarStringsPorMes (meses) {
      const resultados = {}

      for (const mes in meses) {
        resultados[mes] = meses[mes].length
      }

      return resultados
    },
    returnUndefined (value) { // Função que normaliza o retorno undefined para vazio
      return value === undefined ? '' : value
    },
    dateFormatter (date) {
      let parts
      let year
      let month
      let day
      let formattedDate

      parts = date.split('/') // Separa a data entre dia, mes e ano
      year = parseInt(parts[2])// Pega o ano
      month = parseInt(parts[1]) // Pega o mes
      day = parseInt(parts[0]) // Pega o dia

      // Formata a data completa para validar com o primeiro e o ultimo dia
      formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)

      return formattedDate
    },
    transformarData (dataOriginal) {
      const partesData = dataOriginal.split('/')
      let dataTransformada

      if (partesData.length === 3) {
        const dia = partesData[0]
        const mes = partesData[1]
        let ano = partesData[2]

        if (ano < 24) {
          ano = 20 + ano
        } else {
          ano = 19 + ano
        }

        // Verificando se a data resultante é válida
        if (this.validarData(dia, mes, ano)) {
          dataTransformada = `${dia}/${mes}/${ano}`
        } else {
          dataTransformada = 'Data inválida'
        }
      } else {
        dataTransformada = 'Formato de data inválido'
      }
      return dataTransformada
    },
    dateValidSelect (datas) {
      // Criar um objeto para armazenar os anos e as datas correspondentes
      const mesEDatas = []

      // Percorrer o array de datas
      for (const data of datas) {
        const parts2 = this.formatarData(data).split('/') // Separa a data entre dia, mes e ano
        // const parts = data.split('/') // Separa a data entre dia, mes e ano
        // const month = parseInt(parts[1])// Pega o mês
        const month2 = parts2[1]// Pega o mês
        // console.log(month2 + ' month2')

        // Verificar se o ano já existe no objeto
        if (mesEDatas.hasOwnProperty(month2)) {
          // Se existir, adicionar a data ao array correspondente
          mesEDatas[month2].push(month2)
        } else {
          // Se não existir, criar um novo array com a data
          mesEDatas[month2] = [month2]
        }
      }
      return mesEDatas
    },
    formatarData (dataString) {
      // Array com os nomes dos meses por extenso
      const mesesPorExtenso = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ]

      // Separar o dia, mês e ano da string
      const [dia, mes, ano] = dataString.split('/')

      // Converter o mês numérico para o mês por extenso
      const mesPorExtenso = mesesPorExtenso[parseInt(mes) - 1]

      // Retornar a data formatada
      return `${dia}/${mesPorExtenso}/${ano}`
    }
  }
}
</script>
<style lang='scss' scoped>
.v-content {
  height: 100vh;

}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.botoes {
  padding: 10px 36px;
  justify-content: center;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-application .indigo {
  height: 45px !important;
}

.v-card__text,
.v-card__title {
  padding: 18px 26px 3px 26px;
}

.v-toolbar__title {
  margin: auto;
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 90px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 25px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.dependentesTitle {
  font-size: 18px;
  margin-top: 0px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #041c32;
}
</style>
