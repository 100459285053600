<template v-slot>
  <v-content id="tamanhoDiv">
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-account-group
      </v-icon>
      <v-card-text class="text">
        Sócios
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
      :style="{ height: `${tamanhoPagina}px`}"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="10"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                cadastrar
              </v-toolbar-title>
              <button
                class="btnFechar"
                @click="voltarPaginaAnterior"
              >
                <v-icon>mdi-close</v-icon>
              </button>
            </v-toolbar>

            <!-- Formulario  -->
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <div class="sesoesTitle">
                  Dados Pessoais
                </div>
                <!-- Matricula, Nome completo e CPF -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="matricula"
                      label="Matricula"
                      name="matricula"
                      prepend-icon="mdi-newspaper-variant"
                      type="text"
                      :disabled="$store.state.bloqueiaEdicaoMatricula"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="nome"
                      label="Nome Completo"
                      name="nome"
                      prepend-icon="mdi-account-circle"
                      type="text"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-if="$store.state.cpfObrigatorio"
                      v-model="cpf"
                      v-mask="cpfMascara"
                      label="CPF"
                      name="cpf"
                      prepend-icon="mdi-text-box-check"
                      :rules="[validaCpf]"
                      :required="$store.state.cpfObrigatorio"
                    />
                    <v-text-field
                      v-if="!$store.state.cpfObrigatorio"
                      v-model="cpf"
                      v-mask="cpfMascara"
                      label="CPF"
                      name="cpf"
                      prepend-icon="mdi-text-box-check"
                    />
                  </v-col>
                </v-row>

                <!-- Estado Civil, Data de Nascimento, RG e Carteira de Trabalho -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="estadoCivil"
                      :items="estadoCivilOpcoes"
                      prepend-icon="mdi-account-supervisor"
                      label="Estado Civil"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="dataNascimento"
                      v-mask="dataMascara"
                      label="Data de Nascimento"
                      name="dataNascimento"
                      prepend-icon="mdi-calendar-range"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="rg"
                      label="RG"
                      name="rg"
                      prepend-icon="mdi-text-box-check-outline"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="carteiraProfissional"
                      label="Cart. Trab. Nº / Série"
                      name="carteiraProfissional"
                      prepend-icon="mdi-briefcase"
                    />
                  </v-col>
                </v-row>

                <!-- CEP, Endereço, Numero, Complemento e Bairro  -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="cep"
                      v-mask="cepMascara"
                      label="CEP"
                      name="cep"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="endereco"
                      label="Endereço"
                      name="endereco"
                      prepend-icon="mdi-map-marker"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="numero"
                      label="Nº"
                      name="numero"
                      prepend-icon="mdi-home-group"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="complemento"
                      label="Compl"
                      name="complemento"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="bairro"
                      label="Bairro"
                      name="bairro"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <!-- Cidade, UF, Celular e Email -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="cidade"
                      label="Cidade"
                      name="cidade"
                      prepend-icon="mdi-map-marker"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-select
                      v-model="uf"
                      label="UF"
                      name="uf"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :items="ufOpcoes"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="celular"
                      v-mask="celularMascara"
                      label="Celular"
                      name="celular"
                      prepend-icon="mdi-phone-in-talk-outline"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="email"
                      label="E-mail"
                      name="email"
                      prepend-icon="mdi-email"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <div class="sesoesTitle">
                  Dados Profissionais
                </div>

                <!-- Empresa -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="empresa"
                      label="Empresa"
                      name="empresa"
                      prepend-icon="mdi-hospital-building"
                      type="text"
                      :items="empresaOpcoes"
                      :rules="campoObrigatorio"
                      required
                      clearable
                      @change="carregaCNPJ"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="dataAdmissao"
                      v-mask="dataMascara"
                      label="Data de Admissão"
                      name="dataAdmissao"
                      prepend-icon="mdi-calendar-range"
                      type="text"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      v-model="funcao"
                      label="Função"
                      name="funcao"
                      prepend-icon="mdi-account-hard-hat"
                      type="text"
                      :items="funcoesEmpresaOpcoes"
                      clearable
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="setor"
                      label="Setor"
                      name="setor"
                      prepend-icon="mdi-briefcase-account-outline"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="valorMensalidade"
                      v-currency
                      label="Valor"
                      name="valorMensalidade"
                      prepend-icon="mdi-cash-usd-outline"
                      type="text"
                      prefix="R$  "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="observacao"
                      label="Observação"
                      name="observacao"
                      prepend-icon="mdi-tooltip-text"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <div class="sesoesTitle">
                  Dados Sindicato
                </div>
                <!-- Dados Sindicato -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="7"
                  >
                    <v-row>
                      <v-icon
                        medium
                        class="iconSocio"
                      >
                        mdi-account-start
                      </v-icon>
                      <p class="titleSocio">
                        Sócio
                      </p>
                    </v-row>
                    <v-radio-group
                      v-model="socio"
                      class="checkBoxSocio"
                      :mandatory="false"
                    >
                      <v-row>
                        <v-radio
                          label="Sim —"
                          :value="true"
                        />
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <v-text-field
                            v-model="dataEntradaSocio"
                            v-mask="dataMascara"
                            class="dataEntradaSocio"
                            name="dataEntradaSocio"
                            label="Desde"
                            :rules="validaDataSocio"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-select
                            v-model="quemAssociou"
                            class="dataEntradaSocio"
                            label="Quem Associou"
                            name="quemAssociou"
                            type="text"
                            :items="dirigentesOpcoes"
                            :rules="campoObrigatorio"
                            clearable
                          />
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-row>
                      <v-icon
                        medium
                        class="iconSocio"
                      >
                        mdi-account-tie
                      </v-icon>
                      <p class="titleSocio">
                        Dirigente
                      </p>
                    </v-row>
                    <v-radio-group
                      v-model="dirigente"
                      class="checkBoxDirigente"
                      :mandatory="false"
                    >
                      <v-row>
                        <v-col cols="">
                          <v-radio
                            label="Sim"
                            :value="true"
                          />
                          <v-radio
                            label="Não"
                            :value="false"
                          />
                        </v-col>
                        <v-col cols="4" />
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    class="ficha"
                  >
                    <v-row>
                      <v-icon
                        medium
                        class="iconSocio"
                      >
                        mdi-file-cloud
                      </v-icon>
                      <p class="tituloFicha">
                        Anexar Ficha Associado
                      </p>
                    </v-row>
                    <input
                      class="fileFicha"
                      type="file"
                      accept="image/*"
                      @change="onImage"
                    >

                    <div v-show="ficha !== undefined">
                      <p
                        v-if="ficha !== ''"
                        class="nameImage"
                      >
                        {{ ficha }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <div class="dependentesTitle">
                  Dependentes
                </div>

                <v-row
                  v-for="item in items"
                  :key="item.idDependente"
                >
                  <v-col
                    cols="12"
                    sm="5"
                  >
                    <v-text-field
                      v-model="item.nomeDependente"
                      label="Nome"
                      name="nomeDependente"
                      prepend-icon="mdi-account-circle"
                      type="text"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="item.dataNascimentoDependente"
                      v-mask="dataMascara"
                      label="Data de Nascimento"
                      name="dataNascimentoDependente"
                      prepend-icon="mdi-calendar-range"
                      type="text"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="item.parentesco"
                      label="Parentesco"
                      prepend-icon="mdi-account-supervisor"
                      :items="parentescoOpcoes"
                    />
                  </v-col>
                  <v-icon @click="removeDependente(item)">
                    mdi-delete
                  </v-icon>
                </v-row>

                <v-row v-if="adicionaLinhaDependente">
                  <v-col
                    cols="12"
                    sm="5"
                  >
                    <v-text-field
                      v-model="nomeDependente"
                      label="Nome"
                      name="nomeDependente"
                      prepend-icon="mdi-account-circle"
                      type="text"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="dataNascimentoDependente"
                      v-mask="dataMascara"
                      label="Data Nascimento"
                      name="dataNascimentoDependente"
                      prepend-icon="mdi-calendar-range"
                      type="text"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="parentesco"
                      label="Parentesco"
                      prepend-icon="mdi-account-supervisor"
                      :items="parentescoOpcoes"
                    />
                  </v-col>
                </v-row>

                <!-- Adicionar Dependente -->
                <v-btn
                  v-if="nomeDependente === '' && !adicionaLinhaDependente"
                  class="btnDependente"
                  fab
                  dark
                  color="indigo"
                  @click="adicionarDependente"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <!-- Deletar Dependente -->
                <v-btn
                  v-if="adicionaLinhaDependente && nomeDependente === ''"
                  class="btnDependente"
                  fab
                  dark
                  color="error"
                  @click="deletarLinhaDependente"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <!-- Confirmar Dependente -->
                <v-btn
                  v-if="nomeDependente !== ''"
                  class="btnDependente"
                  fab
                  dark
                  color="success"
                  @click="confirmarDadosDependente"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-form>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-model="erro"
                type="error"
                dismissible
              >
                Confira as informações e tente novamente
              </v-alert>
              <v-alert
                v-model="sucesso"
                type="success"
                dismissible
              >
                Sócio cadastrado com sucesso
              </v-alert>
              <v-alert
                v-model="cpfObrigatorio"
                type="error"
                dismissible
              >
                Sócio já cadastrado!!!
              </v-alert>
            </div>

            <v-card-actions class="botoes">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="voltarPaginaAnterior"
                >
                  Cancelar
                </v-btn>
              </v-flex>
              <v-btn
                class="my-2"
                rounded
                color="#1976d2"
                :loading="carregando"
                depressed
                :disabled="adicionaLinhaDependente ? true : false"
                @click="verificaSocioCadastrado"
              >
                Cadastrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'CadastrarSocio',
  data () {
    return {
      // Variáveis Página
      valid: false,
      tamanhoPagina: '',
      // Dados Pessoais
      matricula: '',
      nome: '',
      cpf: '',
      estadoCivil: '',
      dataNascimento: '',
      rg: '',
      carteiraProfissional: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      celular: '',
      email: '',
      // Dados Profissionais
      empresa: '',
      cnpjEmpresa: '',
      dataAdmissao: '',
      funcao: '',
      setor: '',
      valorMensalidade: '',
      observacao: '',
      // Dados Sindicato
      socio: true,
      dataEntradaSocio: '',
      quemAssociou: '',
      dataSaidaSocio: '',
      motivoSaidaSocio: '',
      dirigente: false,
      ficha: '',
      // Dependentes
      idDependente: '',
      idDependenteAux: '',
      nomeDependente: '',
      dataNascimentoDependente: '',
      parentesco: '',
      // Mascara Campos
      dataMascara: '##/##/####',
      cpfMascara: '###.###.###-##',
      cepMascara: '#####-###',
      celularMascara: '(##) # ####-####',
      // Validação campos
      campoObrigatorio: [
        v => !!v || 'O campo é obrigatório'
      ],
      validaData: [
        v => (v.length === 0 || v.length === 10) || 'Insira a data completa Ex.:06/04/1972'
      ],
      validaDataSocio: [
        v => v.length === 10 || 'Data deve ser completa. Ex.:01/01/2023'
      ],
      // Opções dos Campos
      estadoCivilOpcoes: [
        'Casado(a)',
        'Solteiro(a)',
        'Divorciado(a)',
        'Não Informado',
        'Separado(a)',
        'União Estável',
        'Viúvo(a)'
      ],
      ufOpcoes: [
        'RS',
        'SC',
        'PR'
      ],
      funcoesEmpresaOpcoes: [],
      empresas: undefined,
      socios: undefined,
      items: undefined,
      dirigentesOpcoes: [],
      empresaOpcoes: [],
      ultimaMatricula: [],
      parentescoOpcoes: [
        'Cônjuge',
        'Filho(a)',
        'Pai',
        'Mãe',
        'Irmão(ã)'
      ],
      motivosSaidaSocio: [
        'A pedido',
        'Aposentadoria',
        'Óbito',
        'Rescisão'
      ],
      // Variáveis Globais
      erro: false,
      cpfObrigatorio: false,
      carregando: false,
      sucesso: false,
      arquivoFicha: null,
      adicionaLinhaDependente: false
    }
  },
  computed: {
    validaCpf () {
      return (value) => {
        if (this.$store.state.cpfObrigatorio) {
          if (!value) {
            return 'CPF é obrigatório.'
          }
          if (value.length !== 14) {
            return 'Insira um CPF válido com 14 digitos'
          }
        }
        return undefined
      }
    }
  },
  created () {
    this.retornoUltimaMatricula()
    this.retornaEmpresas()
    this.retornaDirigentes()
    this.retornaDependentes()
  },
  mounted () {
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.tamanhoPagina = tamanhoPagina - 145
  },
  methods: {
    removeDependente (item) {
      this.items.splice(this.items.indexOf(item), 1)
    },
    adicionarDependente () {
      this.adicionaLinhaDependente = true
    },
    deletarLinhaDependente () {
      this.adicionaLinhaDependente = false
    },
    confirmarDadosDependente () {
      this.items = this.items === undefined ? [] : this.items
      this.adicionaLinhaDependente = false
      this.idDependente = this.idDependente + 1
      this.idDependenteAux = this.idDependente
      this.idDependente = this.idDependente.toString()

      this.items.push(
        {
          idDependente: this.idDependente,
          nomeDependente: this.nomeDependente,
          matriculaSocio: this.matricula,
          dataNascimentoDependente: this.dataNascimentoDependente,
          parentesco: this.parentesco
        }
      )

      this.nomeDependente = ''
      this.dataNascimentoDependente = ''
      this.parentesco = ''
      this.idDependente = this.idDependenteAux
    },
    cadastrar () {
      if (this.adicionaLinhaDependente) {
        this.confirmarDadosDependente()
      }

      this.carregando = true
      this.erro = false
      if (!this.$refs.form.validate()) {
        this.erro = true
        this.carregando = false

        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.erro = false
        }, 2000)
        return
      }

      // Fazendo o insert dos dados do socio
      firebase.firestore().collection('socios').doc(this.matricula).set({
        // Dados Pessoais
        matricula: this.matricula,
        nome: this.primeiraLetraMaiuscula(this.nome),
        cpf: this.cpf,
        estado_civil: this.estadoCivil,
        data_nascimento: this.dataNascimento,
        rg: this.rg,
        carteira_profissional: this.carteiraProfissional,
        cep: this.cep,
        endereco: this.primeiraLetraMaiuscula(this.endereco),
        numero: this.numero,
        complemento: this.primeiraLetraMaiuscula(this.complemento),
        bairro: this.bairro,
        cidade: this.primeiraLetraMaiuscula(this.cidade),
        uf: this.uf,
        celular: this.celular,
        email: this.email.toLowerCase(),
        // Dados Profissionais
        cnpj_empresa: this.cnpjEmpresa,
        data_admissao: this.dataAdmissao,
        funcao: this.primeiraLetraMaiuscula(this.funcao),
        setor: this.primeiraLetraMaiuscula(this.setor),
        valor_mensalidade: this.ajustaValor(this.valor),
        observacao: this.primeiraLetraMaiuscula(this.observacao),
        // Dados Sindicato
        socio: this.socio,
        data_entrada_socio: this.dataEntradaSocio,
        quem_associou: this.quemAssociou,
        data_saida_socio: this.dataSaidaSocio,
        motivo_saida: this.motivoSaidaSocio,
        dirigente: this.dirigente,
        ficha: this.ficha
      })
      // Fazendo o Insert de todos os dependentes
      if (this.items !== undefined) {
        this.items.forEach((el) => {
          firebase.firestore().collection('dependentes').doc(el.idDependente).set({
            id: el.idDependente,
            matricula_associado: el.matriculaSocio,
            nome_dependente: el.nomeDependente,
            parentesco: el.parentesco,
            data_nascimento: el.dataNascimentoDependente
          })
        })
      }
      this.salvarFichaFirebase()

      // Valida se tem no Store os dados dos socios
      this.socios = this.$store.state.socios

      if (this.socios !== undefined) {
        this.socios.push(
          {
            // Dados Pessoais
            matricula: this.matricula,
            nome: this.primeiraLetraMaiuscula(this.nome),
            cpf: this.cpf,
            estado_civil: this.estadoCivil,
            data_nascimento: this.dataNascimento,
            rg: this.rg,
            carteira_profissional: this.carteiraProfissional,
            cep: this.cep,
            endereco: this.primeiraLetraMaiuscula(this.endereco),
            numero: this.numero,
            complemento: this.primeiraLetraMaiuscula(this.complemento),
            bairro: this.bairro,
            cidade: this.primeiraLetraMaiuscula(this.cidade),
            uf: this.uf,
            celular: this.celular,
            email: this.email.toLowerCase(),
            // Dados Profissionais
            cnpj_empresa: this.cnpjEmpresa,
            data_admissao: this.dataAdmissao,
            funcao: this.primeiraLetraMaiuscula(this.funcao),
            setor: this.primeiraLetraMaiuscula(this.setor),
            valor_mensalidade: this.ajustaValor(this.valor),
            observacao: this.primeiraLetraMaiuscula(this.observacao),
            // Dados Sindicato
            socio: this.socio,
            data_entrada_socio: this.dataEntradaSocio,
            quem_associou: this.quemAssociou,
            data_saida_socio: this.dataSaidaSocio,
            motivo_saida: this.motivoSaidaSocio,
            dirigente: this.dirigente,
            ficha: this.ficha
          }
        )
      }

      firebase.firestore().collection('socios')
        .where('matricula', '==', this.matricula)
        .get().then((snapshot) => {
          snapshot.forEach((doc) => {
            this.dadosVisualizacao = doc.data()
          })
        })
        .catch((err) => {
          console.log('Error não está retornando Dados Dependentes - ', err)
        })

      // Desaparece a mensagem após 2 segundos
      setTimeout(() => {
        this.sucesso = true
        this.carregando = false
      }, 2000)

      setTimeout(() => {
        this.voltarPaginaAnterior()
      }, 4000)
    },
    retornaEmpresas () {
      this.retornaFuncaoTrabalho()

      // Valida se tem no Store os dados das instituições
      this.empresas = this.$store.state.empresas

      // Valida se tem instituições no Store senão faz consulta no BD
      if (this.empresas === undefined) {
        this.empresas = []
        firebase.firestore().collection('empresas').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc.data().acesso !== 'Administrador') {
              // Busca Empresas para o Filro
                this.empresas.push(doc.data())
                this.$store.dispatch('setEmpresas', this.empresas)
              }
            })
            this.empresas.forEach((el) => {
              this.empresaOpcoes.push(el.nome)
            })
            this.empresaOpcoes.sort()
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.empresas = this.$store.state.empresas
        this.empresas.forEach((el) => {
          this.empresaOpcoes.push(el.nome)
        })
        this.empresaOpcoes.sort()
      }
    },
    retornaDirigentes () {
      if (this.$store.state.socios === undefined) {
        let dirigentesOpcoesTemp = []
        firebase.firestore().collection('socios').orderBy('nome', 'asc').get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc.data().socio === true && doc.data().dirigente === true) {
                dirigentesOpcoesTemp.push(doc.data().nome)
              }
            })
          })
          .catch(function (error) {
            console.log('Error getting documents: ', error)
          })
        this.dirigentesOpcoes = dirigentesOpcoesTemp
      } else {
        this.$store.state.socios.forEach(doc => {
          if (doc.dirigente) {
            this.dirigentesOpcoes.push(doc.nome)
          }
        })

        this.dirigentesOpcoes = this.dirigentesOpcoes.sort()
      }
    },
    retornoUltimaMatricula () {
      // Valida se tem no Store os dados dos socios
      this.socios = this.$store.state.socios

      if (this.socios === undefined) {
        firebase.firestore().collection('socios').orderBy('matricula', 'desc').limit(1)
          .get().then((snapshot) => {
            snapshot.forEach((doc) => {
              this.matricula = parseInt(doc.data().matricula) + 1
              this.matricula = this.matricula.toString()
            })
          })
          .catch((err) => {
            console.log('Error não está retornando Dados Associados - ', err)
          })
      } else {
        this.socios = this.$store.state.socios
        var ultimoAssociado = this.socios.slice(-1)
        this.matricula = parseInt(ultimoAssociado[0].matricula) + 1
        this.matricula = this.matricula.toString()
      }
    },
    retornaFuncaoTrabalho () {
      let funcoes = []

      if (this.$store.state.funcaoEmpresa === undefined) {
        /** Função para search dados sobre a configuração */
        firebase.firestore().collection('configuracao').doc('funcao').get()
          .then(doc => {
            this.funcoesEmpresaOpcoes = doc.data().descricao

            this.funcoesEmpresaOpcoes.sort()
          })
          .catch((err) => {
            console.log('Error getting documents', err)
          })
      } else {
        this.$store.state.funcaoEmpresa.forEach(doc => {
          funcoes.push(doc)
        })
        funcoes[0].forEach(el => {
          this.funcoesEmpresaOpcoes.push(el)
        })
        this.funcoesEmpresaOpcoes.sort()
      }
    },
    voltarPaginaAnterior () { // Método para voltar a página anterior
      if (this.dadosVisualizacao !== undefined) {
        this.$router.push({
          name: 'detalhesSocio',
          query: { W5kaXNhdWR: this.dadosVisualizacao, KSJMLwWasO: this.empresas }
        })
      } else {
        this.$router.push('/socios')
      }
      this.$refs.form.reset()
    },
    primeiraLetraMaiuscula (value) { // Método para padronizar dados no Banco de Dados
      if (value === '' || value === undefined) {
        return ''
      } else if (value.length <= 4) {
        return value
      } else {
        // Recebe o valor inteiro
        let loweredText = value.toLowerCase()
        // Separa por virgula todas as palavras
        let words = loweredText.split(' ')

        // Faz um for em todas as palavras e coloca a primeira letra maior
        for (let a = 0; a < words.length; a++) {
          let w = words[a]
          let firstLetter = w[0]
          if (firstLetter === undefined) {
            firstLetter = ''
          }

          if (w === 'dos' || w === 'das') {
            w = firstLetter + w.slice(1)
          } else if (w.length > 2 || w.replace('.', '').length === 1) {
            w = firstLetter.toUpperCase() + w.slice(1)
          } else {
            w = firstLetter + w.slice(1)
          }
          // retorna cada palavra com a primeira maior
          words[a] = w
        }

        // retorna a frase inteira
        return words.join(' ')
      }
    },
    carregaCNPJ () {
      this.empresas.forEach((el) => {
        if (el.nome === this.empresa) {
          this.cnpjEmpresa = el.cnpj
        }
      })
    },
    onImage (event) {
      this.arquivoFicha = event.target.files[0]
      this.ficha = event.target.files[0].name
    },
    salvarFichaFirebase () {
      if (this.ficha !== '') {
        const storageRef = firebase.storage()
          .ref(`Fichas Associados/${this.matricula}`)
          .put(this.arquivoFicha)
        storageRef.on('state_changed', snapshot => {
        },
        error => { console.log(error.message) }
        )
      }
    },
    retornaDependentes () {
      firebase.firestore().collection('dependentes').orderBy('id', 'desc').limit(1)
        .get().then((snapshot) => {
          snapshot.forEach((doc) => {
            this.idDependente = parseInt(doc.data().id)
          })
        })
        .catch((err) => {
          console.log('Error não está retornando Dados Dependentes - ', err)
        })
    },
    ajustaValor (value) {
      if (this.valor === '' ||
      this.valor === undefined) {
        value = '0,00'
      }

      return value
    },
    verificaSocioCadastrado () { // Método para verificar se existe o socio já no sistema
      this.retornoUltimaMatricula()
      if (this.$store.state.cpfObrigatorio) { // Valida se a FF está ativa
        this.socios.forEach((doc) => {
          if (doc.cpf.length === 14 && doc.cpf === this.cpf) {
            this.cpfObrigatorio = true
            this.carregando = false
          }
        })
      }

      if (!this.cpfObrigatorio) {
        this.cadastrar()
      }

      setTimeout(() => {
        this.cpfObrigatorio = false
        this.cpf = ''
      }, 3000)
    }
  }
}

</script>
<style  scoped>
.v-content {
  height: 100vh;

}

.v-toolbar__content {
  margin-top: 20px
}

.container {
  padding-top: 4px;
  padding-bottom: 4px;

}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.botoes{
  padding: 0px 36px;
  margin-top: 20px
}

.v-application .my-2 {
  margin-bottom: 17px !important;
  color: white;
}

.v-application .indigo {
    height: 45px !important;
}

.v-card__text, .v-card__title {
  padding: 18px 26px 3px 26px;
}

.v-toolbar__title {
  margin: auto
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.v-text-field {
    margin-top: -8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 90px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 5px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.fill-height{
  align-items: baseline;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 3px;
}

.alertas {
  margin: 0px auto -13px;
  width: 90%;
}

.titleSocio {
  margin-top: 15px;
  margin-left: 10px;
}

.desde {
  margin: 32px -5px 0px 10px;
  font-size: 14px;
}

.checkboxSocioNao {
  margin-top: -40px
}

.checkBoxSocio {
  margin-top: -30px;
  margin-left: 46px;
}

.iconSocio {
  margin-left: 16px;
}

.dataSocioSim {
  margin-top: 0px;
  margin-bottom: 5px;
}

.checkBoxDirigente{
  margin-top: -20px;
  margin-left: 35px;
}

.dependentesTitle {
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #041c32
}

.sesoesTitle {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  color: #041c32
}

.btnFechar {
  margin-bottom: 20px;
}

input {
  width: 124px;
  margin-left: 30px;
}

.nameImage {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: black;
  margin-right: 30px;
}

.ficha p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.naoDirigente {
  margin-left: -3px;
}

.btnDependente {
  position: relative;
  left: 48%;
}

.v-btn--fab.v-size--default {
    width: 46px !important;
    height: 46px;
}

.text-center {
  margin-bottom: 20px;
  font-weight: bold;
  color: #041c32;
}

.dataEntradaSocio {
  margin-top: 0px;
  margin-bottom: 5px;
}

.dataSaidaSocio {
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileFicha {
  margin-top: 10px;
  margin-left: 5px;
}

</style>
