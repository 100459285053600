import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'
// import store from '../store'

// Paginas de Acesso Geral
import Login from '@/templates/Login.vue'
import Home from '@/templates/Home.vue'
import Perfil from '@/templates/Perfil.vue'
import Empresas from '@/templates/Empresas.vue'
import Socios from '@/templates/Socios.vue'
import Relatorios from '@/templates/Relatorios.vue'
import Movimentacao from '@/templates/Movimentacao.vue'
import Backup from '@/templates/Backup.vue'
import Configuracoes from '@/templates/Configuracoes.vue'

// Views
import CadastrarEmpresa from '@/views/CadastrarEmpresa.vue'
import CadastrarSocio from '@/views/CadastrarSocio.vue'
import DetalhesSocio from '@/views/DetalhesSocio.vue'
import EditarSocio from '@/views/EditarSocio.vue'

import Script from '@/data/Script.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Controle de Acesso e Login
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },

    // Páginas de acesso
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/perfil',
      name: 'perfil',
      component: Perfil,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/empresas',
      name: 'empresas',
      component: Empresas,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/socios',
      name: 'socios',
      component: Socios,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/relatorios',
      name: 'relatorios',
      component: Relatorios,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/movimentacao',
      name: 'movimentacao',
      component: Movimentacao,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/backup',
      name: 'backup',
      component: Backup,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/configuracoes',
      name: 'configuracoes',
      component: Configuracoes,
      meta: {
        requeresAuth: true
      }
    },

    // Views
    {
      path: '/cadastrar-empresa',
      name: 'cadastrarEmpresa',
      component: CadastrarEmpresa,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/cadastrar-socio',
      name: 'cadastrarSocio',
      component: CadastrarSocio,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/detalhes-socio',
      name: 'detalhesSocio',
      component: DetalhesSocio,
      meta: {
        requeresAuth: true
      }
    },
    {
      path: '/editar-socio',
      name: 'editarSocio',
      component: EditarSocio,
      meta: {
        requeresAuth: true
      }
    },
    // Scripts
    {
      path: '/script',
      name: 'scriptInsercao',
      component: Script,
      meta: {
        requeresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-unused-vars
  // const isAdmin = store.state.isAdmin
  const { currentUser } = firebase.auth()
  const requiresAuth = to.matched.some(record => record.meta.requeresAuth)

  if (requiresAuth && !currentUser) next('login')
  else if (!requiresAuth && currentUser) next('home')
  // else if (to.path === '/associados') {
  //   const aux = isAdmin ? true : 'associado'
  //   next(aux)
  // }
  else next()
})

export default router
