import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContent,[_c(VCard,{staticClass:"header"},[_c(VIcon,{staticClass:"icon"},[_vm._v(" mdi-cog ")]),_c(VCardText,{staticClass:"text"},[_vm._v(" Configuração ")])],1),_c(VRow,[_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.isAdmin),expression:"isAdmin"}],attrs:{"cols":"12","sm":"3"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"to":"/instituicoes"}},[_c(VIcon,[_vm._v("mdi-hospital-building")]),_c(VCardText,[_vm._v("Instituições Cadastradas")])],1)]}}])})],1),_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"to":"/associados"}},[_c(VIcon,[_vm._v("mdi-account-group")]),_c(VCardText,[_vm._v("Listar Associados")])],1)]}}])})],1),_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2,"to":"/relatorios"}},[_c(VIcon,[_vm._v("mdi-file-chart")]),_c(VCardText,[_vm._v("Relatórios")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }