<template v-slot>
  <v-content id="tamanhoDiv">
    <!-- Header -->
    <v-card class="header">
      <v-icon class="icon">
        mdi-account-group
      </v-icon>
      <v-card-text class="text">
        Sócios
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
      :style="{ height: `${tamanhoPagina}px`}"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                editar
              </v-toolbar-title>
              <button
                class="btnFechar"
                @click="voltarPaginaAnterior"
              >
                <v-icon>mdi-close</v-icon>
              </button>
            </v-toolbar>

            <!-- Modal para criar historico da empresa -->
            <v-dialog
              v-model="modalHistoricoEmpresa"
              width="60%"
            >
              <v-card class="elevation-12">
                <!-- Barra de Título -->
                <v-toolbar
                  class="BarraEditar"
                  color="indigo"
                  dark
                  flat
                >
                  <v-toolbar-title class="BarraAtencao text-uppercase">
                    atenção !!!
                  </v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <div class="textoModal">
                    <p class="font-weight-bold text-center">
                      Você deseja reativar <b>{{ nome }}</b>
                      como sócio(a)?
                    </p>
                    <p class="confirmaAcao">
                      Ao clicar em <b>CONFIRMAR</b> será criado o histórico
                      de trabalho da empresa e limpo os campos:<br>
                      Empresa, Data de Admissão, Função, Setor, Valor e Observação.
                      <br><br>
                      O histórico estará disponível nas informações detalhadas do sócio(a)
                      <br><br>
                    </p>
                  </div>

                  <v-card-actions class="botoesModal">
                    <v-flex>
                      <v-btn
                        class="my-2"
                        rounded
                        color="error"
                        dark
                        @click="cancelar"
                      >
                        Cancelar
                      </v-btn>
                    </v-flex>
                    <v-btn
                      :loading="carregandoBotaoModal"
                      class="my-2"
                      rounded
                      color="#1976d2"
                      dark
                      @click="salvaDadosHistoricoTrabalho"
                    >
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!-- Modal para informar a data de entrada quando vazia -->
            <v-dialog
              v-model="modalDataEntradaSocioVazio"
              width="42%"
            >
              <v-card class="elevation-12">
                <!-- Barra de Título -->
                <v-toolbar
                  class="BarraEditar"
                  color="indigo"
                  dark
                  flat
                >
                  <v-toolbar-title class="BarraAtencao text-uppercase">
                    atenção !!!
                  </v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <div class="textoModal">
                    <p class="font-weight-bold text-center">
                      Informe a <b>Data de Entrada</b><br>
                      como sócio no sindisaude quando<br>
                      <b>{{ nome }}</b> <br>
                      trabalhava na Empresa <br>
                      <b>{{ empresa }}</b> <br>
                      Para poder gerar o histórico no sistema
                    </p>
                  </div>
                  <v-text-field
                    v-model="dataEntradaSocio"
                    v-mask="dataMascara"
                    class="dataSocioSim"
                    name="dataEntradaSocio"
                    label="Data Entrada Sócio"
                    type="text"
                  />
                  <v-card-actions class="botoesModal">
                    <v-flex>
                      <v-btn
                        class="my-2"
                        rounded
                        color="error"
                        dark
                        @click="cancelarModalDataEntrada"
                      >
                        Cancelar
                      </v-btn>
                    </v-flex>
                    <v-btn
                      :loading="carregandoBotaoModal"
                      class="my-2"
                      rounded
                      color="#1976d2"
                      dark
                      @click="abreModalHistoricoEmpresa"
                    >
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!-- Formulario  -->
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validaration
              >
                <div class="text-center">
                  Dados Pessoais
                </div>
                <!-- Matricula, Nome completo e CPF -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="matricula"
                      label="Matricula"
                      name="matricula"
                      prepend-icon="mdi-newspaper-variant"
                      type="text"
                      :disabled="true"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="nome"
                      label="Nome Completo"
                      name="nome"
                      prepend-icon="mdi-account-circle"
                      type="text"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-if="$store.state.cpfObrigatorio"
                      v-model="cpf"
                      v-mask="cpfMascara"
                      label="CPF"
                      name="cpf"
                      prepend-icon="mdi-text-box-check"
                      :rules="[validaCpf]"
                      :required="$store.state.cpfObrigatorio"
                    />
                    <v-text-field
                      v-if="!$store.state.cpfObrigatorio"
                      v-model="cpf"
                      v-mask="cpfMascara"
                      label="CPF"
                      name="cpf"
                      prepend-icon="mdi-text-box-check"
                    />
                  </v-col>
                </v-row>

                <!-- Estado Civil, Data de Nascimento, RG e Carteira de Trabalho -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="estadoCivil"
                      :items="estadoCivilOpcoes"
                      prepend-icon="mdi-account-supervisor"
                      label="Estado Civil"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="dataNascimento"
                      v-mask="dataMascara"
                      label="Data de Nascimento"
                      name="dataNascimento"
                      prepend-icon="mdi-calendar-range"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="rg"
                      label="RG"
                      name="rg"
                      prepend-icon="mdi-text-box-check-outline"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="carteiraProfissional"
                      label="Cart. Trab. Nº / Série"
                      name="carteiraProfissional"
                      prepend-icon="mdi-briefcase"
                    />
                  </v-col>
                </v-row>

                <!-- CEP, Endereço, Numero, Complemento e Bairro  -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="cep"
                      v-mask="cepMascara"
                      label="CEP"
                      name="cep"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="endereco"
                      label="Endereço"
                      name="endereco"
                      prepend-icon="mdi-map-marker"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="numero"
                      label="Nº"
                      name="numero"
                      prepend-icon="mdi-home-group"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="complemento"
                      label="Compl"
                      name="complemento"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="bairro"
                      label="Bairro"
                      name="bairro"
                      prepend-icon="mdi-home-city"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <!-- Cidade, UF, Celular e Email -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="cidade"
                      label="Cidade"
                      name="cidade"
                      prepend-icon="mdi-map-marker"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-select
                      v-model="uf"
                      label="UF"
                      name="uf"
                      prepend-icon="mdi-google-maps"
                      type="text"
                      :items="ufOpcoes"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="celular"
                      v-mask="celularMascara"
                      label="Celular"
                      name="celular"
                      prepend-icon="mdi-phone-in-talk-outline"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="email"
                      label="E-mail"
                      name="email"
                      prepend-icon="mdi-email"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <div class="text-center">
                  Dados Profissionais
                </div>

                <!-- Empresa -->
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="empresa"
                      label="Empresa Principal"
                      name="empresa"
                      prepend-icon="mdi-hospital-building"
                      type="text"
                      :items="empresaOpcoes"
                      :rules="campoObrigatorio"
                      required
                      clearable
                      @change="carregaCNPJ"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="dataAdmissao"
                      v-mask="dataMascara"
                      label="Data de Admissão"
                      name="dataAdmissao"
                      prepend-icon="mdi-calendar-range"
                      type="text"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      v-model="funcao"
                      label="Função"
                      name="funcao"
                      prepend-icon="mdi-account-hard-hat"
                      type="text"
                      :items="funcoesEmpresa"
                      clearable
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="setor"
                      label="Setor"
                      name="setor"
                      prepend-icon="mdi-briefcase-account-outline"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-text-field
                      v-model="valorMensalidade"
                      v-currency
                      label="Valor"
                      name="valorMensalidade"
                      prepend-icon="mdi-cash-usd-outline"
                      type="text"
                      prefix="R$  "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="observacao"
                      label="Observação"
                      name="observacao"
                      prepend-icon="mdi-tooltip-text"
                      type="text"
                    />
                  </v-col>
                </v-row>

                <div class="text-center">
                  Dados Sindicato
                </div>

                <v-row>
                  <v-col
                    cols="12"
                    sm="7"
                  >
                    <v-row>
                      <v-icon
                        medium
                        class="iconSocio"
                      >
                        mdi-account-star
                      </v-icon>
                      <p class="titleSocio">
                        Sócio
                      </p>
                    </v-row>
                    <v-radio-group
                      v-model="socio"
                      class="checkBoxSocio"
                      :mandatory="false"
                      @change="abreModalHistoricoEmpresa"
                    >
                      <v-row>
                        <v-radio
                          label="Sim —"
                          :value="true"
                        />
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <v-text-field
                            v-model="dataEntradaSocio"
                            v-mask="dataMascara"
                            class="dataSocioSim"
                            name="dataEntradaSocio"
                            label="Desde"
                            :rules="validaDataSocio"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          :sm="tamanhoQuemAssociou"
                        >
                          <v-select
                            v-model="quemAssociou"
                            class="dataSocioSim"
                            label="Quem Associou"
                            name="quemAssociou"
                            type="text"
                            :rules="campoObrigatorio"
                            :items="dirigentes"
                            @change="validaOutroAssociou"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                        >
                          <v-text-field
                            v-if="quemAssociou === 'Outro'"
                            v-model="outroAssociou"
                            class="dataSocioSim"
                            label="Outro Associou"
                            name="outroAssociou"
                            type="text"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="checkboxSocioNao">
                        <v-radio
                          label="Não —"
                          :value="false"
                        />
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <v-text-field
                            v-model="dataSaidaSocio"
                            v-mask="dataMascara"
                            class="dataSaidaSocio"
                            name="dataSaidaSocio"
                            label="Desde"
                            type="text"
                            :rules="[validaDataSaidaSocio]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-select
                            v-model="motivoSaidaSocio"
                            class="dataSaidaSocio"
                            label="Motivo de Saída"
                            name="motivoSaidaSocio"
                            type="text"
                            :items="motivosSaidaSocio"
                            :rules="[validaMotivoSaidaSocio]"
                            clearable
                          />
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>

                  <!-- Dirigente -->
                  <v-col
                    cols="12"
                    sm=""
                  >
                    <v-row>
                      <v-icon
                        medium
                        class="iconSocio"
                      >
                        mdi-account-tie
                      </v-icon>
                      <p class="titleSocio">
                        Dirigente
                      </p>
                    </v-row>
                    <v-radio-group
                      v-model="dirigente"
                      class="checkBoxDirigente"
                      :mandatory="false"
                    >
                      <v-row>
                        <v-col cols="">
                          <v-radio
                            label="Sim"
                            :value="true"
                          />
                          <v-radio
                            label="Não"
                            :value="false"
                          />
                        </v-col>
                        <v-col cols="4" />
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    class="ficha"
                  >
                    <v-row>
                      <v-icon
                        medium
                        class="iconSocio"
                      >
                        mdi-file-cloud
                      </v-icon>
                      <p class="tituloFicha">
                        Anexar Ficha Associado
                      </p>
                    </v-row>
                    <input
                      class="fileFicha"
                      type="file"
                      accept="image/*"
                      @change="onImage"
                    >

                    <div v-show="ficha !== undefined">
                      <p
                        v-if="ficha !== ''"
                        class="nameImage"
                      >
                        {{ ficha }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <div class="dependentesTitle">
                  Dependentes
                </div>

                <v-row
                  v-for="item in items"
                  :key="item.idDependente"
                >
                  <v-col
                    cols="12"
                    sm="5"
                  >
                    <v-text-field
                      v-model="item.nome_dependente"
                      label="Nome"
                      name="nomeDependente"
                      prepend-icon="mdi-account-circle"
                      type="text"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="item.data_nascimento"
                      v-mask="dataMascara"
                      label="Data Nasc"
                      name="dataNascimentoDependente"
                      prepend-icon="mdi-calendar-range"
                      type="text"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="item.parentesco"
                      label="Parentesco"
                      prepend-icon="mdi-account-supervisor"
                      :items="parentescoOpcoes"
                      required
                    />
                  </v-col>
                  <v-icon @click="removeDependente(item)">
                    mdi-delete
                  </v-icon>
                </v-row>

                <v-row v-if="adicionaLinhaDependente">
                  <v-col
                    cols="12"
                    sm="5"
                  >
                    <v-text-field
                      v-model="nomeDependente"
                      label="Nome"
                      name="nomeDependente"
                      prepend-icon="mdi-account-circle"
                      type="text"
                      :rules="campoObrigatorio"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="dataNascimentoDependente"
                      v-mask="dataMascara"
                      label="Data Nasc"
                      name="dataNascimentoDependente"
                      prepend-icon="mdi-calendar-range"
                      type="text"
                      :rules="validaData"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      v-model="parentesco"
                      label="Parentesco"
                      prepend-icon="mdi-account-supervisor"
                      :items="parentescoOpcoes"
                      required
                    />
                  </v-col>
                </v-row>

                <!-- Adicionar Dependente -->
                <v-btn
                  v-if="nomeDependente === '' && !adicionaLinhaDependente"
                  class="btnDependente"
                  fab
                  dark
                  color="indigo"
                  @click="addDependente"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <!-- Deletar Dependente -->
                <v-btn
                  v-if="adicionaLinhaDependente && nomeDependente === ''"
                  class="btnDependente"
                  fab
                  dark
                  color="error"
                  @click="deleteLinhaDependente"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <!-- Confirmar Dependente -->
                <v-btn
                  v-if="nomeDependente !== ''"
                  class="btnDependente"
                  fab
                  dark
                  color="success"
                  @click="confirmDadosDependente"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-form>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-model="erro"
                type="error"
                dismissible
              >
                Confira as informações e tente novamente
              </v-alert>

              <v-alert
                v-model="sucesso"
                type="success"
                dismissible
              >
                Sócio alterado com sucesso
              </v-alert>
            </div>

            <v-card-actions class="botoes">
              <v-flex>
                <v-btn
                  class="my-2"
                  rounded
                  color="error"
                  dark
                  @click="voltarPaginaAnterior"
                >
                  Cancelar
                </v-btn>
              </v-flex>
              <v-btn
                class="my-2"
                rounded
                color="#1976d2"
                :loading="carregando"
                :disabled="adicionaLinhaDependente ? true : false"
                @click="editarSocio"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'EditarSocio',
  data () {
    return {
      // Variáveis Página
      valid: false,
      tamanhoPagina: '',
      // Dados Pessoais
      matricula: '',
      nome: '',
      cpf: '',
      estadoCivil: '',
      dataNascimento: '',
      rg: '',
      carteiraProfissional: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      celular: '',
      email: '',
      // Dados Profissionais
      empresa: '',
      cnpjEmpresa: '',
      dataAdmissao: '',
      funcao: '',
      setor: '',
      valorMensalidade: '',
      observacao: '',
      historicoTrabalhoAssociacao: '',
      // Dados Sindicato
      socio: true,
      dataEntradaSocio: '',
      quemAssociou: '',
      outroAssociou: '',
      dataSaidaSocio: '',
      motivoSaidaSocio: '',
      dirigente: false,
      ficha: '',
      // Dependentes
      idDependente: '',
      idDependenteAux: '',
      nomeDependente: '',
      dataNascimentoDependente: '',
      parentesco: '',
      // Mascara Campos
      dataMascara: '##/##/####',
      cpfMascara: '###.###.###-##',
      cepMascara: '#####-###',
      celularMascara: '(##) # ####-####',
      // Validação campos
      campoObrigatorio: [
        v => !!v || 'O campo é obrigatório'
      ],
      validaData: [
        v => (v.length === 0 || v.length === 10) || 'Insira a data completa Ex.:06/04/1972'
      ],
      validaDataSocio: [
        v => v.length === 10 || 'Data deve ser completa. Ex.:01/01/2023'
      ],
      // Opções dos Campos
      estadoCivilOpcoes: [
        'Casado(a)',
        'Solteiro(a)',
        'Divorciado(a)',
        'Não Informado',
        'Separado(a)',
        'União Estável',
        'Viúvo(a)'
      ],
      ufOpcoes: [
        'RS',
        'SC',
        'PR'
      ],
      funcoesEmpresa: [],
      empresas: undefined,
      socios: undefined,
      items: undefined,
      dirigentes: [],
      empresaOpcoes: [],
      ultimaMatricula: [],
      parentescoOpcoes: [
        'Cônjuge',
        'Filho(a)',
        'Pai',
        'Mãe',
        'Irmão(ã)'
      ],
      motivosSaidaSocio: [
        'A pedido',
        'Aposentadoria',
        'Óbito',
        'Rescisão'
      ],
      // Variáveis Globais
      erro: false,
      cpfObrigatorio: false,
      carregando: false,
      sucesso: false,
      arquivoFicha: null,
      adicionaLinhaDependente: false,
      idUltimoDependente: '',
      historicoSocio: false,
      modalDataEntradaSocioVazio: false,
      tamanhoQuemAssociou: 6,
      // Variáveis Modal Historico Empresa
      modalHistoricoEmpresa: false,
      carregandoBotaoModal: false,
      dadosHistoricoTrabalhoAssociacao: {
        cnpj_empresa: '',
        data_admissao: '',
        funcao: '',
        setor: '',
        observacao: '',
        valor_mensalidade: ''
      }
    }
  },
  computed: {
    validaCpf () {
      return (value) => {
        if (this.$store.state.cpfObrigatorio) {
          if (!value) {
            return 'CPF é obrigatório.'
          }
          if (value.length !== 14) {
            return 'Insira um CPF válido com 14 digitos'
          }
        }
        return true
      }
    },
    validaDataSaidaSocio () {
      return (value) => {
        if (!this.socio && !value) {
          return 'Informe a data de saída'
        }

        if (value.length > 0 && value.length < 10) {
          return 'Insira a data completa Ex.:06/04/1972'
        }

        return true
      }
    },
    validaMotivoSaidaSocio () {
      return (value) => {
        if (!this.socio && !value) {
          return 'Informe o motivo da saída'
        }

        return true
      }
    },
    ajustaTamanhoQuemAssociou () {
      return (value) => {
        if (this.quemAssociou === 'Outro') {
          value = 3
          return value
        } else {
          value = 6
          return value
        }
      }
    }
  },
  created () {
    this.retornaFuncaoTrabalho()
    this.retornaDirigentes()
    this.recebeDados()
  },
  async mounted () {
    const tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.tamanhoPagina = tamanhoPagina - 145

    await this.preencherDadosTela()
  },
  methods: {
    removeDependente (item) {
      this.items.splice(this.items.indexOf(item), 1)
      firebase.firestore().collection('dependentes').doc(item.id).delete()
    },
    addDependente () {
      this.adicionaLinhaDependente = true
    },
    confirmDadosDependente () {
      this.items = this.items === undefined ? [] : this.items
      this.adicionaLinhaDependente = false
      this.idUltimoDependente = parseInt(this.idUltimoDependente) + 1
      this.idDependenteAux = this.idDependente
      this.idDependente = this.idUltimoDependente.toString()

      this.items.push(
        {
          id: this.idDependente,
          nome_dependente: this.nomeDependente,
          matricula_associado: this.matricula,
          data_nascimento: this.dataNascimentoDependente,
          parentesco: this.parentesco
        }
      )

      this.nomeDependente = ''
      this.dataNascimentoDependente = ''
      this.parentesco = ''
      this.idDependente = this.idDependenteAux
    },
    async editarSocio () {
      if (this.adicionaLinhaDependente) {
        this.confirmDadosDependente()
      }

      this.carregando = true
      this.erro = false
      if (!this.$refs.form.validate()) {
        this.erro = true
        this.carregando = false

        // Desaparece a mensagem após 2 segundos
        setTimeout(() => {
          this.erro = false
        }, 2000)
        return
      }

      if (this.nomeImagem !== '') {
        this.salvarFichaFirebase()
      }

      await firebase.firestore().collection('socios').doc(this.matricula).update({
        matricula: this.matricula,
        nome: this.primeiraLetraMaiuscula(this.nome),
        cpf: this.cpf,
        estado_civil: this.estadoCivil,
        data_nascimento: this.dataNascimento,
        rg: this.rg,
        carteira_profissional: this.carteiraProfissional,
        cep: this.cep,
        endereco: this.primeiraLetraMaiuscula(this.endereco),
        numero: this.numero,
        complemento: this.primeiraLetraMaiuscula(this.complemento),
        bairro: this.bairro,
        cidade: this.primeiraLetraMaiuscula(this.cidade),
        uf: this.uf,
        celular: this.celular,
        email: this.email.toLowerCase(),
        // Dados Profissionais
        cnpj_empresa: this.cnpjEmpresa,
        data_admissao: this.dataAdmissao,
        funcao: this.primeiraLetraMaiuscula(this.funcao),
        setor: this.primeiraLetraMaiuscula(this.setor),
        valor_mensalidade: this.ajustaValorMensalidade(this.valorMensalidade),
        observacao: this.primeiraLetraMaiuscula(this.observacao),
        // Dados Sindicato
        socio: this.socio,
        data_entrada_socio: this.dataEntradaSocio,
        quem_associou: this.quemAssociou === 'Outro' ? this.outroAssociou : this.quemAssociou,
        data_saida_socio: this.returnUndefined(this.dataSaidaSocio),
        motivo_saida: this.returnUndefined(this.motivoSaidaSocio),
        dirigente: this.dirigente,
        ficha: this.ficha
      })

      // Cria o histórico de trabalho da empresa se precisar
      if (this.dadosHistoricoTrabalhoAssociacao.cnpj_empresa !== '') {
        await this.criaHistoricoEmpresa()
      }

      // Valida se tem no Store os dados dos socios
      this.socios = this.$store.state.socios

      if (this.socios !== undefined) {
        this.socios.forEach((el) => {
          if (el.matricula === this.matricula) {
            el.matricula = this.matricula
            el.nome = this.primeiraLetraMaiuscula(this.nome)
            el.cpf = this.cpf
            el.estado_civil = this.estadoCivil
            el.data_nascimento = this.dataNascimento
            el.rg = this.rg
            el.carteira_profissional = this.carteiraProfissional
            el.cep = this.cep
            el.endereco = this.primeiraLetraMaiuscula(this.endereco)
            el.numero = this.numero
            el.complemento = this.primeiraLetraMaiuscula(this.complemento)
            el.bairro = this.bairro
            el.cidade = this.primeiraLetraMaiuscula(this.cidade)
            el.uf = this.uf
            el.celular = this.celular
            el.email = this.email.toLowerCase()
            // Dados Profissionais
            el.cnpj_empresa = this.cnpjEmpresa
            el.data_admissao = this.dataAdmissao
            el.funcao = this.primeiraLetraMaiuscula(this.funcao)
            el.setor = this.primeiraLetraMaiuscula(this.setor)
            el.valor_mensalidade = this.ajustaValorMensalidade(this.valorMensalidade)
            el.observacao = this.primeiraLetraMaiuscula(this.observacao)
            // Dados Sindicato
            el.socio = this.socio
            el.data_entrada_socio = this.dataEntradaSocio
            el.quem_associou = this.quemAssociou === 'Outro' ? this.outroAssociou : this.quemAssociou
            el.data_saida_socio = this.returnUndefined(this.dataSaidaSocio)
            el.motivo_saida = this.returnUndefined(this.motivoSaidaSocio)
            el.dirigente = this.dirigente
            el.ficha = this.ficha
            el.historico_trabalho_associacao = this.historicoTrabalhoAssociacao
            this.dadosEditados = el
          }
        })
      }
      // Fazendo o Insert de todos os dependentes
      this.items.forEach((el) => {
        firebase.firestore().collection('dependentes').doc(el.id).set({
          id: el.id,
          matricula_associado: el.matricula_associado,
          nome_dependente: this.primeiraLetraMaiuscula(el.nome_dependente),
          parentesco: el.parentesco,
          data_nascimento: el.data_nascimento
        })
      })
      setTimeout(() => {
        this.sucesso = true
        this.carregando = false
      }, 3000)

      setTimeout(() => {
        this.voltarPaginaAnterior()
      }, 5000)
    },
    recebeDados () {
      this.dadosRecebidos = this.$route.query.W5kaXNhdWR
      this.empresaRecebida = this.$route.query.KSJMLwWasO
      this.paginaAnterior = this.$route.query.page

      const convertdadosRecebidos = JSON.stringify(this.dadosRecebidos)
      const convertInstituicaoRecebida = JSON.stringify(this.empresaRecebida)

      this.dados = JSON.parse(convertdadosRecebidos)
      this.empresas = JSON.parse(convertInstituicaoRecebida)
      this.items = []

      this.retornaEmpresas()
      this.retornaDependentes()
    },
    retornaEmpresas () {
      this.empresas.forEach((el) => {
        this.empresaOpcoes.push(el.nome)
      })
      this.empresaOpcoes.sort()
    },
    retornaDependentes () {
      firebase.firestore().collection('dependentes').orderBy('id', 'desc').limit(1)
        .get().then((snapshot) => {
          snapshot.forEach((doc) => {
            this.idUltimoDependente = doc.data().id
          })
        })
        .catch((err) => {
          console.log('Error não está retornando Dados Dependentes - ', err)
        })

      let dependentesTemp = []
      firebase.firestore().collection('dependentes')
        .where('matricula_associado', '==', this.dados.matricula)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            dependentesTemp.push(doc.data())
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })
      setTimeout(() => {
        this.items = dependentesTemp
        this.items.forEach((el) => {
          this.idDependente = el.id
          this.matriculaAssociado = el.matricula_associado
          this.dataNascimentoDependente = el.data_nascimento
          this.nomeDependente = el.nome_dependente
          this.parentesco = el.parentesco
        })
        this.nomeDependente = ''
        this.dataNascimentoDependente = ''
        this.parentesco = ''
      }, 2000)
    },
    voltarPaginaAnterior () { // Método para voltar a página anterior
      // if (this.paginaAnterior === 'detalhesSocio') {
      //   if (this.$store.state.socios !== undefined) {
      if (this.dadosEditados !== undefined) {
        this.$router.push({
          name: 'detalhesSocio',
          query: { W5kaXNhdWR: this.dadosEditados, KSJMLwWasO: this.empresaRecebida }
        })
      } else {
        this.$router.push({
          name: 'detalhesSocio',
          query: { W5kaXNhdWR: this.dadosRecebidos, KSJMLwWasO: this.empresaRecebida }
        })
      }
      //   } else {
      //     this.$router.push('socios')
      //   }
      // } else {
      //   this.$router.push({ name: this.paginaAnterior })
      // }
      this.$refs.form.reset()
    },
    primeiraLetraMaiuscula (value) { // Método para padronizar dados no Banco de Dados
      if (value === '' || value === undefined) {
        return ''
      } else if (value.length <= 2) {
        return value
      } else {
        // Recebe o valor inteiro
        let loweredText = value.toLowerCase()
        // Separa por virgula todas as palavras
        let words = loweredText.split(' ')

        // Faz um for em todas as palavras e coloca a primeira letra maior
        for (let a = 0; a < words.length; a++) {
          let w = words[a]
          let firstLetter = w[0]
          if (firstLetter === undefined) {
            firstLetter = ''
          }

          if (w === 'dos' || w === 'das') {
            w = firstLetter + w.slice(1)
          } else if (w.length > 2 || w.replace('.', '').length === 1) {
            w = firstLetter.toUpperCase() + w.slice(1)
          } else {
            w = firstLetter + w.slice(1)
          }
          // retorna cada palavra com a primeira maior
          words[a] = w
        }

        // retorna a frase inteira
        return words.join(' ')
      }
    },
    carregaCNPJ () {
      this.empresas.forEach((el) => {
        if (el.nome === this.empresa) {
          this.cnpjEmpresa = el.cnpj
        }
      })
    },
    onImage (event) {
      this.dadosImagem = event.target.files[0]
      this.ficha = event.target.files[0].name
    },
    salvarFichaFirebase () {
      if (this.dadosImagem !== undefined) {
        const storageRef = firebase.storage()
          .ref(`Fichas Associados/${this.ficha}`)
          .put(this.dadosImagem)
        storageRef.on('state_changed', snapshot => {},
          error => { console.log(error.message) }
        )
      }
    },
    retornaNomesEmpresas (item) {
      let nomeEmpresa
      // Varre todas as instituições com o cnpj e retorna o nome da Empresa
      this.empresas.forEach((el) => {
        if (el.cnpj === item) {
          nomeEmpresa = el.nome
        }
      })
      return nomeEmpresa
    },
    retornaFuncaoTrabalho () {
      let funcoes = []

      this.$store.state.funcaoEmpresa.forEach(doc => {
        funcoes.push(doc)
      })

      funcoes[0].forEach(el => {
        this.funcoesEmpresa.push(el)
      })

      this.funcoesEmpresa = this.funcoesEmpresa.sort()
    },
    returnUndefined (value) { // Função que normaliza o retorno undefined para vazio
      return value === undefined ? '' : value
    },
    ajustaValorMensalidade (value) {
      if (this.valorMensalidade === '' ||
      this.valorMensalidade === undefined) {
        value = '0,00'
      }

      return value
    },
    retornaDirigentes () {
      this.dirigentes.push('Angela Meneses')
      this.$store.state.socios.forEach(doc => {
        if (doc.dirigente) {
          this.dirigentes.push(doc.nome)
        }
      })

      this.dirigentes = this.dirigentes.sort()
      this.dirigentes.push('Outro')
    },
    abreModalHistoricoEmpresa () {
      if (this.historicoSocio === false &&
          this.socio === true) {
        if (this.dataEntradaSocio === '') {
          this.modalDataEntradaSocioVazio = true
        } else {
          this.modalHistoricoEmpresa = true
          this.modalDataEntradaSocioVazio = false
        }
      }
    },
    salvaDadosHistoricoTrabalho () {
      // Salva os dados antigos da Empresa
      this.dadosHistoricoTrabalhoAssociacao.cnpj_empresa = this.cnpjEmpresa
      this.dadosHistoricoTrabalhoAssociacao.data_admissao = this.dataAdmissao
      this.dadosHistoricoTrabalhoAssociacao.funcao = this.funcao
      this.dadosHistoricoTrabalhoAssociacao.setor = this.setor
      this.dadosHistoricoTrabalhoAssociacao.observacao = this.observacao
      this.dadosHistoricoTrabalhoAssociacao.valor_mensalidade = this.valorMensalidade
      this.dadosHistoricoTrabalhoAssociacao.data_entrada_socio = this.dataEntradaSocio
      this.dadosHistoricoTrabalhoAssociacao.quem_associou = this.quemAssociou
      this.dadosHistoricoTrabalhoAssociacao.data_saida_socio = this.dataSaidaSocio
      this.dadosHistoricoTrabalhoAssociacao.motivo_saida = this.motivoSaidaSocio

      // Seta vazio os campos para incluir novos dados
      this.cnpjEmpresa = ''
      this.empresa = ''
      this.dataAdmissao = ''
      this.funcao = ''
      this.setor = ''
      this.observacao = ''
      this.valorMensalidade = '0,00'
      this.dataEntradaSocio = ''
      this.quemAssociou = ''
      this.dataSaidaSocio = ''
      this.motivoSaidaSocio = ''

      this.modalHistoricoEmpresa = false
    },
    async criaHistoricoEmpresa () {
      const dataHistorico = this.formatarData(this.dadosHistoricoTrabalhoAssociacao.data_entrada_socio)
      // Atualize os dados do histórico de trabalho
      await firebase.firestore().collection('socios').doc(this.matricula).update({
        [`historico_trabalho_associacao.${dataHistorico}`]: {
          'cnpj_empresa': this.dadosHistoricoTrabalhoAssociacao.cnpj_empresa,
          'data_admissao': this.dadosHistoricoTrabalhoAssociacao.data_admissao,
          'funcao': this.dadosHistoricoTrabalhoAssociacao.funcao,
          'setor': this.dadosHistoricoTrabalhoAssociacao.setor,
          'observacao': this.dadosHistoricoTrabalhoAssociacao.observacao,
          'valor_mensalidade': this.dadosHistoricoTrabalhoAssociacao.valor_mensalidade,
          'data_entrada_socio': this.dadosHistoricoTrabalhoAssociacao.data_entrada_socio,
          'quem_associou': this.dadosHistoricoTrabalhoAssociacao.quem_associou,
          'data_saida_socio': this.dadosHistoricoTrabalhoAssociacao.data_saida_socio,
          'motivo_saida': this.dadosHistoricoTrabalhoAssociacao.motivo_saida
        }
      }, { merge: true })

      await firebase.firestore().collection('socios').doc(this.matricula).get()
        .then(doc => {
          this.historicoTrabalhoAssociacao = doc.data().historico_trabalho_associacao
        })
        .catch(err => {
          console.log('Error getting document', err)
        })

      this.modalHistoricoEmpresa = false
    },
    formatarData (datadeAdmissao) {
      let partes
      let ano
      let mes
      let dia

      partes = datadeAdmissao.split('/') // Separa a data entre dia, mes e ano
      ano = parseInt(partes[2])// Pega o ano
      mes = parseInt(partes[1]) // Pega o mes
      dia = parseInt(partes[0]) // Pega o dia

      // Formata a data completa para validar com o primeiro e o ultimo dia
      return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2)
    },
    async preencherDadosTela () {
      let contarOutroQueAssociou = 0
      await firebase.firestore().collection('socios').doc(this.dados.matricula).get()
        .then(doc => {
          if (doc.data().quem_associou !== '') {
            this.dirigentes.forEach(el => {
              if (el === doc.data().quem_associou) {
                contarOutroQueAssociou += 1
              }
            })

            if (contarOutroQueAssociou === 0) {
              this.quemAssociou = 'Outro'
              this.outroAssociou = doc.data().quem_associou
              this.tamanhoQuemAssociou = 3
            } else {
              this.quemAssociou = doc.data().quem_associou
              this.outroAssociou = ''
            }
          }

          this.bairro = doc.data().bairro
          this.carteiraProfissional = doc.data().carteira_profissional
          this.cep = doc.data().cep
          this.cidade = doc.data().cidade
          this.matricula = doc.data().matricula
          this.empresa = this.retornaNomesEmpresas(doc.data().cnpj_empresa)
          this.cnpjEmpresa = doc.data().cnpj_empresa
          this.complemento = doc.data().complemento
          this.cpf = doc.data().cpf
          this.dataAdmissao = doc.data().data_admissao
          this.dataEntradaSocio = doc.data().data_entrada_socio
          this.dataNascimento = doc.data().data_nascimento
          this.dataSaidaSocio = doc.data().data_saida_socio
          this.dirigente = doc.data().dirigente
          this.email = doc.data().email
          this.endereco = doc.data().endereco
          this.estadoCivil = doc.data().estado_civil
          this.celular = doc.data().celular
          this.funcao = doc.data().funcao
          this.nome = doc.data().nome
          this.numero = doc.data().numero
          this.observacao = doc.data().observacao
          this.rg = doc.data().rg
          this.setor = doc.data().setor
          this.socio = doc.data().socio
          this.historicoSocio = doc.data().socio
          this.uf = doc.data().uf
          this.valorMensalidade = doc.data().valor_mensalidade
          this.ficha = doc.data().ficha
          this.motivoSaidaSocio = doc.data().motivo_saida
          this.historicoTrabalhoAssociacao = doc.data().historico_trabalho_associacao
        })
        .catch(err => {
          console.log('Error getting document', err)
        })
    },
    cancelar () {
      this.modalHistoricoEmpresa = false
      this.socio = false
    },
    cancelarModalDataEntrada () {
      this.socio = false
      this.modalDataEntradaSocioVazio = false
      // Desaparece a mensagem após 2 segundos
      setTimeout(() => {
        this.dataEntradaSocio = ''
      }, 1)
    },
    validaOutroAssociou () {
      if (this.quemAssociou === 'Outro') {
        this.tamanhoQuemAssociou = 3
      } else {
        this.tamanhoQuemAssociou = 6
      }
    }
  }
}

</script>
<style  scoped>
.v-content {
  height: 100vh;

}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.botoes{
  padding: 0px 36px;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
  color: white;
}

.v-application .indigo {
    height: 45px !important;
}

.v-card__text, .v-card__title {
  padding: 18px 26px 3px 26px;
}

.v-toolbar__title {
  margin: auto
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.v-text-field {
    margin-top: -8px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 90px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 5px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.fill-height{
  align-items: baseline;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 3px;
}

.container {
  padding-top: 4px;
  padding-bottom: 4px;

}

.alertas {
  margin: 0px auto -13px;
  width: 90%;
}

.titleSocio {
  margin-top: 15px;
  margin-left: 10px;
}

.desde {
  margin: 32px -5px 0px 10px;
  font-size: 14px;
}

.checkboxSocioNao {
  margin-top: -40px
}

.checkBoxSocio {
  margin-top: -30px;
  margin-left: 46px;
}

.iconSocio {
  margin-left: 16px;
}

.dataSocioSim {
  margin-top: 0px;
  margin-bottom: 5px;
}

.dataSaidaSocio {
  margin-top: 5px;
  margin-bottom: 5px;
}

.checkBoxDirigente{
  margin-top: -20px;
  margin-left: 35px;
}

.dependentesTitle {
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #041c32
}

.btnFechar {
  margin-bottom: 20px;
}

input {
  width: 124px;
  margin-left: 30px;
}

.nameImage {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: black;
  margin-right: 30px;
}

.ficha p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnDependente {
  position: relative;
  left: 48%;
}

.v-btn--fab.v-size--default {
    width: 46px !important;
    height: 46px;
}

.text-center {
  margin-bottom: 20px;
  font-weight: bold;
  color: #041c32;
}

.fileFicha {
  margin-top: 10px;
  margin-left: 5px;
}

.BarraEditar {
  height: 45px !important;
}

.BarraAtencao {
  position: relative;
  top: -7px;
  font-size: 22px;
  font-weight: bold;
}

.confirmaAcao{
  font-size: 17px !important;
  text-align: center;
  margin-bottom: 0px;
}

.botoesModal{
  padding: 0px 5px;
  justify-content: center;
}

.textoModal{
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.textoModal b{
  color: #041c32;
}
</style>
