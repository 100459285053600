<template>
  <div class="header">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
        <v-list-item to="/home">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Início</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/perfil">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
          v-if="isAdmin"
          to="/empresas"
        >
          <v-list-item-action>
            <v-icon>mdi-hospital-building</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Empresas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/socios">
          <v-list-item-action>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sócios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/relatorios">
          <v-list-item-action>
            <v-icon>mdi-bookmark-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Relatórios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="ativaMovimentacao && isAdmin"
          to="/movimentacao"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-sync-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Movimentações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
          v-if="ativaBackup && isAdmin"
          to="/backup"
        >
          <v-list-item-action>
            <v-icon>mdi-database-check-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Backup</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          v-if="isAdmin"
          to="/script"
        >
          <v-list-item-action>
            <v-icon>mdi-all-inclusive</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Script</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          v-if="isAdmin"
          to="/configuracao"
        >
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Configuração</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item @click="sair">
          <v-list-item-action>
            <v-icon>mdi-power-settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <img
        class="logo"
        alt="Vue logo"
        src="../assets/LogoCut.svg"
        @click="toHome"
      >
      <div class="logado">
        {{ nome }}
      </div>
    </v-app-bar>
  </div>
</template>

<script>

import firebase from 'firebase'

export default {
  props: {
    source: String
  },
  data: () => ({
    nome: '',
    drawer: null,
    isAdmin: false,
    uidCurrentUser: '',
    ativaScript: false,
    ativaMovimentacao: false,
    ativaBackup: false
  }),
  async created () {
    this.uidCurrentUser = firebase.auth().currentUser.uid

    let validaAcesso
    let nomeEmpresa

    await firebase.firestore().collection('empresas')
      .where('uid', '==', this.uidCurrentUser)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          validaAcesso = doc.data().acesso === 'Administrador'
          nomeEmpresa = doc.data().nome
        })
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error)
      })

    this.isAdmin = validaAcesso
    this.$store.dispatch('setAdm', this.isAdmin)
    this.nome = nomeEmpresa

    await firebase.firestore().collection('configuracao').doc('geral').get()
      .then(doc => {
        this.ativaMovimentacao = doc.data().ativaMovimentacao
        this.ativaBackup = doc.data().ativaBackup
        this.$store.dispatch('setAtivaMovimentacao', this.ativaMovimentacao)
        this.$store.dispatch('setAtivaBackup', this.ativaBackup)
      })
      .catch((err) => {
        console.log('Error getting documents2', err)
      })
  },
  methods: {
    sair: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch('setSocios', undefined)
          this.$store.dispatch('setNomeAcesso', undefined)
          this.$store.dispatch('setEmpresas', undefined)
          this.$store.dispatch('setAtivaMovimentacao', undefined)
          this.$store.dispatch('setAtivaBackup', undefined)
          this.$store.dispatch('setAdm', undefined)
          localStorage.removeItem('reloadFirebase')

          this.$router.replace('login')
        })
    },
    toHome () {
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>
.logado{
  position: absolute;
  right: 18px;
  font-weight: bold;
}
.logo {
  width: 160px;
}
.v-divider {
  border-width: thin 0px 0.5px 0;
  margin: 7px auto;
  max-width: 85%;
}
.v-list-item {
    margin: 7px 13px;
}
.header {
  padding-top: 68px;
}
</style>
