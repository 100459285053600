<template>
  <v-content id="tamanhoDiv">
    <v-card class="header">
      <v-icon class="icon">
        mdi-database-check-outline
      </v-icon>
      <v-card-text class="text">
        Backup
      </v-card-text>
    </v-card>

    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <v-card class="elevation-12">
            <!-- Barra de Título -->
            <v-toolbar
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="tituloBarra text-uppercase">
                Restauração e Backup de Dados
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="5"
                >
                  <v-radio-group
                    v-model="tipoAcao"
                    :rules="tipoAcaoRules"
                    :value="computedTipoAcao"
                    required
                    row
                    prepend-icon="mdi-file-restore-outline"
                  >
                    <v-radio
                      label="Backup"
                      value="Backup"
                    />
                    <v-radio
                      label="Restaurar"
                      value="Restauração"
                    />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  sm=""
                >
                  <v-select
                    v-model="colection"
                    label="Banco de Dados"
                    name="colection"
                    prepend-icon="mdi-database-check-outline"
                    type="text"
                    :items="colectionOpcoes"
                    required
                    clearable
                    @change="getRestoreBackups"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row
                v-if="tipoAcao === 'Restauração'
                  && colection !== null
                  && colection !== undefined
                  && colection !== ''"
              >
                Restaurar por:
                <v-col
                  cols="12"
                  sm="5"
                >
                  <v-radio-group
                    v-model="tipoRestauracao"
                    required
                    row
                    prepend-icon="mdi-backup-restore"
                    :value="computedtipoRestauracao"
                  >
                    <v-radio
                      label="Servidor"
                      value="Servidor"
                    />
                    <v-radio
                      label="Arquivo"
                      value="Arquivo"
                    />
                  </v-radio-group>
                </v-col>
                <v-col
                  v-if="tipoRestauracao === 'Arquivo'"
                  cols="12"
                  sm=""
                  class="ficha"
                >
                  <v-row>
                    <v-icon
                      medium
                      class="iconSocio"
                    >
                      mdi-file-cloud
                    </v-icon>
                    <p class="tituloFicha">
                      Arquivo Backup
                    </p>
                  </v-row>
                  <input
                    type="file"
                    @change="onJson"
                  >
                </v-col>
                <v-col
                  v-if="tipoRestauracao === 'Servidor'"
                  cols="12"
                  sm=""
                  class="ficha"
                >
                  <v-select
                    v-model="restoreBackup"
                    label="Backups"
                    name="colection"
                    prepend-icon="mdi-database-clock"
                    type="text"
                    :items="restoreBackupOptions"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <!-- Alertas de Sucesso e Erro -->
            <div class="alertas">
              <v-alert
                v-model="hasError"
                type="error"
                dismissible
              >
                Confira as informações e tente novamente
              </v-alert>

              <v-alert
                v-model="hasErrorDownload"
                type="error"
                dismissible
              >
                Arquivo inexistente ou sem permissão para download
              </v-alert>

              <v-alert
                v-model="hasWarningFile"
                type="warning"
                dismissible
              >
                Atenção!!! Você deve realizar o upload do arquivo com o nome padrão
                para que seja realizado o backup corretamente... Por exemplo:
                2023-01-socios
              </v-alert>

              <v-alert
                v-model="hasSuccess"
                type="success"
                dismissible
              >
                Backup realizado com sucesso!!! Total de {{ totalRegistros }} registros
              </v-alert>

              <v-alert
                v-model="hasSuccessRestore"
                type="success"
                dismissible
              >
                Restauração realizada com sucesso!!! Total de {{ totalRegistros }} registros
              </v-alert>
            </div>

            <v-card-actions class="botoes">
              <v-btn
                class="my-2"
                rounded
                color="success"
                :loading="hasLoading"
                :disabled="tipoAcao === 'Backup' ?
                  (colection !== null &&
                    colection !== undefined &&
                    colection !== '' ? false : true) :
                  tipoRestauracao === 'Servidor' ?
                    (restoreBackup !== null &&
                      restoreBackup !== undefined &&
                      restoreBackup !== '' ? false : true) :
                    fileName !== undefined ? false : true"
                depressed
                @click="isBackupOrRestauration"
              >
                Realizar {{ tipoAcao }}
              </v-btn>
            </v-card-actions>

            <div class="selectBackup">
              <h2>Histórico de Backups</h2>
            </div>

            <v-data-table
              sort-by="arquivo"
              class="elevation-1"
              :headers="headers"
              :fixed-header="fixedHeader"
              :height="height"
              :items="backups"
              :search="search"
              :loading="loading"
              loading-text="Carregando Dados... Por Favor Aguarde."
              :sort-desc="true"
            >
              <template #top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-spacer />
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="search"
                      class="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-toolbar>
              </template>

              <!-- Botão de Ações lateral tabela -->
              <template #item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="downloadJson(item)"
                >
                  mdi-cloud-download
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>

import firebase from 'firebase'
import download from 'downloadjs'
import axios from 'axios'
import 'moment/locale/pt-br'

export default {
  data () {
    return {
      isAdmin: false,
      selectSwitch: '',
      db: firebase.firestore(),
      colection: '',
      colectionOpcoes: [
        'socios',
        'configuracao',
        'dependentes',
        'empresas'
      ],
      bkpJson: [],
      jsonData: '',
      fileName: undefined,
      totalRegistros: 0,
      hasLoading: false,
      hasSuccess: false,
      hasError: false,
      hasErrorDownload: false,
      fixedHeader: true,
      headers: [
        { text: 'Tabela', align: 'left', value: 'colection' },
        { text: 'Data do Backup', align: 'left', value: 'data_backup', sortable: true },
        { text: 'Total de Registros', value: 'total_registros', sortable: false },
        { text: 'Arquivo', value: 'arquivo', sortable: true },
        { text: 'Ações', value: 'action', sortable: false }
      ],
      backups: [],
      search: '',
      loading: true,
      tipoAcao: 'Backup',
      tipoAcaoRules: [
        v => !!v || 'A ação é obrigatória'
      ],
      tipoRestauracao: 'Servidor',
      height: 0,
      tamanhoPagina: 0,
      heightRestaura: 0,
      restoreBackup: '',
      restoreBackupOptions: [],
      loadingItem: false,
      hasSuccessRestore: false,
      hasWarningFile: false,
      restoreBackupFile: false,
      finishControl: 0
    }
  },
  computed: {
    computedTipoAcao () {
      this.onHeight()

      return this.tipoAcao
    },
    computedtipoRestauracao () {
      this.ontipoRestauracao()

      return this.tipoRestauracao
    }
  },
  created () {
    this.getBackups()
  },
  mounted () {
    // Função para montar o tamanho certo da tela que mostrará o scroll correto
    this.tamanhoPagina = document.getElementById('tamanhoDiv').offsetHeight
    this.height = this.tamanhoPagina - 560
  },
  methods: {
    isBackupOrRestauration () { // Método para realizar o backup e restauração
    // Realiza o backup do banco de dados
      if (this.tipoAcao === 'Backup') {
        // Zera os registros
        this.totalRegistros = 0
        this.bkpJson = []

        // Ativa o Loading do botão
        this.hasLoading = true

        // Busca os dados para realizar o backup
        this.db.collection(this.colection)
          .get().then((snapshot) => {
            snapshot.forEach((doc) => {
              // Contabiliza os registros
              this.totalRegistros += 1
              // Inclui os dados em um Json
              this.bkpJson.push(JSON.stringify(doc.data()))
            })

            // Cria o Json com o formato correto para o backup
            this.jsonData = `[{ "date": "${new Date().toLocaleDateString()}", "colection":
                      "${this.colection}", "data": [ ${this.bkpJson}]}]`

            // Cria o arquivo Json para armazenar no storage do Firebase
            this.jsonFile = new Blob([JSON.parse(JSON.stringify(this.jsonData), null, 2)], {
              type: 'application/json'
            })

            // Cria o nome do arquivo para subir no storage do Firebase
            this.fileName = `${new Date().toISOString().substr(0, 7)}-${this.colection}`

            // Metodo para subir o arquivo no storage
            this.onUpload()

            // Metodo para armazenar o registro de log do backup
            this.setBackupStorage()

            // Metodo de download do arquivo para armazenamento externo
            download(this.jsonFile, `${this.fileName}.json`)

            // Desaparece a mensagem após 2 segundos
            setTimeout(() => {
              this.hasSuccess = true
              this.hasLoading = false
              this.colection = ''
              this.getBackups()
            }, 2000)

            setTimeout(() => {
              this.hasSuccess = false
            }, 6000)
          })
          .catch((err) => {
            this.hasError = true
            this.hasLoading = false

            // Desaparece a mensagem após 2 segundos
            setTimeout(() => {
              this.hasError = false
            }, 2000)
            console.log('Error getting documents', err)
          })
      } else { // Realiza as restaurações do banco por meio do servidor ou por arquivo
        this.hasLoading = true
        this.totalRegistros = 0

        // Restauração pelo servidor, onde pega o Json armazenado no firebase storage
        if (this.tipoRestauracao === 'Servidor') {
          firebase.storage()
            .ref(`Backup Dados/${this.restoreBackup}`)
            .getDownloadURL().then((url) => {
              axios
                .get(url, {
                  responseType: 'json'
                }).then(response => {
                  response.data.forEach((doc) => {
                    doc.data.forEach((el) => {
                      this.totalRegistros += 1
                      // Método para restaurar dados das collections
                      this.restoreCollection(doc.colection, el)
                    })
                  })

                  if (this.restoreBackupFile) {
                    this.fileName = this.fileName.replace('.json', '')

                    // Metodo para armazenar o registro de log do backup
                    this.setBackupStorage()

                    this.restoreBackupFile = false
                    this.colection = ''
                    this.getBackups()
                  }
                })
            })
        } else { // Restauração por meio de arquivo onde sobe o arquivo no servidor
          // depois realiza a restauração, atualizando o backup
          if (this.fileName !== undefined) {
            // Realiza o upload do arquivo no servidor
            const storageRef = firebase.storage()
              .ref(`Backup Dados/${this.fileName}`)
              .put(this.jsonFile)
            storageRef.on('state_changed', snapshot => {
            },
            error => { console.log(error.message) }
            )
            setTimeout(() => {
              this.hasLoading = false
              // Restaura os dados que estão no servidor
              this.tipoAcao = 'Restaurar'
              this.tipoRestauracao = 'Servidor'
              this.restoreBackup = this.fileName
              this.isBackupOrRestauration()
            }, 10000)
          }
        }
      }
    },
    onJson (event) {
      if (event.target.files[0] !== undefined) {
        this.jsonFile = event.target.files[0]
        this.fileName = event.target.files[0].name
        this.hasWarningFile = true
        this.restoreBackupFile = true
      } else {
        this.fileName = undefined
      }
    },
    onUpload () {
      if (this.jsonData !== '') {
        // Cria e sobe o arquivo no storage no firebase
        const storageRef = firebase.storage()
          .ref(`Backup Dados/${this.fileName}.json`)
          .put(this.jsonFile)
        storageRef.on('state_changed', snapshot => {
        },
        error => { console.log(error.message) }
        )
      }
    },
    ontipoRestauracao () {
      if (this.tipoRestauracao !== 'Arquivo') {
        this.fileName = undefined
      } else {
        this.restoreBackup = ''
      }
    },
    setBackupStorage () {
      // Seta os dados na collection backup no firebase
      firebase.firestore().collection('backup').doc(this.fileName).set({
        arquivo: `${this.fileName}.json`,
        colection: this.colection,
        data_backup: new Date().toLocaleString('pt-br', { timezone: 'Brazil/brt' }),
        total_registros: this.totalRegistros
      }, { merge: true })
    },
    getBackups () { // Busca os dados dos backups realizados
      this.backups = []
      this.db.collection('backup').get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.backups.push(doc.data())
          })
        })
      this.loading = false
    },
    onHeight () { // Realiza a alteração do tamanho da página conforme seleção
      if (this.tipoAcao === 'Restauração' && this.colection !== null &&
                          this.colection !== undefined &&
                          this.colection !== '') {
        this.height = this.tamanhoPagina - 560
      } else {
        this.height = this.tamanhoPagina - 490
      }
    },
    getRestoreBackups () { // Busca os dados dos backups realizados para a restauração
      this.restoreBackupOptions = []

      this.backups.forEach((doc) => {
        if (doc.colection === this.colection) {
          this.restoreBackupOptions.push(doc.arquivo)
        }
      })
    },
    downloadJson (item) { // Método para buscar o Json no storage e realizar o download
      firebase.storage()
        .ref(`Backup Dados/${Object.assign({}, item).arquivo}`)
        .getDownloadURL().then((url) => {
          let x = new XMLHttpRequest()
          x.open('GET', url, true)
          x.responseType = 'blob'
          x.onload = function (e) { download(x.response, `${Object.assign({}, item).arquivo}`) }
          x.send()
        }).catch((err) => {
          this.hasErrorDownload = true

          // Deleta o valor da collection quando não tem salvo no storage
          firebase.firestore().collection('backup').doc(Object.assign({}, item).arquivo).delete()
            .then(() => { })
            .catch((err) => {
              console.log('Error update document', err)
            })

          // Desaparece a mensagem após 2 segundos
          setTimeout(() => {
            this.hasError = false
            this.getBackups()
          }, 2000)
          console.log('Error getting documents', err)
        })
    },
    restoreCollection (collection, document) {
      if (collection === 'configuracao') { // Restaura a tabela de configuração
        firebase.firestore().collection(collection)
          .doc(document.configuracao).set({ })
          .then(() => {
            if (document.configuracao === 'socios') {
              firebase.firestore().collection(collection).doc(document.configuracao).update({
                configuracao: document.configuracao,
                bloqueiaEdicaoMatricula: document.bloqueiaEdicaoMatricula,
                cpfObrigatorio: document.cpfObrigatorio
              })
            } else if (document.configuracao === 'geral') {
              firebase.firestore().collection(collection).doc(document.configuracao).update({
                configuracao: document.configuracao,
                ativaBackup: document.ativaBackup,
                ativaMovimentacao: document.ativaMovimentacao,
                verificaLogin: document.verificaLogin
              })
            } else {
              firebase.firestore().collection(collection).doc(document.configuracao).update({
                configuracao: document.configuracao,
                descricao: document.descricao
              })
            }
          })
          .catch((err) => {
            this.hasError = true
            this.hasLoading = false

            // Desaparece a mensagem após 2 segundos
            setTimeout(() => {
              this.hasError = false
            }, 2000)
            console.log('Error getting documents', err)
          })
        setTimeout(() => {
          this.hasSuccessRestore = true
          this.hasLoading = false
          this.colection = undefined
          this.restoreBackup = undefined
          this.fileName = undefined
        }, 2000)

        setTimeout(() => {
          this.hasSuccessRestore = false
        }, 6000)
      } else if (collection === 'empresas') { // Restaura a tabela de instituições
        firebase.firestore().collection(collection)
          .doc(document.id).set({ })
          .then(() => {
            firebase.firestore().collection(collection).doc(document.id).update({
              nome: document.nome ?? '',
              cnpj: document.cnpj ?? '',
              cep: document.cep ?? '',
              endereco: document.endereco ?? '',
              numero: document.numero ?? '',
              fone: document.fone ?? '',
              complemento: document.complemento ?? '',
              bairro: document.bairro ?? '',
              cidade: document.cidade ?? '',
              uf: document.uf ?? '',
              email: document.email ?? '',
              acesso: document.acesso ?? '',
              uid: document.uid ?? '',
              cnpj_matriz: document.cnpj_matriz ?? '',
              mensalidade: document.mensalidade ?? '',
              senha: document.senha ?? '',
              tipo: document.tipo ?? ''
            })
          })
          .catch((err) => {
            this.hasError = true
            this.hasLoading = false

            // Desaparece a mensagem após 2 segundos
            setTimeout(() => {
              this.hasError = false
            }, 2000)
            console.log('Error getting documents', err)
          })
        setTimeout(() => {
          this.hasSuccessRestore = true
          this.hasLoading = false
          this.colection = undefined
          this.restoreBackup = undefined
          this.fileName = undefined
        }, 6000)

        setTimeout(() => {
          this.hasSuccessRestore = false
        }, 10000)
      } else if (collection === 'socios') { // Restaura a tabela de socios
        this.finishControl += 1
        firebase.firestore().collection(collection)
          .doc(document.matricula).set({ })
          .then(() => {
            firebase.firestore().collection(collection).doc(document.matricula).update({
              bairro: document.bairro ?? '',
              carteira_profissional: document.carteira_profissional ?? '',
              cep: document.cep ?? '',
              cidade: document.cidade ?? '',
              matricula: document.matricula ?? '',
              cnpj_empresa: document.cnpj_empresa ?? '',
              complemento: document.complemento ?? '',
              cpf: document.cpf ?? '',
              data_admissao: document.data_admissao ?? '',
              data_entrada_socio: document.data_entrada_socio ?? '',
              data_nascimento: document.data_nascimento ?? '',
              data_saida_socio: document.data_saida_socio ?? '',
              dirigente: document.dirigente ?? '',
              email: document.email ?? '',
              endereco: document.endereco ?? '',
              estado_civil: document.estado_civil ?? '',
              celular: document.celular ?? '',
              funcao: document.funcao ?? '',
              nome: document.nome ?? '',
              numero: document.numero ?? '',
              observacao: document.observacao ?? '',
              rg: document.rg ?? '',
              setor: document.setor ?? '',
              socio: document.socio ?? '',
              uf: document.uf ?? '',
              valor_mensalidade: document.valor_mensalidade ?? 0,
              ficha: document.ficha ?? '',
              mensalidade: document.mensalidade ?? ''
            })
            if (this.totalRegistros === this.finishControl) {
              setTimeout(() => {
                this.hasSuccessRestore = true
                this.hasLoading = false
                this.colection = undefined
                this.restoreBackup = undefined
                this.fileName = undefined
              }, 10000)
            }
            console.log('finishControl => ' + this.finishControl)
          })
          .catch((err) => {
            this.hasError = true
            this.hasLoading = false

            // Desaparece a mensagem após 2 segundos
            setTimeout(() => {
              this.hasError = false
            }, 2000)
            console.log('Error getting documents', err)
          })

        setTimeout(() => {
          this.hasSuccessRestore = false
        }, 24000)
      } else { // Restaura a tabela de dependentes
        firebase.firestore().collection(collection)
          .doc(document.id).set({ })
          .then(() => {
            firebase.firestore().collection(collection).doc(document.id).update({
              id: document.id,
              matricula_associado: document.matricula_associado,
              nome_dependente: document.nome_dependente,
              parentesco: document.parentesco,
              data_nascimento: document.data_nascimento
            })
          })
          .catch((err) => {
            this.hasError = true
            this.hasLoading = false

            // Desaparece a mensagem após 2 segundos
            setTimeout(() => {
              this.hasError = false
            }, 2000)
            console.log('Error getting documents', err)
          })

        setTimeout(() => {
          this.hasSuccessRestore = true
          this.hasLoading = false
          this.colection = undefined
          this.restoreBackup = undefined
          this.fileName = undefined
        }, 20000)

        setTimeout(() => {
          this.hasSuccessRestore = false
        }, 24000)
      }
    }
  }
}
</script>

<style  scoped>
.v-content {
  height: 100vh;
}

.tituloBarra {
  height: 45px !important;
  margin-top: -3px;
}

.botoes {
  padding: 22px 36px 0px 10px;
  justify-content: center;
  margin-bottom: -10px;
}

.v-application .my-2 {
  margin-bottom: 17px !important;
}

.v-application .indigo {
  height: 45px !important;
}

.v-card__text,
.v-card__title {
  padding: 5px 26px 3px 26px;
}

.v-toolbar__title {
  margin: auto;
}

.header {
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  z-index: 4;
}

.fill-height {
    height: 295px;
}

.icon {
  font-size: 30px !important;
  margin-top: -3px !important;
  padding-right: 48px !important;
}

.text {
  padding-top: 0 !important;
  margin-top: -24px;
  margin-left: 25px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.dependentesTitle {
  font-size: 18px;
  margin-top: 0px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #041c32;
}

.nameImage {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: black;
  margin-right: 30px;
}

.alertas {
  margin: 0px auto -13px;
  width: 90%;
}

.selectBackup{
  width: 403px;
    margin-left: 17px;
    position: relative;
    margin-top: 20px;
    z-index: 4;
}

.ficha p {
  margin-top: 35px;
  margin-bottom: 10px;
}

.iconSocio {
  margin-left: 16px;
  margin-top: 35px;
  margin-bottom: 20px;
}
.elevation-1 {
  margin-top: -50px;
}

</style>
